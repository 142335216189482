import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AllState } from 'src/types/AllState';
import {
  Tooltip,
  IconButton,
  makeStyles,
  Tabs,
  Tab,
  TextField,
  Button,
  Typography,
  CircularProgress,
  Link
} from '@material-ui/core';
import { openPanel } from 'src/store/panel/actions';
import { openAuthPopup } from 'src/store/user/actions';
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';
import SlidingPane from 'react-sliding-pane';
import { TabPanel, TabContext } from '@material-ui/lab';
import { getChannels, deleteChannel, setShowChannel, addChannel } from 'src/store/channels/actions';
import HomeIcon from '@material-ui/icons/Home';
import AddToPhotosIcon from '@material-ui/icons/AddToPhotos';
import DeckIcon from '@material-ui/icons/Deck';
import SearchIcon from '@material-ui/icons/Search';
import {
  Paper
} from '@material-ui/core';
import PlaylistPanel from './PlaylistPanel';
import SearchPanel from './SearchPanel';
import IframeVideo from './IframeVideo';
import LibraryMusicIcon from '@material-ui/icons/LibraryMusic';
import PlaylistVideo from './PlaylistVideo';
import channelServices from 'src/services/channel';
import PopupInstruction from './PopupInstruction';
import ListChannel from './ListChannel';

//import Background from 'src/components/containers/App/Background/Background';

const PANEL_NAME = 'channel';

const useStyles = makeStyles((theme) => ({
  loading: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: '0',
    left: '0',
    zIndex: 9999,
    background: 'rgba(0, 0, 0, .5)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  actionButton: {
    color: 'white'
  },
  tabroot: {
    flexGrow: 1,
    padding: '25px 20px',
    display: 'flex',
    height: '100%',
    color: 'white',
    justifyContent: 'space-around',
    backgroundColor: 'rgba(68,68,68 , 0.7)',
    '& .MuiTabPanel-root': {
      padding: '0 0px 0 20px',
      width: '72vw',
    },
    '& .addPlaylistIframe' :{
      position: 'absolute',
      bottom: 0,
      right: 0,
      zIndex: 3,
      color: 'red',
   },  
    '& .termYT': {
      position:'absolute',
      bottom:0,
      transform: 'translate(11%, -50%)',
      zIndex:'2',
      '& a':{
        color:'#fff',
        fontWeight:800,
        fontSize:'12px',
      }
    }
  },
  tabs: {
    minWidth: '24vw',
    height: '430px',
    borderRight: `1px solid ${theme.palette.divider}`,
    backgroundColor: 'rgba(0,0,0 , 0.7)',
    '& .MuiTab-textColorSecondary': {
      color: 'rgba(255,255,255 , 0.7)',
      minWidth: '24vw',
    },
    '& .Mui-selected': {
      color: '#fff',
      fontWeight: 'bold',
      backgroundColor: 'rgba(245, 0, 87, 0.5)',
      maxWidth: 'unset',
      '& .MuiTab-wrapper': {
        alignItems: 'flex-start',
        color: '#fff',
        marginRight: '10px',
      },
    },
    '& .MuiTab-wrapper': {
      alignItems: 'flex-start',
      color: '#fff',
      marginRight: '10px',
    },
    '& .MuiTab-labelIcon': {
      minHeight: '50px',
      '& .MuiTab-wrapper': {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        textAlign: 'left',
        '& *:first-child': {
          marginRight: '6px',
          marginBottom: '0',
        },
      },
    },
  },
  tabContent: {
    // backgroundColor: 'rgba(0,0,0 , 0.34)',
  },
  imagesContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    height: '55vh',
    overflowY: 'auto',
    '&::-webkit-scrollbar-track': {
      border: '1px solid #ddd',
      padding: '2px 0',
      backgroundColor: 'rgba(245, 0, 87, .5)',
    },
    '&::-webkit-scrollbar': {
      width: '0px',
      color: 'rgba(245, 0, 87, .5)',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '0px',
      boxShadow: 'inset 0 0 6px rgba(0,0,0,.3)',
      backgroundColor: '#f50057',
      border: '1px solid #ddd',
    },
  },
  box: {

  },
  imageWrap: {
    overflow: 'hidden',
    cursor: 'pointer',
    position: 'relative',
    '&:hover': {
      '& > div': {
        opacity: '1 !important',
      },
    },
  },
  imageOverlay: {
    opacity: 0,
    transition: 'opacity 0.8s ease-out',
    'z-index': 1000,
    'box-shadow': 'inset 0px 0px 50px #000000',
    position: 'absolute',
    top: '4px',
    left: '4px',
    bottom: '4px',
    right: '4px',
  },
  formContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formGroup: {
    marginBottom: '20px',
    width: '100%',
  },
  channelInput: {
    width: '50%',
    '& > .MuiFormLabel-root': {
      color: '#f50057'
    },
    '& > .MuiInput-underline:after': {
      borderBottom: '2px solid white'
    },
    '& .MuiInputBase-input': {
      color: '#f50057'
    }
  },
  channelRoot: {
    padding: '20px 30px',
    alignItems: 'center',
    height: '100%',
    backgroundColor: 'rgba(0,0,0 , 0.34)',
    fontSize: '1.5rem',
    color: '#fff',
  },
  root: {
    // maxWidth: 345,
  },
  header: {
    minHeight: '100px',
    "& .MuiTypography-h5 ": {
      fontSize: '1rem',
      color: '#3333ee',
    },

  },
  media: {
    height: 0,
    paddingTop: '45.25%', // 16:9
  },
  content: {
    minHeight: '100px',
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  rootstep: {
    width: '100%',
    '& .MuiPaper-root': {
      color: '#fff',
      backgroundColor: 'rgba(0,0,0 , 0.34)',
      padding: theme.spacing(0, 1),
      '& .MuiButton-textSizeSmall':{
        color:'#fff',
        fontSize:'1.2rem',
      }     
      
    }
  },
  button: {
    marginTop: theme.spacing(0),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(0),
  },
  resetContainer: {
    padding: theme.spacing(1),
  },
  titleForm:{
    fontSize:'16pt',
  },
  instruction: {
    fontSize: '12pt',
    color: '-webkit-link',
    cursor: 'pointer',
    textDecoration: 'underline',
    paddingLeft: '20px',
    display: 'inline-flex',
    position: 'relative',
    top: '5px',
    '& a':{
      color:'white',
      fontWeight:'700',
    }
  }
}));

const ChannelWidget: React.FC = () => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const [valueChannelInput, setValueChannelInput] = useState('');

  const [isSticky, setIsSticky] = useState<boolean>(false);

  const [myPlaylist, setMyPlaylist] = useState<any>();
  const [playVideoId, setPlayVideoId] = useState();
  const [isPopupInstruction, setIsPopupInstruction] = useState<boolean>(false)
  
  let channelTabs: any = [];

  const dataChannel = useSelector(({ channels }: any) => ({ ...channels }));

  const [valueTab, setValueTab] = useState('webstart');

  const { name } = useSelector(
    ({ panel, user }: AllState) => panel
  );

  const loggedIn = useSelector(({ user }: AllState) => user.isAuthenticated);

  const [isLoad, setIsLoad] = useState<boolean>(false);

  let isAdd = false;
 
  // useEffect(() => {
  //   if (dataChannel?.type && dataChannel?.type.split('_')[0] == 'add') {
  //     setValueTab(dataChannel?.user_channels[0].channel_id);
  //     isAdd = true;
  //   }
  // }, [dataChannel?.type]);

  useEffect(() => {
    if (dataChannel.showChannel && dataChannel.showChannel.all == true) {
      channelTabs.push('search');
      
      if (dataChannel?.webstart_channel?.channel_id && dataChannel.showChannel.webstart == true) {
        channelTabs.push('webstart');
      }

      if (dataChannel?.partner_channel?.channel_id && dataChannel.showChannel.partner == true) {
        channelTabs.push('partner');
      }

      dataChannel?.user_channels?.length > 0 &&
        dataChannel?.user_channels.map((item: any, index: number) => {
          channelTabs.push(item.channel_id);
        });

      if (!dataChannel?.user_channels?.length || dataChannel?.user_channels?.length < 15) {
        channelTabs.push('add_new_channel');
      }
      
      channelTabs.push('my_playlist');

      const item = channelTabs.find((x: string) => x == valueTab);
      if (!item && !isAdd) {
        //setValueTab(channelTabs[1]);
        setValueTab('add_new_channel');
        
      }
    }
  });

  useEffect(() => {
    if (loggedIn && name === PANEL_NAME && !isLoad) {        
        dispatch(getChannels());
        setIsLoad(true);        
    }   
  }, [loggedIn, name ]);

  useEffect(() => {   
    if (isSticky && name !== PANEL_NAME && name !== '') {
      setIsSticky(false);
    }
  }, [loggedIn, name ]);

  const handleOpen = () => {
    if (loggedIn) {
      if (name === PANEL_NAME) {
        if (isSticky) {
          setIsSticky(false);
        } else {
          setIsSticky(true);
        }
        dispatch(openPanel(''));
      } else {
        setIsSticky(false);
        dispatch(openPanel(PANEL_NAME));
      }
    } else {
      setIsSticky(false);
      dispatch(openAuthPopup({type: 'login', isOpen: true}));
    }
  }

  const handleChangeTab = async (event: any, newValue: string) => {
    if (newValue === 'my_playlist') {
      const { data } = await channelServices.getUserPlaylists();
      setPlayVideoId(data?.videosInfo?.items && data?.videosInfo?.items[0] ? data.videosInfo.items[0]?.id : '');
      setMyPlaylist(data);
    }    
    setValueChannelInput('');
    setValueTab(newValue);
  };

  const handleCloseTab = (e: any, tabValue: string) => {
    e.stopPropagation();
    if (tabValue == valueTab) {
      const index = channelTabs.findIndex((x: string) => x == tabValue);
      if (index == 0) {
        setValueTab(channelTabs[1]);
      } else {
        setValueTab(channelTabs[0]);
      }
    }
    if (tabValue == 'webstart' || tabValue == 'partner') {
      dispatch(setShowChannel(tabValue, false));
    } else {
      dispatch(deleteChannel(tabValue));
    }
  };

  const handleAddChannel = () => {
    dispatch(addChannel(valueChannelInput));
  }

  const closeSticky = () => {
    setIsSticky(false);
    dispatch(openPanel(''));
  }

  const handleRemoveVideo = (videoId: string) => {
    const newVideosInfo = {...myPlaylist.videosInfo, items: myPlaylist.videosInfo?.items?.filter(p => p.id !== videoId)}
    const newMyPlaylist = {...myPlaylist, videosInfo: newVideosInfo};
    setPlayVideoId(newMyPlaylist?.videosInfo?.items[0]?.id || '');
    setMyPlaylist(newMyPlaylist);
    channelServices.deletePlaylist(videoId);
  }

  const handlePopupInstruction = () => {
    setIsPopupInstruction(true);
  }

  return (
    <>
      {((loggedIn && dataChannel.showChannel?.all == true) || !loggedIn) &&
        <div className='can-hide'>
          <Tooltip title="Channel">
            <div>
              <IconButton onClick={handleOpen}>
                <VideoLibraryIcon className={classes.actionButton} />
              </IconButton>
            </div>
          </Tooltip>
        </div>
      }
      {loggedIn && dataChannel.showChannel?.all == true &&
        <SlidingPane
          className={'ce-sliding-panel '}
          overlayClassName={'ce-overlay-sliding-panel '  + (isSticky ? 'is-sticky' : '')}
          isOpen={(name === PANEL_NAME) || isSticky}
          from='bottom'
          width='100%'
          hideHeader
          shouldCloseOnEsc
          closeIcon={<div>&times;</div>}
          onRequestClose={!isSticky ? handleOpen : () => {}}
        >
          <div className={classes.tabroot}>
            {dataChannel.isWaiting &&
              <div className={classes.loading}>
                <CircularProgress />
              </div>
            }
            
            <TabContext value={valueTab}>
              <Tabs
                value={valueTab}
                onChange={handleChangeTab}
                orientation="vertical"
                variant="scrollable"
                indicatorColor='secondary'
                textColor='secondary'
                aria-label='icon tabs'
                className={classes.tabs}
              >
                <div className="termYT">
                <Link href="https://www.youtube.com/t/terms" target="_blank">
                  YouTube's Terms of Service
                </Link>&nbsp;|&nbsp;
                <Link href="https://policies.google.com/privacy" target="_blank">
                  Google Privacy Policy
                </Link>
                </div>
                <Tab
                  icon={<SearchIcon color="secondary" onClick={(e) => handleCloseTab(e, 'search')} />}
                  label='Search'
                  aria-label='search'
                  value='search'
                />
                {dataChannel?.webstart_channel?.channel_id &&
                  dataChannel.showChannel?.webstart == true &&
                  <Tab
                    icon={<HomeIcon color="secondary" onClick={(e) => handleCloseTab(e, 'webstart')} />}
                    label='Webstars Channel'
                    aria-label='webstart'
                    value='webstart'
                  />
                }
                {dataChannel?.partner_channel?.channel_id &&
                  dataChannel.showChannel?.partner == true &&
                  <Tab
                    icon={<DeckIcon color="secondary" onClick={(e) => handleCloseTab(e, 'partner')} />}
                    label='Partners Channel'
                    aria-label='partner'
                    value='partner'
                  />
                }
                {/* {dataChannel?.user_channels?.length > 0 &&
                  dataChannel?.user_channels.map((item: any, index: number) => (
                    <Tab
                      icon={<HighlightOffIcon color="secondary" onClick={(e) => handleCloseTab(e, item.channel_id)} />}
                      key={index}
                      label={item?.info?.title || 'User Channel'}
                      aria-label='user'
                      value={item.channel_id}
                    />
                  ))
                } */}

                {(!dataChannel?.user_channels?.length || dataChannel?.user_channels?.length < 15) &&
                  <Tab
                    icon={<AddToPhotosIcon color="secondary" />}
                    label='My Channel'
                    aria-label='new channel'
                    value='add_new_channel'
                  />
                }
                <Tab
                  icon={<LibraryMusicIcon color="secondary" onClick={(e) => handleCloseTab(e, 'my_playlist')} />}
                  label='My Favorite Videos'
                  aria-label='my_playlist'
                  value='my_playlist'
                />
              </Tabs>
              <TabPanel value='search' className={classes.tabContent}>
                <SearchPanel
                  isSticky={isSticky}
                  closeSticky={closeSticky}
                />
              </TabPanel>
              {dataChannel?.webstart_channel?.channel_id &&
                dataChannel.showChannel?.webstart == true &&
                <TabPanel value='webstart' className={classes.tabContent}>
                  <PlaylistPanel 
                    dataChannel={dataChannel?.webstart_channel} 
                    isSticky={isSticky}
                    closeSticky={closeSticky}
                  />
                </TabPanel>
              }

              {dataChannel?.partner_channel?.channel_id &&
                dataChannel.showChannel?.partner == true &&
                <TabPanel value='partner' className={classes.tabContent}>
                  <PlaylistPanel 
                    dataChannel={dataChannel?.partner_channel} 
                    isSticky={isSticky}
                    closeSticky={closeSticky}
                  />
                </TabPanel>
              }
              {dataChannel?.user_channels?.length > 0 &&
                dataChannel?.user_channels.map((item: any, index: number) => (
                  <TabPanel value={item.channel_id} className={classes.tabContent} key={'user_channel_' + index}>
                    <PlaylistPanel 
                      dataChannel={item} 
                      isSticky={isSticky}
                      closeSticky={closeSticky}
                    />
                  </TabPanel>
                ))}

              {(!dataChannel?.user_channels?.length || dataChannel?.user_channels?.length < 15) &&
                <TabPanel value='add_new_channel' className={classes.tabContent}>
                  <Paper component="form" className={classes.channelRoot}>
                    <Typography className={classes.titleForm}>Add new youtube channel:</Typography>
                    <div className={classes.formContainer}>
                      <div className={classes.formGroup}>
                        <TextField label="Paste Channel id or a video URL link from youtube to here" className={classes.channelInput} value={valueChannelInput} onChange={(e) => setValueChannelInput(e.target.value)} onKeyPress={(ev) => {
                if (ev.key === 'Enter') {
                  ev.preventDefault();
                  handleAddChannel();
                }
              }}/>
                        <Button variant="outlined" color="secondary" style={{ marginTop: '16px', marginLeft: '10px' }}
                          onClick={handleAddChannel}
                        >
                          Add
                        </Button>
                        <div className={classes.instruction}>
                          <a onClick={handlePopupInstruction}>Where you get CHANNEL ID from youtube</a>
                        </div>
                      </div>
                    </div>
                    <div className={classes.rootstep}>     
                      <ListChannel handleClick={handleChangeTab} handleClose={handleCloseTab}/>                     
                      {/* <HowtoAddChannel /> */}
                    </div>
                  </Paper>

                </TabPanel>
              }
              <TabPanel value='my_playlist' className={classes.tabContent}>
                <PlaylistVideo
                  listVideo={myPlaylist?.videosInfo?.items || []}
                  playVideoId={playVideoId || ''}
                  isSticky={isSticky}
                  closeSticky={closeSticky}
                  typePlaylist="my_playlist"
                  handleRemoveVideo={handleRemoveVideo}
                />
              </TabPanel>
              <PopupInstruction 
                isPopupInstruction={isPopupInstruction}
                setIsPopupInstruction={setIsPopupInstruction}
              />
            </TabContext>
          </div>
        </SlidingPane>
      }
    </>
  );
};

export default ChannelWidget;
