import ChannelService from '../../services/channel';
import { AllState } from 'src/types/AllState';
import * as types from './actionTypes';
import { toast } from 'react-toastify';
import moment from 'moment';
import UserService from '../../services/user';
import { UserPreferenceTypes } from 'src/types/UserPreferenceType';

export function getChannels(): (
  dispatch: any,
  getState: any
) => Promise<void> {
  return async (dispatch: any, getState: () => AllState): Promise<void> => {
    try {
      dispatch({
        type: types.SET_WAITING,
        payload: true,
      });

      const { data } = await ChannelService.getChannels();
      const responeData = data.data;
      let returnData: any = {
        webstart_channel: {},
        partner_channel: {},
        user_channels: []
      }
      if (responeData) {
        if (responeData.webstart_channel) {
          returnData['webstart_channel']['channel_id'] = responeData.webstart_channel;

          const playlist = await ChannelService.getYoutubePlaylistInfo(responeData.webstart_channel);
          if (playlist?.data?.playlistInfo?.items) {
            returnData['webstart_channel']['info'] = { title: playlist?.data?.playlistInfo?.items[0]?.snippet?.channelTitle }
            returnData['webstart_channel']['playlist'] = playlist?.data?.playlistInfo?.items;
            returnData['webstart_channel']['firstVideo'] = playlist?.data?.firstVideo;
          }
        }

        if (responeData.partner_channel) {
          returnData['partner_channel']['channel_id'] = responeData.partner_channel;

          const playlist = await ChannelService.getYoutubePlaylistInfo(responeData.partner_channel);
          if (playlist?.data?.playlistInfo?.items) {
            returnData['partner_channel']['info'] = { title: playlist?.data?.playlistInfo?.items[0]?.snippet?.channelTitle }
            returnData['partner_channel']['playlist'] = playlist?.data?.playlistInfo?.items;
            returnData['partner_channel']['firstVideo'] = playlist?.data?.firstVideo;
          }
        }

        if (responeData.user_channels && responeData.user_channels.length > 0) {
          for (let i = 0; i < responeData.user_channels.length; i++) {
            let userChannel: any = {};
            userChannel['channel_id'] = responeData.user_channels[i].channel_id;
            userChannel['thumbnails'] = responeData.user_channels[i].thumbnails ?? 'https://cdn.shopify.com/s/files/1/0958/7836/products/no-image-icon-15_b4dbe501-df49-4986-9bc3-e18bf672194b_5000x.png';
            userChannel['title'] = responeData.user_channels[i].title ?? 'No Title';
            const playlist = await ChannelService.getYoutubePlaylistInfo(responeData.user_channels[i].channel_id);
            if (playlist?.data?.playlistInfo?.items) {
              userChannel['info'] = { title: playlist?.data?.playlistInfo?.items[0]?.snippet?.channelTitle }
              userChannel['playlist'] = playlist?.data?.playlistInfo?.items;
              userChannel['firstVideo'] = playlist?.data?.firstVideo;
            }
            returnData['user_channels'].push(userChannel);
          }
        }
      }

      await dispatch({
        type: types.GET_CHANNEL_SUCCESS,
        payload: returnData,
      });
    } catch (error) {
      console.error(error);
    } finally {
      dispatch({
        type: types.SET_WAITING,
        payload: false,
      });
    }
  };
}

export function deleteChannel(channel_id: string): (
  dispatch: any,
  getState: any
) => Promise<void> {
  return async (dispatch: any, getState: () => AllState): Promise<void> => {
    try {
      dispatch({
        type: types.SET_WAITING,
        payload: true,
      });

      const result = await ChannelService.deleteChannel(channel_id);
      
      const { channels } = getState();
      const returnData = channels;
      returnData['user_channels'] = channels.user_channels.filter((x: any) => x.channel_id !== channel_id);

      await dispatch({
        type: types.GET_CHANNEL_SUCCESS,
        payload: returnData,
      });
    } catch (error) {
      console.error(error);
    } finally {
      dispatch({
        type: types.SET_WAITING,
        payload: false,
      });
    }
  };
}

export function addChannel(channel_id: string): (
  dispatch: any,
  getState: any
) => Promise<void> {
  return async (dispatch: any, getState: () => AllState): Promise<void> => {
    try {
      dispatch({
        type: types.SET_WAITING,
        payload: true,
      });

      const { channels } = getState();
      const returnData = channels;

      const result = await ChannelService.addChannel(channel_id);
      if (result.data.status == 'fail') {
        toast.error(result.data.message);
      } else {
        toast.success(result.data.message);
        const newChannel = result.data.data;

        let userChannel: any = {};
        userChannel['channel_id'] = newChannel.channel_id;
        userChannel['thumbnails'] = newChannel.thumbnails ?? 'https://cdn.shopify.com/s/files/1/0958/7836/products/no-image-icon-15_b4dbe501-df49-4986-9bc3-e18bf672194b_5000x.png';
        userChannel['title'] = newChannel.title ?? 'No Title';
        const playlist = await ChannelService.getYoutubePlaylistInfo(newChannel.channel_id);
        if (playlist?.data?.playlistInfo?.items) {
          userChannel['info'] = { title: playlist?.data?.playlistInfo?.items[0]?.snippet?.channelTitle }
          userChannel['playlist'] = playlist?.data?.playlistInfo?.items;
          userChannel['firstVideo'] = playlist?.data?.firstVideo;
        }
        returnData['user_channels'].unshift(userChannel);
        returnData['type'] = 'add_' + moment();
      }
      
      await dispatch({
        type: types.GET_CHANNEL_SUCCESS,
        payload: returnData,
      });
    } catch (error) {
      console.error(error);
    } finally {
      dispatch({
        type: types.SET_WAITING,
        payload: false,
      });
    }
  };
}

export function updateChannelOptions(data: any) {
  return {
    type: types.SET_SHOW_CHANNEL,
    payload: data,
  };
}

export function setShowChannel(
  key: string, value: boolean
): (dispatch: any, getState: any) => Promise<void> {
  return async (dispatch: any, getState: () => AllState): Promise<void> => {
    try {
      const channelOptions = getState().channels.showChannel;
      const newOptions = {
        ...channelOptions,
        [key]: value,
      }
      console.log(newOptions);
      await dispatch({
        type: types.SET_SHOW_CHANNEL,
        payload: newOptions,
      });
      await UserService.postUserPreference(
        newOptions,
        UserPreferenceTypes.channel
      );
    } catch (error) {
      console.error(error);
    }
  };
}

export function searchVideos(search: any): (
  dispatch: any,
  getState: any
) => Promise<void> {
  return async (dispatch: any, getState: () => AllState): Promise<void> => {
    try {
      dispatch({
        type: types.SET_WAITING,
        payload: true,
      });

      const data = await ChannelService.searchYoutubeVideoInfo(search);

      await dispatch({
        type: types.SEARCH_VIDEO_SUCCESS,
        payload: data.data,
      });
    } catch (error) {
      console.error(error);
    } finally {
      dispatch({
        type: types.SET_WAITING,
        payload: false,
      });
    }
  };
}