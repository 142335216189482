import React, { useCallback, useEffect } from 'react';
import { Box, Button, Grid, TextField } from '@material-ui/core';
import { useFormik, FormikHelpers, FormikErrors } from 'formik';
import { toast } from 'react-toastify';
import { object, string } from 'yup';
import api from '../../../../services/api';

interface FormProps {
  email: string;
}

const initialValues: FormProps = {
  email: '',
};

const validationSchema = object().shape({
  email: string()
    .required('Email address is required.')
    .email('Email address not formed correctly.'),
});

interface Props {
  shouldReset: boolean;
}

const ForgotForm: React.FC<Props> = ({ shouldReset }) => {
  const onSubmit = ({ email }: FormProps) => {
    api({
      method: 'post',
      data: {
        email: email,
      },
      url: '/api/forgot-password',
    })
      .then((rs) => {
        toast.success('Email Reset is sent successfully. Please check it.');
      })
      .catch((err) => {
        if (err?.response?.status === 422) {
          toast.error(err.response.data.message, {
            position: 'top-right',
          });
        }
      });
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  useEffect(() => {
    if (shouldReset) {
      formik.resetForm();
    }
  }, [shouldReset]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box px={3} pt={0} pb={6}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id='email'
              name='email'
              label='Email'
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              InputLabelProps={{ className: 'Mui-black' }}
            />
          </Grid>
        </Grid>
      </Box>
      <Button
        fullWidth
        variant='contained'
        type='submit'
        color='primary'
        className='Mui-submitFormBtn'
      >
        Reset Password
      </Button>
    </form>
  );
};

export default ForgotForm;
