export interface Quote {
  text: string;
  author: string;
}

export type QuotePosition = 'top' | 'bottom';

export enum QuoteSize {
  'Small' = '30',
  'Medium' = '35',
  'Large' = '40',
}
