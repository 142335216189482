import React, { useCallback } from 'react';
import { Button } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { socialAuth } from 'src/store/user/actions';
import './buttons.styles.scss';

const redirect_uri = chrome.identity ? encodeURIComponent(
   `${chrome.identity?.getRedirectURL()}provider_cb`  
) : `${process.env.REACT_APP_FACEBOOK_REDIRECT_URL}`;

const authURL = `https://www.facebook.com/v10.0/dialog/oauth?client_id=${process.env.REACT_APP_FACEBOOK_APP_ID}&redirect_uri=${redirect_uri}&response_type=token&scope=email&state="{st=state}"`;

let isOpening: boolean = false;

const FacebookButton: React.FC = () => {
  const dispatch = useDispatch();

  const handleClick = useCallback(() => {
    if (chrome.identity) {
      !isOpening &&
      chrome.identity?.launchWebAuthFlow(
        { url: authURL, interactive: true },
        async (responseUrl: any) => {
          isOpening = false;
          if (chrome?.runtime?.lastError) {
            console.log(chrome.runtime.lastError);
            return;
          }
  
          const url = new URL(responseUrl);
          const urlParams = new URLSearchParams(url?.hash?.slice(1));
          const params = Object.fromEntries(urlParams.entries());
  
          if (params.access_token?.length > 0) {
            const error: any = await dispatch(
              socialAuth(params.access_token, 'facebook')
            );
            if (error) {
              toast.error(error);
            }
          }
        }
      );
    } else {
      var win:any = window.open(authURL, "windowname", 'toolbar=no, menubar=no, width=600, height=700, top=100, left=100'); 

        var pollTimer = window.setInterval(async function() { 
            try {
                if (win.document.URL.indexOf(redirect_uri) !== -1) {
                    win.close();
                    window.clearInterval(pollTimer);
                    var responseUrl =   win.document.URL;
                    const url = new URL(responseUrl);
                    const urlParams = new URLSearchParams(url?.hash?.slice(1));
                    const params = Object.fromEntries(urlParams.entries());
            
                    if (params.access_token?.length > 0) {
                      const error: any = await dispatch(
                        socialAuth(params.access_token, 'facebook')
                      );
                      if (error) {
                        toast.error(error);
                      }
                    }
                }
            } catch(e) {
            }
        }, 100);
    }
    isOpening = true;
  }, [dispatch]);

  return (
    <Button
      color='primary'
      variant='contained'
      className='Mui-socialBtn'
      onClick={handleClick}
    >
      <div className='iconWrapper'>
        <img
          className='facebookIcon'
          src='https://upload.wikimedia.org/wikipedia/commons/f/ff/Facebook_logo_36x36.svg'
        />
      </div>
      <p className='btnText'>
        <b>Facebook</b>
      </p>
    </Button>
  );
};

export default FacebookButton;
