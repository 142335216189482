import axios, { AxiosResponse } from 'axios';
import { ILandscapeStoreState } from 'src/types/LandscapeStoreState';
import {
  UserPreferenceType,
  UserPreferenceTypes,
} from 'src/types/UserPreferenceType';
import api from './api';
import chromeStore from './chromeStore';

class UserService {
  public async register(data: any): Promise<AxiosResponse> {
    return await api({
      method: 'post',
      url: `${process.env.REACT_APP_API_BASE_URL}/api/sign-up`,
      data: data,
    });
  }

  public async login(data: any): Promise<AxiosResponse> {
    return await api({
      method: 'post',
      url: `${process.env.REACT_APP_API_BASE_URL}/api/sign-in`,
      data: data,
    });
  }

  public async googleAuth(code: string, data: any): Promise<AxiosResponse> {
    return await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_BASE_URL}/api/auth/google`,
      headers: {
        Authorization: code,
      },
      data: data
    });
  }

  public async facebookAuth(access_token: string, data: any): Promise<AxiosResponse> {
    return await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_BASE_URL}/api/auth/facebook`,
      headers: {
        Authorization: access_token,
      },
      data: data
    });
  }

  public async getUserData(): Promise<AxiosResponse> {
    return await api({
      method: 'get',
      url: '/api/user',
    });
  }

  public async getCalendarEvents(
    start: string,
    end: string
  ): Promise<AxiosResponse> {
    return await api({
      method: 'get',
      url:
        '/api/user/calendar-events?' +
        `startDate=${encodeURIComponent(start)}&endDate=${encodeURIComponent(
          end
        )}`,
    });
  }

  public async getCalendarEventDates(
    start: string,
    end: string
  ): Promise<AxiosResponse> {
    return await api({
      method: 'get',
      url:
        '/api/user/calendar-event-dates?' +
        `startDate=${encodeURIComponent(start)}&endDate=${encodeURIComponent(
          end
        )}`,
    });
  }

  public async createCalendarEvents(
    start_date: string,
    due_date: string,
    title: string,
    description: string,
  ): Promise<AxiosResponse> {
    return await api({
      method: 'post',
      url: '/api/user/calendar-events',
      data: { start_date, due_date, title, description}
    });
  }

  public async patchCalendarEvents(
    id: number,
    dataPatch: any
  ): Promise<AxiosResponse> {
    return await api({
      method: 'patch',
      url: `/api/user/calendar-events/${id}`,
      data: { ...dataPatch }
    });
  }

  public async updateCalendarEvents(
    id: number,
    start_date: string,
    due_date: string,
    title: string,
    description: string,
  ): Promise<AxiosResponse> {
    return await api({
      method: 'put',
      url: `/api/user/calendar-events/${id}`,
      data: { start_date, due_date, title, description}
    });
  }

  public async deleteCalendarEvents(
    id: number
  ): Promise<AxiosResponse> {
    return await api({
      method: 'delete',
      url: `/api/user/calendar-events/${id}`,
    });
  }

  public async getUserPreferences(): Promise<AxiosResponse> {
    return await api({
      method: 'get',
      url: '/api/preferences',
    });
  }

  public async postUserPreference(
    data: any,
    preferenceType: UserPreferenceType = UserPreferenceTypes.landscape
  ): Promise<AxiosResponse> {
    return await api({
      method: 'post',
      url: `/api/preference?preferenceType=${preferenceType}`,
      data,
    });
  }

  public postLandscapePreferencesToChromeStorage(
    data: Partial<ILandscapeStoreState>
  ): void {
    chromeStore.set('landscape-preferences', data);
  }

  public async getLandscapePreferencesFromChromeStorage(): Promise<any> {
    return await chromeStore.get('landscape-preferences');
  }
}

export default new UserService();
