import { AxiosResponse } from 'axios';
import api from './api';

class LandscapeService {
  public async getRandomDailyQuote(): Promise<AxiosResponse> {
    return await api({
      method: 'get',
      url: '/api/daily-quote',
    });
  }
}

export default new LandscapeService();
