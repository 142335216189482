import React from 'react';
import { makeStyles, Tooltip, IconButton } from '@material-ui/core';
import withoutMoving from 'src/components/hocs/withMoving/withoutMoving';
import webstarsLogo from 'src/assets/ws_logo4.png';
//import webstarsLogo from 'src/assets/Airrackwhite_140x@2x.gif';

const useStyles = makeStyles(() => ({
    logo: {
      'user-select': 'none',
      'font-weight': 'bold',
      cursor: 'pointer',
      minWidth: 'max-content',
      backgroundColor: 'rgba(255,255,255,0.65)',
      borderRadius: '15px',
      padding:'5px 10px',
    },
    img:{
        height:'35px',
        filter: 'drop-shadow(0 0 6px rgba(142,142,142,0.3))',
        verticalAlign: 'middle',
    },
    text:{        
      fontWeight: 'bold',
      fontSize: '24px',
      color: 'white',  
    }
  }));


const HomeLogoWidget: React.FC = () => {
  const classes = useStyles();  

  return (   
        <div className={classes.logo}>        
            <a href="https://webstars.us" target="_blank"><img src={webstarsLogo} className={classes.img} /></a>
            {/* <span className={classes.text}>Webstars</span>           */}
        </div>    
  ) ;
};

export default withoutMoving(HomeLogoWidget, 'homeLogo');
