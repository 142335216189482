import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import withMoving from 'src/components/hocs/withMoving/withMoving';
//import withoutMoving from 'src/components/hocs/withMoving/withoutMoving';
import * as _ from 'lodash';
import { AllState } from 'src/types/AllState';
import ActionButton, { ActionButtonProps } from '../components/ActionButton';
import { getPromotions } from 'src/store/promotions/actions';
import { Avatar, IconButton, makeStyles, Tooltip } from '@material-ui/core';
import {
  ArrowBackIos,
  ArrowForwardIos,
  ViewComfy,
  Add,
  KeyboardArrowUp,
  KeyboardArrowDown,
} from '@material-ui/icons';
import Modal from 'src/components/containers/Modal/Modal';
import { grey } from '@material-ui/core/colors';
import FavoritesManagement from 'src/components/widgets/Edit/components/Favorites';
import { IPanel } from 'src/types/IPanel';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    left: '90px',
    transform: 'translateX(-50%)',
    top: '12vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  'box-content': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    opacity: '0.001',
    transition: 'all 0.5s',
    '&:hover': {
      opacity: '1',
    },
  },
  content: {
    [theme.breakpoints.down('sm')]: {
      width: '120px',
    },
    [theme.breakpoints.up('md')]: {
      width: '140px',
    },
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
  },
  'page-action': {
    [theme.breakpoints.down('sm')]: {
      width: '120px',
    },
    [theme.breakpoints.up('md')]: {
      width: '140px',
    },

    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    '& :nth-child(n)': {
      [theme.breakpoints.down('sm')]: {
        margin: '0px',
        fontSize: '40px',
      },
      [theme.breakpoints.up('md')]: {
        margin: '5px',
        fontSize: '60px',
      },
    },
  },
  'content-child': {
    boxSizing: 'border-box',
    width: 'calc(1/2*100% - (1 - 1/2)*1px)',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      height: '70px',
    },
    [theme.breakpoints.up('md')]: {
      height: '80px',
    },
    
    '&:nth-child(2n)': {
      marginRight: '0',
    },
    '&::after': {
      content: '',
      flex: 'auto',
    },
    '&:nth-child(-n+2)': {
      marginTop: '0',
    },
    '& .Mui-favoriteItem': {
      padding: '12px',
      //background: 'rgba(0, 0, 0, 0.3)',
      transform: 'scale(1.06)',
      transformOrigin: '50% 100%',
      boxShadow: 'rgba(255,255,255,0.9) 0px 1px 5px 1px',
      [theme.breakpoints.down('sm')]: {
        width: '20px',
        height: '20px',
      },
      [theme.breakpoints.up('md')]: {
        width: '45px',
        height: '45px',
      },
      '& .MuiAvatar-root': {
        transform: 'scale(0.9)',    
        width: '26px',
        height: '26px',  
      },
      '&:hover': {
        padding: '7px',
        width: '50px',
        height: '50px',
      }
    },
  },
  'pagination-button': {
    color: 'white',
    fontWeight: 'bold',
    fontSize: '3em',
    cursor: 'pointer',
  },
  'shortcut-btn': {
    display: 'flex',
    alignContent: 'center',
    lineHeight: '25px',
    fontSize: '1em',
    color: 'white',
    marginBottom: '25px',
    cursor: 'pointer',
  },
  'shortcut-name': {
    textAlign: 'center',
    color: 'white',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.6em',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '.8em',
    },

    fontWeight: 'bold',
    overflow: 'hidden !important',
    textOverflow: 'ellipsis',
  },
  modalPaper: {
    maxWidth: '720px',
    width: '470px !important',
  },
}));

const FavoritesButton: React.FC<ActionButtonProps> = withMoving(
  ActionButton,
  'favorites'
);

const FavoritesWidget: React.FC = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const perPage = 8;

  const { loggedInUser, namePanel, favorites } = useSelector(
    ({ user, panel, promotions }: AllState) => ({
      loggedInUser: user.loggedInUser,
      namePanel: panel.name,
      ...promotions,
    })
  );

  const [isShowShortcut, setShowShortcut] = useState<boolean>(true);
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [carouselPage, setCarouselPage] = useState(0);

  useEffect(() => {
    dispatch(getPromotions());
  }, []);

  const listFavorite: Array<any> = [];
  favorites?.map((item) => {
    listFavorite.push(item);
  });

  listFavorite.push({
    idx: 'add_new',
    typeItem: 'add_new_remove',
    rank: -1,
  });

  const favoriteGroups = _.chunk(
    _.orderBy(listFavorite, ['rank'], ['desc']),
    perPage
  );
  if (favoriteGroups.length - 1 < carouselPage) {
    setCarouselPage(favoriteGroups.length - 1);
  }
  function onPrevClick() {
    setCarouselPage(
      carouselPage === 0 ? favoriteGroups.length - 1 : carouselPage - 1
    );
  }

  function onNextClick() {
    setCarouselPage(
      carouselPage === favoriteGroups.length - 1 ? 0 : carouselPage + 1
    );
  }

  return (
    <>
      {namePanel === '' && (
        <div className={classes.root}>
          <span className={classes['shortcut-btn']}>
            <a
              onClick={() => setModalOpen((prev) => !prev)}
              title='Add your favorite'
            >
              <Add />
            </a>
            <Tooltip title='Favorites'>              
                <span style={{ marginLeft: '5px' }}>Favorites</span>              
            </Tooltip>
          </span>          
            <div className={classes['box-content']}>
              <div className={classes.content}>
                {favoriteGroups
                  .slice(carouselPage, carouselPage + 1)
                  .map((favoriteGroup, idG) => {
                    return (
                      <React.Fragment key={'G' + idG}>
                        {favoriteGroup.map((x, idx) => (
                          <React.Fragment key={idx}>
                            {!x.typeItem && (
                              <div className={classes['content-child']}>
                                <IconButton
                                  className='Mui-favoriteItem'
                                  component='a'
                                  href={
                                    x.url?.includes('//')
                                      ? x.url
                                      : `https://${x.url}`
                                  }
                                  target='_blank'
                                >
                                  <Avatar
                                    src={`https://www.google.com/s2/favicons?sz=32&domain=${x.url}`}                                    
                                    classes={{ img: 'Mui-favoriteImg' }}
                                  >
                                    {x.name?.slice(0, 1).toUpperCase()}
                                  </Avatar>
                                </IconButton>
                                <div className={classes['shortcut-name']}>
                                  {x.name}
                                </div>
                              </div>
                            )}
                          </React.Fragment>
                        ))}
                      </React.Fragment>
                    );
                  })}
              </div>
              <div className={classes['page-action']}>
                {!!listFavorite && listFavorite.length > perPage && (
                  <KeyboardArrowUp
                    className={classes['pagination-button']}
                    onClick={() => onPrevClick()}
                  />
                )}
                {!!listFavorite && listFavorite.length > perPage && (
                  <KeyboardArrowDown
                    onClick={() => onNextClick()}
                    className={classes['pagination-button']}
                  />
                )}
              </div>
            </div>          
        </div>
      )}
      {isModalOpen && (
        <Modal
          open={isModalOpen}
          useBackdrop
          header='My Favorites'
          handleClose={() => setModalOpen(false)}
          paperClassName={classes.modalPaper}
        >
          <FavoritesManagement />
        </Modal>
      )}
    </>
  );
};
// Need add HOC moving
export default withMoving(FavoritesWidget,'favorites');
