import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import 'fontsource-roboto';
import App from 'src/components/containers/App/App';
import 'src/styles/base.scss';
import store from './store';
// import Analytics from 'analytics';
import * as setup from './helpers/setup';

// @ts-ignore
// import googleAnalytics from '@analytics/google-analytics';

setup.install();

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);

// From documentation (https://www.npmjs.com/package/@analytics/google-analytics)
// const analytics = Analytics({
//   app: 'webstars-chrome-extension',
//   plugins: [
//     googleAnalytics({
//       trackingId: 'UA-193050269-1'
//     }),
//   ],
// });
 
// // Track a pageview.
// analytics.page();


