export default [
  '#F3C6D1',
  '#CBA6CC',
  '#A09BCC',
  '#90CCDE',
  '#D3F1D2',
  '#EBD4B8',
  '#DCAAAA',
  '#AADCD0',
  '#BAC0BE',
  '#D9DEE0',
  '#ffd3b6',
  '#ffaaa5',
  '#a8e6cf',
  '#dcedc1',
  '#ff8b94',
  '#96ceb4',
  '#ffeead',
  '#ff6f69',
  '#ffcc5c',
  '#88d8b0',
  '#66545e',
  '#a39193',
  '#aa6f73',
  '#eea990',
  '#f6e0b5',
  '#464675',
  '#34A0B5',
  '#F2C28A',
  '#E97870',
  '#B52B65',
  '#772A53',
  '#9D7892',
  '#D6A2A3',
  '#F4C87E',
  '#E87C5D',
  '#4C71C7',
  '#3AB57B',
  '#FDE490',
  '#F99FBA',
  '#C9346B',
];
