export interface Language {
  title: string;
  value: string;
}

const voiceSearchLanguages: Language[] = [
  { title: 'Afrikaans', value: 'af' },
  { title: 'Basque', value: 'eu' },
  { title: 'Bulgarian', value: 'bg' },
  { title: 'Catalan', value: 'ca' },
  { title: 'Arabic (Egypt)', value: 'ar-EG' },
  { title: 'Arabic (Jordan)', value: 'ar-JO' },
  { title: 'Arabic (Kuwait)', value: 'ar-KW' },
  { title: 'Arabic (Lebanon)', value: 'ar-LB' },
  { title: 'Arabic (Qatar)', value: 'ar-QA' },
  { title: 'Arabic (UAE)', value: 'ar-AE' },
  { title: 'Arabic (Morocco)', value: 'ar-MA' },
  { title: 'Arabic (Iraq)', value: 'ar-IQ' },
  { title: 'Arabic (Algeria)', value: 'ar-DZ' },
  { title: 'Arabic (Bahrain)', value: 'ar-BH' },
  { title: 'Arabic (Lybia)', value: 'ar-LY' },
  { title: 'Arabic (Oman)', value: 'ar-OM' },
  { title: 'Arabic (Saudi Arabia)', value: 'ar-SA' },
  { title: 'Arabic (Tunisia)', value: 'ar-TN' },
  { title: 'Arabic (Yemen)', value: 'ar-YE' },
  { title: 'Czech', value: 'cs' },
  { title: 'Dutch', value: 'nl-NL' },
  { title: 'English (Australia)', value: 'en-AU' },
  { title: 'English (Canada)', value: 'en-CA' },
  { title: 'English (India)', value: 'en-IN' },
  { title: 'English (New Zealand)', value: 'en-NZ' },
  { title: 'English (South Africa)', value: 'en-ZA' },
  { title: 'English(UK)', value: 'en-GB' },
  { title: 'English(US)', value: 'en-US' },
  { title: 'Finnish', value: 'fi' },
  { title: 'French', value: 'fr-FR' },
  { title: 'Galician', value: 'gl' },
  { title: 'German', value: 'de-DE' },
  { title: 'Greek', value: 'el-GR' },
  { title: 'Hebrew', value: 'he' },
  { title: 'Hungarian', value: 'hu' },
  { title: 'Icelandic', value: 'is' },
  { title: 'Italian', value: 'it-IT' },
  { title: 'Indonesian', value: 'id' },
  { title: 'Japanese', value: 'ja' },
  { title: 'Korean', value: 'ko' },
  { title: 'Latin', value: 'la' },
  { title: 'Mandarin Chinese', value: 'zh-CN' },
  { title: 'Taiwanese', value: 'zh-TW' },
  { title: 'Cantonese', value: 'zh-HK' },
  { title: 'Malaysian', value: 'ms-MY' },
  { title: 'Norwegian', value: 'no-NO' },
  { title: 'Polish', value: 'pl' },
  { title: 'Pig Latin', value: 'xx-piglatin' },
  { title: 'Portuguese', value: 'pt-PT' },
  { title: 'Portuguese (Brasil)', value: 'pt-br' },
  { title: 'Romanian', value: 'ro-RO' },
  { title: 'Russian', value: 'ru' },
  { title: 'Serbian', value: 'sr-SP' },
  { title: 'Slovak', value: 'sk' },
  { title: 'Spanish (Argentina)', value: 'es-AR' },
  { title: 'Spanish (Bolivia)', value: 'es-BO' },
  { title: 'Spanish (Chile)', value: 'es-CL' },
  { title: 'Spanish (Colombia)', value: 'es-CO' },
  { title: 'Spanish (Costa Rica)', value: 'es-CR' },
  { title: 'Spanish (Dominican Republic)', value: 'es-DO' },
  { title: 'Spanish (Ecuador)', value: 'es-EC' },
  { title: 'Spanish (El Salvador)', value: 'es-SV' },
  { title: 'Spanish (Guatemala)', value: 'es-GT' },
  { title: 'Spanish (Honduras)', value: 'es-HN' },
  { title: 'Spanish (Mexico)', value: 'es-MX' },
  { title: 'Spanish (Nicaragua)', value: 'es-NI' },
  { title: 'Spanish (Panama)', value: 'es-PA' },
  { title: 'Spanish (Paraguay)', value: 'es-PY' },
  { title: 'Spanish (Peru)', value: 'es-PE' },
  { title: 'Spanish (Puerto Rico)', value: 'es-PR' },
  { title: 'Spanish (Spain)', value: 'es-ES' },
  { title: 'Spanish (US)', value: 'es-US' },
  { title: 'Spanish (Uruguay)', value: 'es-UY' },
  { title: 'Spanish (Venezuela)', value: 'es-VE' },
  { title: 'Swedish', value: 'sv-SE' },
  { title: 'Turkish', value: 'tr' },
  { title: 'Zulu', value: 'zu' },
];

export default voiceSearchLanguages;
