import { useEffect } from 'react';

const showWidgetTimeout = 10000;

let idleTimer: any = null;
let isIdleState: boolean = true;

const useHiddenWidgets = () => {
  const showWidget = () => {
    if (idleTimer) {
      clearTimeout(idleTimer);
    }

    if (isIdleState) {
      document
        .querySelectorAll('.can-hide')
        .forEach((x) => x.classList.remove('inactive'));
    }
    
    isIdleState = false;

    const timeout = setTimeout(() => {
      document
        .querySelectorAll('.can-hide')
        .forEach((x) => x.classList.add('inactive'));
      isIdleState = true;
    }, showWidgetTimeout);

    idleTimer = timeout;
  };

  useEffect(() => {
    document
      .querySelectorAll('.can-hide')
      .forEach((x) => x.classList.add('inactive'));

    window.addEventListener('mousemove', showWidget);
  }, []);
};

export default useHiddenWidgets;
