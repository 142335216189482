import withMoving from 'src/components/hocs/withMoving/withMoving';
import React from 'react';
import {
  Clear
} from '@material-ui/icons';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import {
  IconButton
} from '@material-ui/core';
import Draggable from 'react-draggable';
import channelService from 'src/services/channel';
import { toast } from 'react-toastify';
import ReactPlayer from 'react-player/youtube';
interface Props {
  playVideo: string,
  isSticky: boolean,
  closeSticky: () => void
  autoPlay?: boolean,
}

const IframeVideo: React.FC<Props> = ({
  playVideo,
  isSticky,
  closeSticky,
  autoPlay
}) => {

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }

  const handleAddPlaylist = async (videoId) => {
    if (videoId) {
      const { data } = await channelService.addToPlaylist(videoId);
      toast[data.status](data.message);
    }
  }

  return (
    <div className="sticky-video">
      <Draggable
        axis="x"
        handle=".handle"
        // bounds={{right:'10px', bottom: '10px'}}
        defaultPosition={{ x: 0, y: 0 }}
        grid={[25, 25]}
        scale={1}>
        <div>
          {isSticky &&
            <div className="box-close-sticky"><a className="close-sticky"
              onClick={() => closeSticky()}
            >
              <Clear />
            </a>
            </div>
          }
          {!chrome.identity ? (<ReactPlayer width="100%" height="100%" controls pip playsinline light style={{ border: '1px solid #ebebeb88' }}
            url={`https://www.youtube.com/watch?v=${playVideo}`}
          />) : (<iframe
            src={`https://www.youtube.com/embed/${playVideo}?controls=1&playsinline=1&showinfo=0&rel=0&iv_load_policy=3&modestbranding=1&enablejsapi=1&widgetid=1${autoPlay === true ? '&autoplay=1' : ''}`}
            width="100%" height="100%"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" style={{ border: '1px solid #ebebeb88' }}
          />)}


          <IconButton title="Add to favorite videos" aria-label={`Add playlist`} className="addPlaylistIframe" onClick={() => handleAddPlaylist(playVideo || '')}>
            <StarBorderIcon />
          </IconButton>
        </div>
      </Draggable>

    </div>
  )
}

export default withMoving(IframeVideo, 'IframeVideo');
// export default IframeVideo;

