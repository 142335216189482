import React, { useState, useEffect } from 'react';
import loadingGif from 'src/assets/loading.gif';
import StyledImage from 'src/styledComponents/Image';

interface Props {
  src: string;
  alt?: string;
  selected?: boolean;
}

export const LazyImage: React.FC<Props> = ({ src, alt, selected = false }) => {
  const [imageSrc, setImageSrc] = useState(loadingGif);
  const [imageRef, setImageRef] = useState<HTMLImageElement | null>(null);

  const onLoad = (event: any) => {
    event.target.classList.add('loaded');
  };

  const onError = (event: any) => {
    event.target.classList.add('has-error');
  };

  useEffect(() => {
    let observer: IntersectionObserver;
    let didCancel = false;

    if (imageRef && imageSrc !== src) {
      if (IntersectionObserver) {
        observer = new IntersectionObserver(
          (entries) => {
            entries.forEach((entry) => {
              if (
                !didCancel &&
                (entry.intersectionRatio > 0 || entry.isIntersecting)
              ) {
                setImageSrc(src);
                observer.unobserve(imageRef);
              }
            });
          },
          {
            threshold: 0.01,
            rootMargin: '75%',
          }
        );
        observer.observe(imageRef);
      } else {
        setImageSrc(src);
      }
    }
    return () => {
      didCancel = true;
      if (observer && observer.unobserve && imageRef) {
        observer.unobserve(imageRef);
      }
    };
  }, [src, imageSrc, imageRef]);

  return (
    <StyledImage
      className={selected ? 'selected' : ''}
      ref={setImageRef}
      src={imageSrc}
      alt={alt ?? ''}
      onLoad={onLoad}
      onError={onError}
    />
  );
};
