import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Button } from '@material-ui/core';
import { AllState } from 'src/types/AllState';

export interface ActionButtonProps {
  isMoving?: boolean;
  onClick: () => any;
  children: React.ReactNode;
  hideUnauthorized?: boolean;
  hidden?: boolean;
}

const ActionButton: React.FC<ActionButtonProps> = ({
  isMoving,
  children,
  onClick,
  hideUnauthorized,
  hidden,
}) => {
  const loggedIn = useSelector(({ user }: AllState) => user.isAuthenticated);

  const handleClick = useCallback(() => {
    if (!isMoving) {
      onClick();
    }
  }, [isMoving, onClick]);

  return (
    <div className='can-hide'>
      {(hideUnauthorized && !loggedIn) || hidden ? null : (
        <Button color='primary' variant='contained' onClick={handleClick}>
          {children}
        </Button>
      )}
    </div>
  );
};

export default ActionButton;
