import React, { useState } from 'react';
import {
  Avatar,
  Button,
  Fade,
  IconButton,
  makeStyles,
  Modal,
  Theme,
  Tooltip,
  Typography,
} from '@material-ui/core';
import classNames from 'classnames';
import { User } from 'src/models';

const useStyles = makeStyles((theme: Theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
  },
  paper: {
    outline: 'none',
    borderRadius: '3px',
    backgroundColor: '#8c8c8ce3',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 0, 0, 2),
    overflowY: 'unset',
    maxHeight: '100vh',
    position: 'absolute',
    marginRight: '100px',
    marginBottom: '20px',
    minWidth: '350px',
    top: '70px',
    right: '-50px',
  },
  btnWrap: {
    textAlign: 'end',
  },
  userInfo: {
    display: 'flex',
    gap: '20px',
    marginBottom: '15px',
    marginRight: '15px',
  },
}));

interface Props {
  user: User | null;
  logout: () => Promise<void>;
}

const Profile: React.FC<Props> = ({ user, logout }) => {
  const [isOpen, setOpen] = useState<boolean>(false);
  const classes = useStyles();

  return (
    <>
      <Tooltip title='Profile' style={{position: "absolute", top: "0", right: "0", zIndex:1}}>
        <IconButton onClick={() => setOpen((prev) => !prev)} className='Mui-calendar'>
          <Avatar src={user?.picture} style={{width: '24px', height: '24px'}}>
            {user?.name?.slice(0, 1).toUpperCase()}
          </Avatar>
        </IconButton>
      </Tooltip>
      <Modal
        className={classNames(classes.modal, 'can-hide')}
        open={isOpen}
        onClose={() => setOpen(false)}
        closeAfterTransition
        BackdropProps={{
          invisible: true,
        }}
      >
        <Fade in={isOpen}>
          <div className={classes.paper}>
            <div className={classes.userInfo}>
              {!!user?.picture && (
                <Avatar className='Mui-bigAvatar' src={user?.picture} />
              )}
              <div>
                <Typography className='Mui-useName'>{user?.name}</Typography>
                <Typography className='Mui-useEmail'>{user?.email}</Typography>
              </div>
            </div>
            <div className={classes.btnWrap}>
              <Button
                variant='contained'
                color='primary'
                onClick={() => logout()}
              >
                Logout
              </Button>
            </div>
          </div>
        </Fade>
      </Modal>
    </>
  );
};

export default Profile;
