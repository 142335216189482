import Immutable from 'seamless-immutable';
import * as _ from 'lodash';
import * as types from './actionTypes';
import { ISearchStoreState } from '../../types/SearchStoreState';

const emptyState: ISearchStoreState = {
  searches: [],
  count: 0,
};

const initialState = Immutable(_.cloneDeep(emptyState));

export default function reduce(state = initialState, action: any = {}): any {
  switch (action.type) {
    case types.SET_SEARCH_DATA:
      return { ...state, ...action.payload };

    default:
      return state;
  }
}
