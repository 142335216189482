import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { socialAuth } from 'src/store/user/actions';

const client_id = chrome.runtime?.getManifest
  ? chrome.runtime?.getManifest().oauth2?.client_id ?? ''
  : `${process.env.REACT_APP_GOOGLE_CLIENT_ID}`;
let isOpening: boolean = false;
const useGoogleLogin = () => {
  const dispatch = useDispatch();

  const getAuthParams = useCallback((shouldGetConsent: boolean, appType: string = 'extension') => {
    const authParams = new URLSearchParams({
      client_id,
      response_type: 'code',
      redirect_uri: chrome.identity?.getRedirectURL() ?? `${appType == 'web' ? process.env.REACT_APP_GOOGLE_REDIRECT_URL_WEB : process.env.REACT_APP_GOOGLE_REDIRECT_URL}`,
      scope: [
        'https://www.googleapis.com/auth/userinfo.email',
        'https://www.googleapis.com/auth/userinfo.profile',
      ].join(' '),
      prompt: shouldGetConsent ? 'consent' : 'select_account',
      access_type: 'offline',
    });

    return authParams.toString();
  }, []);

  const handleClick = useCallback(
    (shouldGetConsent: boolean = false) => {
      if (chrome.identity) {
        !isOpening && chrome.identity?.launchWebAuthFlow(
          {
            url: `https://accounts.google.com/o/oauth2/v2/auth?${getAuthParams(
              shouldGetConsent
            )}`,
            interactive: true,
          },
          async (responseUrl: any) => {
            isOpening = false;
            if (chrome?.runtime?.lastError) {
              console.log(chrome.runtime.lastError);
              return;
            }

            const url = new URL(responseUrl);
            const urlParams = new URLSearchParams(url?.search?.slice(1));
            const params = Object.fromEntries(urlParams.entries());

            if (params.code?.length > 0) {
              const error: any = await dispatch(
                socialAuth(params.code, 'google')
              );
              if (error) {
                toast.error(error);
              }
            }
          }
        )
      } else {
        var win:any = window.open(`https://accounts.google.com/o/oauth2/v2/auth?${getAuthParams(
          shouldGetConsent,
          'web'
        )}`, "windowname", 'toolbar=no, menubar=no, width=600, height=700, top=100, left=100'); 

        var pollTimer = window.setInterval(async function() { 
            try {
                if (win.document.URL.indexOf(`${process.env.REACT_APP_GOOGLE_REDIRECT_URL_WEB}`) !== -1) {
                    win.close();
                    window.clearInterval(pollTimer);
                    var responseUrl =   win.document.URL;
                    const url = new URL(responseUrl);
                    const urlParams = new URLSearchParams(url?.search?.slice(1));
                    const params = Object.fromEntries(urlParams.entries());
                    if (params.code?.length > 0) {
                      const error: any = await dispatch(
                        socialAuth(params.code, 'google', 'web')
                      );
                      if (error) {
                        toast.error(error);
                      }
                    }
                }
            } catch(e) {
            }
        }, 100);
      }
      isOpening = true;
    },
    [dispatch, getAuthParams]
  );

  return handleClick;
};

export default useGoogleLogin;
