import React, { useEffect } from 'react';
import { makeStyles, Paper, Typography, Tooltip, IconButton, Badge } from '@material-ui/core';
import withoutMoving from 'src/components/hocs/withMoving/withoutMoving';
import { useDispatch, useSelector } from 'react-redux';
import { AllState } from 'src/types/AllState';
import { getUserSearchInfo } from 'src/store/search/actions';
import search from 'src/assets/white/counter-icon.png';

const useStyles = makeStyles(() => ({
  counter: {
    'user-select': 'none',
    'font-weight': 'bold',
    cursor: 'pointer',
    minWidth: 'max-content',
  },
}));

const CounterWidget: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [
    searchesCount,
    loggedInUser,
  ] = useSelector(({ search, user }: AllState) => [
    search.count,
    user.loggedInUser,
  ]);

  useEffect(() => {
    dispatch(getUserSearchInfo());
  }, [loggedInUser]);

  return (
    <div className='can-hide'>
      {!!searchesCount ? (
        <div className='can-hide'>
          <Tooltip title={"Count search: " + searchesCount}>
            <div>
              <IconButton>
                <Badge badgeContent={searchesCount} color="primary">
                  <img src={search} style={{width: "30px"}} />
                </Badge>
              </IconButton>
            </div>
          </Tooltip>
        </div>
      ) : null}
    </div>
  );
};

export default withoutMoving(CounterWidget, 'counter');
