import React, { useEffect, useState } from 'react';
import {
    makeStyles,
    List,
    ListItem,
    ListItemText,
    ListItemAvatar,
    Avatar,
    ListSubheader,
    useTheme,
    IconButton,
    Typography,
    Divider,
} from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import channelService from 'src/services/channel';
import { toast } from 'react-toastify';
import './stickyVideo.scss';
import IframeVideo from './IframeVideo';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
    rootDivTab: {
        display: 'flex',
        height: '100%',        
    },
    rootListVideo: {
        paddingTop: 0,
        overflowX: 'hidden',
        overflowY: 'auto',
        height: '430px',
        width: '100%',
        maxWidth: '320px',
        marginLeft: '20px',
        border: '1px solid #cccccce8',
        backgroundColor: 'rgba(0, 0, 0, .2)',
        
        '& .MuiListItem-button:hover':{
            backgroundColor: 'rgba(245, 0, 87, 0.5)',
        },
    '& .MuiListItemText-primary': {
        color: 'white',
        fontSize:'0.75rem',
    },

    '& .MuiListItemText-secondary': {
        color: '#167ac6',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        width: '150px',
        height: '1.2em',
        whiteSpace: 'nowrap',
    },

      '&::-webkit-scrollbar-track': {
        //borderRadius: '10px',
        border: '1px solid #111111e8',
        padding: '2px 0',
        backgroundColor: 'rgba(0,0,0,0.1)',
      },
      '&::-webkit-scrollbar': {
        width: '10px',
      },
      '&::-webkit-scrollbar-thumb': {
        borderRadius: '5px',
        boxShadow: 'inset 0 0 6px rgba(0,0,0,.6)',
        background: 'linear-gradient(left, #fff, #e4e4e4)',
        border: '3px solid transparent',
        backgroundClip:'padding-box',
      },
      '& .itemVideo':{
        minHeight:93,
      }
    },
    headerListVideo: {
        background: 'rgba(32, 32, 32, .9)',
        fontSize:'14pt',
        fontWeight:'bold',
        color: 'white',
    },
    inlineVideo: {
        display: 'inline',
    },
    thumbVideo: {
        width: '140px',
        height: '80px',
        cursor: 'pointer'
    }
}));

interface IProps {
    dataChannel: any
    isSticky: any,
    closeSticky: () => void
}

type Props = IProps;

const PlaylistPanel: React.FC<Props> = ({dataChannel, isSticky, closeSticky}) => {
  const classes = useStyles();
  const theme = useTheme();

  const [listVideo, setListVideo] = useState([]);

  const [playVideo, setPlayVideo] = useState(dataChannel.firstVideo);

  const [valueSwip, setValueSwip] = useState(0);

  const handleChangeSwip = (index: number) => {
    setValueSwip(index);
  }

  const handleChangeSwipVideo = async (playlistId: number) => {
    const videos = await channelService.getYoutubeVideoInfo(playlistId);
    
    if (videos?.data?.videoInfo?.items?.length > 0) {
        
      const infoVideos = videos?.data?.videoInfo?.items?.map((item: any) => ({
        videoId: item?.contentDetails?.videoId || item?.id?.videoId,
        title: item?.snippet?.title,
        publishedAt: item?.snippet?.publishedAt,
        thumbnail: item?.snippet?.thumbnails?.medium?.url || item?.snippet?.thumbnails?.default?.url
      }));
      //console.log(videos?.data?.videoInfo?.items);
      setListVideo(infoVideos);
    } else {
      setListVideo([]);
    }
    handleChangeSwip(1);
  }

  const handleChangeSwipPlaylist = () => {
    handleChangeSwip(0);
  }

  const handleOpenVideo = (videoId: string) => {
    setPlayVideo(videoId);
  }
  
  const paddingString = (str: string , length:any , ending:any ) => {
    if (length == null) {
        length = 60;
      }
      if (ending == null) {
        ending = '...';
      }

      if (str.length > length) {
        return str.substring(0, length - ending.length) + ending;
      } 
      return str;

  }
  
  return (
    <div className={classes.rootDivTab}>
        <div style={{width: "100%", position: "relative"}}>
            <div className="iframe-video">
                {playVideo &&
                    <IframeVideo playVideo={playVideo} isSticky={isSticky} closeSticky={closeSticky}/>
                }
                {!playVideo &&
                    <iframe
                        src={`https://www.youtube.com/embed/`}
                        width="100%" height="100%"
                        allow='autoplay' style={{border:'1px solid #ebebeb88'}}
                    />
                }
            </div>
        </div>
        <SwipeableViews
            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
            index={valueSwip}
            onChangeIndex={handleChangeSwip}
            style={{width: '500px'}}
        >
        <div style={{overflowX: 'hidden'}}>
            <List className={classes.rootListVideo} >
                <ListSubheader component="div" className={classes.headerListVideo}>
                Playlist
                </ListSubheader>
                {dataChannel?.playlist?.map((x: any) => (
                <>
                
                {x.snippet?.thumbnails?.default?.url.includes('no_thumbnail') === false &&     
                (<ListItem key={x.id}  className="itemVideo" onClick={() => handleChangeSwipVideo(x.id)} dense button title={x.snippet?.title}>
                <ListItemAvatar>
                    <Avatar
                        
                        className={classes.thumbVideo}
                        src={x.snippet?.thumbnails?.medium?.url || x.snippet?.thumbnails?.default?.url}
                        variant="square"
                    />
                </ListItemAvatar>
                <ListItemText
                    primary={
                        <React.Fragment>
                          <Typography
                            variant="body2" gutterBottom 
                          >
                            {paddingString(x.snippet?.title, 40, '...')}
                            {/* <br />
                            {x.snippet?.description} */}
                          </Typography>
                                                    
                        </React.Fragment>
                      }
                    secondary={x.snippet?.channelTitle}
                    //   {x.snippet?.description}
                    style={{marginLeft: '10px'}}
                    
                />                
                </ListItem>
                )}     
                </>       
                ))}
            </List>
        </div>
        <div style={{overflowX: 'hidden'}}>
            <List className={classes.rootListVideo}>
                <ListSubheader component="div" className={classes.headerListVideo}>
                Video
                <IconButton
                    onClick={handleChangeSwipPlaylist}
                    style={{color: 'white', float: 'right'}}
                >
                    <ArrowBackIcon  />
                </IconButton>
                </ListSubheader>
                {listVideo.length > 0 && listVideo.map((v: any) => (
                  <>
                {v.title.includes('Private video') === false &&  (<ListItem key={v.videoId}  title={v.title} className="itemVideo" onClick={() => handleOpenVideo(v.videoId)} dense button>
                <ListItemAvatar>
                    <Avatar
                        className={classes.thumbVideo}
                        src={v.thumbnail}
                        variant="square"
                        
                    />
                </ListItemAvatar>
                <ListItemText
                    primary={paddingString(v.title, 60, '...')}
                    secondary={moment(v.publishedAt).format("MMMM Do YYYY")}
                    style={{marginLeft: '10px'}}
                />
                </ListItem>)}
                </>
                ))}

            </List>
        </div>
        </SwipeableViews>
    </div>
  );
};

export default PlaylistPanel;
