import * as types from './actionTypes';

const initialState = {
  name: ''
};

export default function reduce(state = initialState, action: any = {}): any {
  switch (action.type) {
    case types.OPEN_PANEL:
      return { ...state, name: action.name };

    default:
      return state;
  }
}
