import Immutable from 'seamless-immutable';
import * as _ from 'lodash';
import * as types from './actionTypes';
import { ITodo } from 'src/types/ITodo';

const emptyState: ITodo = {
  isWaiting: false,
  todos: [],
  success: false,
  errors: [],
  responseTime: null,
}

const initialState = Immutable(_.cloneDeep(emptyState));

export default function reduce(state = initialState, action: any = {}): any {
  switch (action.type) {
    case types.GET_TODO_SUCCESS:
      return { ...state, ...action.payload, isWaiting: false, errors: [], success: false };

    case types.ADD_TODO_SUCCESS:
      return { ...state, ...action.payload, isWaiting: false, errors: [], success: true };

    case types.ADD_TODO_FAIL:
      return { ...state, errors: action.payload, success: false };

    case types.SET_WAITING:
      return { ...state, isWaiting: action.payload };
    
    case types.CLEAR_TODO_ERRORS:
      return { ...state, errors: [] };

    default:
      return state;
  }
}
