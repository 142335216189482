import Immutable from 'seamless-immutable';
import * as _ from 'lodash';
import * as types from './actionTypes';
import { IPromotionStoreState } from '../../types/PromotionStoreState';

const emptyState: IPromotionStoreState = {
  isWaiting: false,
  topSocialMedias: undefined,
  favorites: undefined,
  bestOfChrome: undefined,
};

const initialState = Immutable(_.cloneDeep(emptyState));

export default function reduce(state = initialState, action: any = {}): any {
  switch (action.type) {
    case types.GET_PROMOTIONS_SUCCESS:
      return { ...state, ...action.payload };

    case types.SET_WAITING:
      return { ...state, isWaiting: action.payload };

    default:
      return state;
  }
}
