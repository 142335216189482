import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { AllState } from 'src/types/AllState';
import './background.styles.scss';

interface IStateProps {
  bgImage: string;
  bgColor: string;
  testBgImage: string;
  isBgImageOn: boolean;
}

type Props = IStateProps;

const Background: React.FC<Props> = ({
  bgImage,
  bgColor,
  testBgImage,
  isBgImageOn,
}) => {
  const backgroundStyle = useMemo(() => {
    let style: any = {};
    // Vu Nguyen - Remove default background temporary 
    // if (testBgImage) {
    //   style.backgroundImage = 'url(' + testBgImage + ')';
    // }
    if (isBgImageOn && bgImage.length > 0) {
      style.backgroundImage = 'url(' + bgImage + ')';
    } else {
      style.backgroundColor = bgColor;
    }

    return style;
  }, [bgImage, bgColor, testBgImage, isBgImageOn]);

  return (
    <div className='background'>
      <div className='background-image' style={backgroundStyle} />
      <div className='background-overlay' />
      <div className='background-footer' />
    </div>
  );
};

function mapStateToProps(state: AllState, props: any) {
  return {
    isBgImageOn: state.landscape.isBgImageOn,
    bgImage: state.landscape.bgImage,
    bgColor: state.landscape.bgColor,
    testBgImage: state.landscape.testBgImage,
  };
}

export default connect(mapStateToProps, null)(Background);
