import React, { useState } from 'react';
import {
    makeStyles,
    useTheme,
    Grid,
    TextField,
    MenuItem,
    IconButton,
    Button,    
    ImageList,
    ImageListItem,
    ImageListItemBar
} from '@material-ui/core';

import StarBorderIcon from '@material-ui/icons/StarBorder';
import SearchIcon from '@material-ui/icons/Search';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';
import SkipNextIcon from '@material-ui/icons/SkipNext';
import { useDispatch, useSelector } from 'react-redux';
import { searchVideos } from 'src/store/channels/actions';
import PlaylistVideo from './PlaylistVideo';
import channelService from 'src/services/channel';
import { toast } from 'react-toastify';

const useStyles = makeStyles((theme) => ({
    rootDivTab: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        padding: '10px 20px ',
        backgroundColor: 'rgba(0,0,0 , 0.7)',
    },
    formControl: {
        '& .MuiInput-formControl': {
            color: '#fff',
            '&:before': {
                borderColor: '#fff',
            },
            '&:after': {
                borderColor: '#fff',
            },
            '& .MuiSelect-icon': {
                color: 'rgba(255,255,255, 0.85)',
            }
        }
    },
    btnSearch: {
        color: '#fff'
    },

    cardMedia: {
        '& .MuiCardContent-root': {
            //width: '214px',
            height: '135px',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            padding: 0,
            // '& .actionBox': {
            //     display: 'none',
            // },

            // '&:hover': {
            //     '& .actionBox': {
            //         height: '100%',
            //         width: '100%',
            //         display: 'flex',
            //         justifyContent: 'center',
            //         alignItems: 'center',
            //         background: '#000',
            //         opacity: 0.8,
            //         flexDirection: 'column',
                    
            //         '& .actionItem': {
            //             padding: 0,
            //             fontSize: '15pt',
            //             color:'white',
            //             '& .MuiSvgIcon-root': {
            //                 marginRight: '2px',
            //             }
            //         }
            //     }
            // }
        }
    },
    searchResult: {
        maxHeight: '240px',
        overflowY: 'auto',
    },
    textResult:{
        paddingTop: '20px!important',
        textAlign: 'center',
        color: 'orangered',
        fontSize: '14pt',
    },
    pagination: {       
        
        '& .MuiSvgIcon-root': {
            color: '#fff',
            fontSize: '40px'
        }
    },
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        '& .MuiImageListItem-item':{
            border:'1px solid #EDEDED',
            borderRadius:5,
        }
      },
      imageList: {
        width: 'auto',
        height: 298,
        padding: 4,
        cursor:'pointer',
        // Promote the list into its own layer in Chrome. This cost memory, but helps keep FPS high.
        transform: 'translateZ(0)',
        
      },
      imageLItem:{
        '& .actionBox': {
            display: 'none',
        },

        '&:hover': {
            '& .actionBox': {
                left: 0,
                right: 0,
                top:96,
                height: 38,
                display: 'flex',
                position: 'absolute',
                background: 'rgba(0, 0, 0, 0.5)',
                alignItems: 'center',              
                opacity: 0.8,
                flexDirection: 'column',
                
                '& .actionItem': {
                    padding: 0,
                    fontSize: '15pt',
                    color:'white',
                    '& .MuiSvgIcon-root': {
                        marginRight: '2px',
                    }
                }
            }
        }
      },
      titleBar: {
        background:
          'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
          'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
          '&:hover': {
            '& .MuiIconButton-label': {
                color:'blue'
            }
          }
      },
      icon: {
        color: 'white',
      },
}));

interface IProps {
    isSticky: any,
    closeSticky: () => void
}

type Props = IProps;

const searchTypeOptions = [
    { value: 'youtube', label: 'On Youtube' },
    { value: 'channel', label: 'On Channel' },
]

const SearchPanel: React.FC<Props> = ({isSticky, closeSticky}) => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();

  const [pannel, setPannel] = useState('search');
  const [playVideoId, setPlayVideoId] = useState('search');
  
  const [search, setSearch] = useState({type: 'youtube', query: ''});

  const [searchText, setSearchText] = useState('');

  const userChannels = useSelector(({ channels }: any) => channels.user_channels);

  const resultVideos = useSelector(({ channels }: any) => channels.search_videos);
    // console.log(resultVideos);
  const [isError, setIsError] = useState<boolean>(false);
  const handleSearch = () => {
    if (search.type === 'SelectChannel') {
        setIsError(true); 
    }else{
        setIsError(false); 
        loadData(); 
    }
  }

  const handleChangePage = (pageToken) => {
    loadData(pageToken);
  }

  const loadData = (pageToken = null) => {
    const query = { q: search.query, pageToken };
    let onChannel = 'Youtube';
    if (search.type !== 'youtube') {
        query['channelId'] = search.type;
        onChannel = `${userChannels?.find(x => x.channel_id === search.type)?.info?.title || ''} channel`;
    }
    setSearchText(`Results search keyword "${search.query}" on ${onChannel} . List by thumbnails`
    );
    dispatch(searchVideos(query));
  }

  const handlePlay = (videoId: string) => {
    if (videoId) {
        setPlayVideoId(videoId);
        setPannel('playlist');
    }
  }

  const handleBack = () => {
    setPannel('search');
  }

  const handleAddPlaylist = async (videoId) => {
      if (videoId) {
        const { data } = await channelService.addToPlaylist(videoId);
        toast[data.status](data.message);
      }
  }

  return (
    <>
    {pannel === 'search' &&
        <div className={classes.rootDivTab}>
            <Grid
                container
                spacing={3}
                // justify="center"
            >
                <Grid item sm={3}>
                    <TextField
                        name="type"
                        id="searchType"
                        select
                        label=""
                        fullWidth
                        className={classes.formControl}
                        value={search.type}
                        onChange={(e) => setSearch({...search, type: e.target.value})}
                        error={isError}
                        helperText={isError?'Please select channel':''}
                    >
                        <MenuItem value="youtube">
                            On Youtube
                        </MenuItem>
                        {userChannels && userChannels.map((uc) => (
                            <MenuItem key={uc.channelId} value={uc.channel_id}>
                            {uc.title}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item sm={8}>
                    <TextField
                        name="query"
                        fullWidth
                        className={classes.formControl}
                        value={search.query}
                        onChange={(e) => setSearch({...search, query: e.target.value})}
                        onKeyPress={(ev) => {
                            if (ev.key === 'Enter') {
                              ev.preventDefault();
                              handleSearch();
                            }
                          }}
                    />
                </Grid>
                <Grid item sm={1}>
                    <IconButton className={classes.btnSearch} onClick={handleSearch}>
                        <SearchIcon />
                    </IconButton>
                </Grid>
            </Grid>
            {(resultVideos?.videosInfo?.nextPageToken || resultVideos?.videosInfo?.prevPageToken) && 
            <Grid
                container
                spacing={1}               
                className={classes.pagination}
            >               
               
                <Grid item sm={1}>
                    <IconButton disabled={!resultVideos?.videosInfo?.prevPageToken} onClick={() => handleChangePage(resultVideos?.videosInfo?.prevPageToken)}>
                        <SkipPreviousIcon />
                    </IconButton>
                </Grid>
                {resultVideos && 
                <Grid item sm={10} className={classes.textResult}>
                    {searchText}
                </Grid>}               
                <Grid item sm={1}>
                    <IconButton disabled={!resultVideos?.videosInfo?.nextPageToken} onClick={() => handleChangePage(resultVideos?.videosInfo?.nextPageToken)}>
                        <SkipNextIcon />
                    </IconButton>
                </Grid>
            </Grid>
            }

            <div className={classes.root}>
            <ImageList rowHeight={135} cols={4} className={classes.imageList}>
                {resultVideos?.videosInfo?.items?.map((item) => (
                <ImageListItem key={item.id.videoId} className={classes.imageLItem}>
                    <img src={item.snippet.thumbnails.medium.url || item.snippet.thumbnails.default.url} alt={item?.snippet?.title || ''} onClick={() => handlePlay(item.id.videoId || '')} />
                    <ImageListItemBar 
                    title={item.title}
                    position="top"
                    actionIcon={
                        <IconButton title="Add to favorite videos" aria-label={`Add playlist`} className={classes.icon} onClick={() => handleAddPlaylist(item.id.videoId || '')}>
                        <StarBorderIcon />
                        </IconButton>
                    }
                    actionPosition="right"
                    className={classes.titleBar}
                    />
                    <div className="actionBox">
                        <Button color="secondary" className="actionItem" onClick={() => handlePlay(item.id.videoId || '')}><PlayArrowIcon /> Play</Button>
                    </div>
                </ImageListItem>
                ))}
            </ImageList>
            </div>

                       
        </div>
    }
    {pannel === 'playlist' &&
        <PlaylistVideo
            listVideo={resultVideos?.videosInfo?.items || []}
            playVideoId={playVideoId}
            isSticky={isSticky}
            typePlaylist="search"
            closeSticky={closeSticky}
            handleBack={handleBack}
        />
    }
    </>
  );
};

export default SearchPanel;
