export class User {
  public id: string;
  public name: string;
  public email: string;
  public type: string;
  public picture?: string;
  public password?: string;
  public hasRefresh: boolean;
  public partnerId: string;
  public repeatPassword?: string;

  public constructor(props: any) {
    Object.assign(this, props);
  }
}
