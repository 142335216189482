import React from 'react';
import { SwatchesPicker, ColorResult } from 'react-color';
import { IQuoteStoreState } from 'src/types/QuoteStoreState';

interface Props {
  quoteColor: string;
  updateQuoteOptions: (options: Partial<IQuoteStoreState>) => void;
}

const ColorPicker: React.FC<Props> = ({ quoteColor, updateQuoteOptions }) => {
  return (
    <SwatchesPicker
      color={quoteColor}
      onChange={(color: ColorResult) =>
        updateQuoteOptions({ color: color.hex })
      }
    />
  );
};

export default ColorPicker;
