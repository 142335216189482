import PromotionService from '../../services/promotion';
import { AllState } from 'src/types/AllState';
import * as types from './actionTypes';
import { Promotion } from 'src/models';
import promotionStorage from 'src/services/promotionStore';

export function getPromotions(): (
  dispatch: any,
  getState: any
) => Promise<void> {
  return async (dispatch: any, getState: () => AllState): Promise<void> => {
    try {
      dispatch({
        type: types.SET_WAITING,
        payload: true,
      });

      // const { data } = await PromotionService.getPromotions();

      const { topSocialMedias, favoriteSites: favorites, bestOfChrome } = await promotionStorage.getPromotions();

      await dispatch({
        type: types.GET_PROMOTIONS_SUCCESS,
        payload: {
          topSocialMedias,
          favorites,
          bestOfChrome,
        },
      });
    } catch (error) {
      console.error(error);
    } finally {
      dispatch({
        type: types.SET_WAITING,
        payload: false,
      });
    }
  };
}

export function createPromotion(
  data: Partial<Promotion>
): (dispatch: any, getState: any) => Promise<string | null> {
  return async (dispatch: any, getState: any): Promise<string | null> => {
    try {
      const { user: { isAuthenticated } }: AllState = getState();

      const { favoriteSites: favorites } = await promotionStorage.getPromotions();
      if (favorites) {
        const duplicate = favorites.find((x: any) => x.url === data.url);
        if (duplicate) {
          const errorMessage = 'Duplicate url.';
          return errorMessage;
        }
      }
      if (isAuthenticated) {
        const result = await PromotionService.createPromotion(data);
        if (result.data?.data) {
          await promotionStorage.createFavorite(result.data.data);
        }

      } else {
        data['typeAdd'] = 'new';
        data['id'] = Date.now().toString();
        await promotionStorage.createFavorite(data);
      }
      
      return null;
    } catch (error : any) {
      console.log(error);

      const { response } = error;
      const data = response?.data;

      const errorMessage =
        data?.message ??
        'A server error has occured while attempting to create promotion.';

      return data?.errors ?? errorMessage;
    }
  };
}

export function updatePromotion(
  data: Partial<Promotion>
): (dispatch: any, getState: any) => Promise<string | null> {
  return async (dispatch: any, getState: any): Promise<string | null> => {
    try {

      const { favoriteSites: favorites } = await promotionStorage.getPromotions();
      if (favorites) {
        const duplicate = favorites.find((x: any) => x.url === data.url && x.id !== data.id);
        if (duplicate) {
          const errorMessage = 'Duplicate url.';
          return errorMessage;
        }
      }

      await promotionStorage.updateFavorite(data);

      const { user: { isAuthenticated } }: AllState = getState();

      if (isAuthenticated) {
        await PromotionService.updatePromotion(data);
      }
      

      return null;
    } catch (error : any) {
      console.log(error);

      const { response } = error;
      const data = response?.data;

      const errorMessage =
        data?.message ??
        'A server error has occured while attempting to update promotion.';

      return data?.errors ?? errorMessage;
    }
  };
}

export function deletePromotion(
  id: string
): (dispatch: any, getState: any) => Promise<boolean> {
  return async (dispatch: any, getState: any): Promise<boolean> => {
    try {
      await promotionStorage.deleteFavorite(id);

      const { user: { isAuthenticated } }: AllState = getState();

      if (isAuthenticated) {
        await PromotionService.deletePromotion(id);
      }

      return true;
    } catch (err) {
      console.log(err);
      return false;
    }
  };
}

export function reorderFavorites(
  changedOrder: { rowIndex: number; id: string | null }[]
): (dispatch: any, getState: any) => Promise<void> {
  return async (dispatch: any, getState: any): Promise<void> => {
    try {
      const { user: { isAuthenticated } }: AllState = getState();

      if (isAuthenticated) {
        await PromotionService.reorderFavorites(changedOrder);
        dispatch(getPromotions());
      }
    } catch (err) {
      console.log(err);
    }
  };
}
