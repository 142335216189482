import React, { useCallback, useMemo } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Slide from '@material-ui/core/Slide';
import CancelIcon from '@material-ui/icons/Cancel';
import { IconButton, Typography } from '@material-ui/core';
import classNames from 'classnames';

const useStyles = makeStyles((theme: Theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop:'18vh',
  },
  modalLeft: {
    justifyContent: 'flex-start',
  },
  modalRight: {
    justifyContent: 'flex-end',
  },
  paper: {
    outline: 'none',
    borderRadius: '10px',
    backgroundColor: '#fffffff0',    
    boxShadow: theme.shadows[5],
    padding: theme.spacing(7,0),
    width: '330px',
    overflowY: 'unset',
    maxHeight: '100vh',
    position: 'relative',
  },
  wide: {
    width: '-webkit-fill-available',
    maxWidth: '1150px',
    minWidth: '600px',
  },
  white: {
    background: 'white',
  },
  transparent: {
    background: '#8c8c8ce3',
  },
  paperToSide: {
    height: '100vh',
    overflowY: 'auto',
    borderRadius: 0,
    '&::-webkit-scrollbar-track': {
      border: '1px solid #ddd',
      padding: '2px 0',
      backgroundColor:'rgba(245, 0, 87, .5)',
    },
    '&::-webkit-scrollbar': {
      width: '0px',
      color:'rgba(245, 0, 87, .5)',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '0px',
      boxShadow: 'inset 0 0 6px rgba(0,0,0,.3)',
      backgroundColor: '#f50057',
      border: '1px solid #ddd',
    },
  },
  header: {
    position: 'absolute',
    left: '5px',
    top: '5px',
  },
  closeButton: {
    position: 'absolute',
    right: '5px',
    top: '5px',
  },
}));

interface Props {
  open: boolean;
  handleClose: () => void;
  children: React.ReactNode;
  header?: React.ReactNode;
  useBackdrop?: boolean;
  attachedToLeft?: boolean;
  attachedToRight?: boolean;
  transparent?: boolean;
  useWidePaper?: boolean;
  paperClassName?: string;
  whitePaper?: boolean;
}

const TransitionsModal: React.FC<Props> = ({
  open,
  handleClose,
  children,
  attachedToLeft,
  attachedToRight,
  useBackdrop = false,
  header,
  transparent,
  useWidePaper = false,
  whitePaper = false,
  paperClassName = '',
}) => {
  const classes = useStyles();

  const content = useMemo(() => {
    return (
      <div
        className={classNames(
          classes.paper,
          { [classes.wide]: useWidePaper },
          { [classes.transparent]: transparent },
          { [classes.white]: whitePaper },
          {
            [classes.paperToSide]: attachedToLeft || attachedToRight,
          },
          paperClassName
        )}
      >
        <div className={classes.header}>
          {typeof header === 'string' ? (
            <Typography variant='h6' className='Mui-modalHeader'>
              {header}
            </Typography>
          ) : (
            header
          )}
        </div>
        <IconButton onClick={handleClose} className={classes.closeButton}>
          <CancelIcon />
        </IconButton>
        {children}
      </div>
    );
  }, [children, header, classes, handleClose, transparent]);

  const getContent = useCallback(() => {
    return attachedToLeft || attachedToRight ? (
      <Slide
        direction={attachedToLeft ? 'right' : 'left'}
        in={open}
        mountOnEnter
        unmountOnExit
      >
        {content}
      </Slide>
    ) : (
      <Fade in={open}>{content}</Fade>
    );
  }, [attachedToLeft, attachedToRight, content, open]);

  return (
    <Modal
      className={classNames(classes.modal, {
        [classes.modalLeft]: attachedToLeft,
        [classes.modalRight]: attachedToRight,
      })}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
        invisible: !useBackdrop,
      }}
    >
      {getContent()}
    </Modal>
  );
};

export default TransitionsModal;
