import { AxiosResponse } from 'axios';
import api from './api';

class SearchService {
  public async getSearchHistory(): Promise<AxiosResponse> {
    return await api({
      method: 'get',
      url: `/api/searchHistory`,
    });
  }

  public async search(val: string): Promise<AxiosResponse> {
    return await api({
      method: 'get',
      url: `/api/search?p=${val}`,
    });
  }

  public async getUserSearchInfo(): Promise<AxiosResponse> {
    return await api({
      method: 'get',
      url: '/api/searchHistory',
    });
  }
}

export default new SearchService();
