import axios, { AxiosResponse } from 'axios';
import * as luxon from 'luxon';
import { Image } from 'src/types/Image';
import DB from "src/helpers/db";
import api from './api';
import galleryData from 'src/assets/gallery.json';
const galleryUrl = 'https://gallery.mystartcdn.com/mystart/gallery.json';

class LandscapeService {
  public async getRandomDailyImage(): Promise<string> {
    // const {
    //   data: { items },
    // } = await axios.get(galleryUrl);

    const items: Image[] = (galleryData as any[]).map((x) => ({
      ...x,
      name: x.name,
      description: x.description,
      image: x.image,
      media: x.small,
    }));

    const currentDate = luxon.DateTime.local();

    const item =
      items[currentDate.ordinal - 1] ??
      items[currentDate.day - 1] ??
      items[currentDate.weekday - 1] ??
      items[0];

    return item?.image ?? '';
  }

  public async getImagesGallery(): Promise<Image[]> {

    const items: Image[] = (galleryData as any[]).map((x) => ({
      ...x,
      name: x.name,
      description: x.description,
      image: x.image,
      media: x.small,
    }));
    items.sort(() => Math.random() - 0.5);    
    return items ?? [];   
  }

  public async getImagesGallery_bk(): Promise<Image[]> {
    const gallery = await axios.get(galleryUrl);

    return (
      gallery?.data?.items.map(({ name, image, description , media }: Image) => ({
        image,
        name,
        description,
        media,
      })) ?? []
    );
  }

  public async getMyImagesGallery(): Promise<Image[]> {
    const keys = await DB.setStore('my_background', 'web_start').getKeys();
    if (keys) {
      let data: Image[] = [];
      for(let i = 0; i < keys.length; i++) {
        data.push({
          image: await DB.setStore('my_background', 'web_start').getItem(keys[i]),
          name: keys[i],
          description: 'My Background',
          media : '',
        });
      };
      return data;
    }
    return [];
  }

  public async uploadBase64Background(data: any): Promise<AxiosResponse> {
    return await api({
      method: 'post',
      url: '/api/upload/base64-background',
      data
    });
  }
}

export default new LandscapeService();
