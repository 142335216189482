import styled from 'styled-components';
import loadingGif from 'src/assets/loading.gif';

const StyledImage = styled.img`
  display: block;
  object-fit: cover;
  width: 100%;
  height: 120px;

  @keyframes loaded {
    0% {
      opacity: 0.1;
    }
    100% {
      opacity: 1;
    }
  }

  &.selected {
    box-shadow: 0px 0px 8px #1a089e;
  }

  &.loaded:not(.has-error) {
    animation: loaded 300ms ease-in-out;
  }

  &.has-error {
    content: url(${loadingGif});
  }
`;

export default StyledImage;
