import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Tooltip, IconButton, Tab, Tabs, Typography } from '@material-ui/core';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import { User } from 'src/models';
import { AllState } from 'src/types/AllState';
import * as userActions from 'src/store/user/actions';
import Modal from 'src/components/containers/Modal/Modal';
import Profile from './Profile/Profile';
import LoginForm from './LoginForm/LoginForm';
import RegisterForm from './RegisterForm/RegisterForm';
import { FacebookButton, GoogleButton } from './SocialButtons';
import classNames from 'classnames';
import './auth.styles.scss';
import ForgotForm from "./ForgotForm/ForgotForm";
import webstarsLogo from 'src/assets/ws_logo4.png';
import { useDispatch, useSelector } from 'react-redux';
import { openAuthPopup } from 'src/store/user/actions';

interface IDispatchProps {
  register: (data: any) => Promise<any>;
  login: (data: any) => Promise<any>;
  logout: () => Promise<void>;
}

interface IStateProps {
  isAuthenticated: boolean;
  user: User | null;
}

type Props = IDispatchProps & IStateProps;

const UserAuthWidget: React.FC<Props> = ({
  register,
  login,
  logout,
  user,
  isAuthenticated,
}) => {

  const dispatch = useDispatch();

  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [activeForm, setActiveForm] = useState<string>('login');

  const authPopup = useSelector(({ user }: AllState) => user.authPopup);

  useEffect(() => {
    if (authPopup && authPopup.isOpen) {
      setActiveForm(authPopup.type);
      setModalOpen(true);
    }
  }, [authPopup]);
 
  const handleClose = useCallback(() => {
    setModalOpen(false);
  }, [setModalOpen]);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(openAuthPopup({type: '', isOpen: false}));
      handleClose();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (!isModalOpen) {
      setActiveForm('login');
    }
  }, [isModalOpen]);

  return (
    <div className={classNames('auth-root', { ['logged']: isAuthenticated })}>
      {isAuthenticated && user ? (
        <Profile user={user} logout={() => logout()} />
      ) : (
        <>
        <Tooltip title="Login" style={{position: "absolute", top: "0", right: "0", zIndex: 1}}>
          <IconButton onClick={() => setModalOpen(true)} className='Mui-calendar'>
            <AccountBoxIcon className='Mui-whiteIcon' />
          </IconButton>
          </Tooltip>
         
          <Modal useBackdrop open={isModalOpen} handleClose={handleClose} paperClassName='modalUserAuth'
          aria-labelledby="modal-title">
          <span id="modal-title"><img src={webstarsLogo} className='loginLogo' /></span>
                      <Tabs
                          value={activeForm}
                          indicatorColor='primary'
                          textColor='primary'
                          centered
                          aria-label="Webstars Authenticate"
                          //variant="scrollable"                          
                          onChange={(e, val) => setActiveForm(val)}
                      >
                        <Tab value='login' label='Login' className='Mui-full' />
                        <Tab value='register' label='Signup' className='Mui-full' />
                        <Tab value='forgot' label='Forgot Password' className='Mui-full' />
                      </Tabs>
                      <div className='socials-wrap'>
                        {/* <Typography color='primary' variant='body2'>
                          {activeForm === 'forgot' && 'Forgot Password'}
                          {activeForm === 'login' && 'Log in with'}
                          {activeForm === 'register' && 'Sign up with'}
                        </Typography> */}
                        <div className='buttons-wrap'>
                          <GoogleButton />
                          <FacebookButton />
                        </div>
                      </div>                      
                      {activeForm === 'login' &&
                      <LoginForm login={login} shouldReset={!isModalOpen} setActiveForm={setActiveForm}/>
                      }
                      {activeForm === 'register' &&
                      <RegisterForm register={register} shouldReset={!isModalOpen} />
                      }
                      {activeForm === 'forgot' &&
                      <ForgotForm shouldReset={!isModalOpen} />
                      }
                      
                  
          </Modal>
          
        </>
      )}
    </div>
  );
};

function mapStateToProps(state: AllState, props: any) {
  return {
    isAuthenticated: state.user.isAuthenticated,
    user: state.user.loggedInUser,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return (bindActionCreators(
    {
      register: userActions.register,
      login: userActions.login,
      logout: userActions.logout,
    },
    dispatch
  ) as unknown) as IDispatchProps;
}

export default connect(mapStateToProps, mapDispatchToProps)(UserAuthWidget);
