import { AxiosResponse } from 'axios';
import { City } from 'src/types/WeatherStoreState';
import api from './api';

class WeatherService {
  public async getWeather(city: City | null): Promise<AxiosResponse> {
    return await api({
      method: 'get',
      url: '/api/weather' + (city ? `?lat=${city.lat}&lng=${city.lng}` : ''),
    });
  }

  public async getCities(p?: string): Promise<AxiosResponse> {
    return await api({
      method: 'get',
      url: '/api/cities' + (p ? `?p=${p}` : ''),
    });
  }
}

export default new WeatherService();
