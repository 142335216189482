import Immutable from 'seamless-immutable';
import * as _ from 'lodash';
import * as types from './actionTypes';
import { IChannel } from 'src/types/IChannel';
import { string } from 'yup/lib/locale';

const emptyState: IChannel = {
  isWaiting: false,
  showChannel: null,
  webstart_channel: null,
  partner_channel: null,
  user_channels: null,
  search_videos: null,
  type: '',
}

const initialState = Immutable(_.cloneDeep(emptyState));

export default function reduce(state = initialState, action: any = {}): any {
  switch (action.type) {
    case types.GET_CHANNEL_SUCCESS:
      return { ...state, ...action.payload, isWaiting: false };

    case types.SET_WAITING:
      return { ...state, isWaiting: action.payload };

    case types.SET_SHOW_CHANNEL:
      return { ...state, showChannel: action.payload };

    case types.SEARCH_VIDEO_SUCCESS:
      return { ...state, search_videos: action.payload, isWaiting: false };

    default:
      return state;
  }
}
