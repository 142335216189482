import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import * as luxon from 'luxon';
import * as _ from 'lodash';
import {
  Box,
  Grid,
  makeStyles,
  TextField,
  Typography,
  Tooltip,
} from '@material-ui/core';
import Modal from 'src/components/containers/Modal/Modal';
import withMoving from 'src/components/hocs/withMoving/withMoving';
import { Autocomplete } from '@material-ui/lab';
import { AllState } from 'src/types/AllState';
import { City } from 'src/types/WeatherStoreState';
import * as clockActions from 'src/store/clock/actions';
import { defaultLocation } from 'src/store/clock/reducer';

const useStyles = makeStyles(() => ({
  clock: {
    'user-select': 'none',
    'font-weight': '300',
    cursor: 'pointer',
    color: 'white',
    minWidth: 'max-content',
    textShadow: '0 2px 4px rgba(77,77,77,0.5)',
  },
}));

interface IDispatchProps {
  getCities: (p?: string) => Promise<void>;
  updateLocation: (location: City) => Promise<void>;
}

interface IStateProps {
  location: City;
  cities: City[];
  isOn: boolean;
}

type Props = IDispatchProps & IStateProps;

const timeFormat = 'hh:mm a';
const dateFormat = 'cccc, LLL dd';

const ClockWidget: React.FC<Props> = ({
  getCities,
  updateLocation,
  location,
  cities,
  isOn,
}) => {
  const classes = useStyles();

  const [dateTime, setDateTime] = useState<luxon.DateTime>(
    luxon.DateTime.local().setZone(location.timeZone)
  );
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [cityList, setCityList] = useState<City[]>([]);

  const clockInterval = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    getCities();
  }, []);

  useEffect(() => {
    setCityList([defaultLocation, ...cities]);
  }, [cities]);

  const tick = useCallback(() => {
    setDateTime(luxon.DateTime.local().setZone(location.timeZone));
  }, [setDateTime, location]);

  const setupInterval = useCallback(() => {
    clockInterval.current = setInterval(tick, 1000);
  }, [tick, clockInterval.current]);

  useEffect(() => {
    setupInterval();

    return () => {
      if (clockInterval.current) {
        clearInterval(clockInterval.current);
      }
    };
  }, []);

  useEffect(() => {
    if (clockInterval.current) {
      clearInterval(clockInterval.current);
      clockInterval.current = null;
    }

    tick();
    setupInterval();
  }, [location]);

  const handleLocationChange = useCallback(
    (e, val: City | null) => {
      if (val) {
        updateLocation(val);
        setModalOpen(false);
      }
    },
    [updateLocation, setModalOpen]
  );

  const debouncedGetCities = useMemo(
    () =>
      _.debounce((val) => {
        getCities(val);
      }, 200),
    [getCities]
  );

  return (
    <>
      {isOn && (
        <Tooltip title='Double click to change timezone' placement='top'>
          <Grid
            container
            justifyContent='center'
            direction='column'
            alignItems='center'
            className='can-hide'
            onDoubleClick={(e) => setModalOpen(true)}
          >
            <Grid item>
              <Typography variant='h3' className={classes.clock}>
                {dateTime.toFormat(timeFormat)}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant='button' className={classes.clock}>
                {dateTime.toFormat(dateFormat)}
              </Typography>
            </Grid>
          </Grid>
        </Tooltip>
      )}
      <Modal
        useBackdrop
        header='Clock'
        open={isModalOpen}
        handleClose={() => setModalOpen(false)}
      >
        <Box px={3} pb={2}>
          <Autocomplete
            fullWidth
            value={location}
            options={cityList}
            onInputChange={(e, val) => {
              if (val.trim().length > 0) {
                debouncedGetCities(val);
              }
            }}
            onChange={handleLocationChange}
            getOptionLabel={(option) => option.name}
            getOptionSelected={(op, val) => op?.name === val?.name}
            renderInput={(params) => (
              <TextField {...params} label='Location' variant='outlined' />
            )}
          />
        </Box>
      </Modal>
    </>
  );
};

function mapStateToProps({ clock }: AllState, props: any) {
  return {
    isOn: clock.isOn,
    cities: clock.cities,
    location: clock.location,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return (bindActionCreators(
    {
      getCities: clockActions.getCities,
      updateLocation: clockActions.updateLocation,
    },
    dispatch
  ) as unknown) as IDispatchProps;
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withMoving(ClockWidget, 'clock'));
