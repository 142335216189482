import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import * as landscapeActions from 'src/store/landscape/actions';
import { AllState } from 'src/types/AllState';
import LandscapeIcon from '@material-ui/icons/Panorama';
import SettingsIcon from '@material-ui/icons/Settings';
import InfoIcon from '@material-ui/icons/Info';
import PaletteIcon from '@material-ui/icons/Palette';
import InsertPhotoIcon from '@material-ui/icons/InsertPhoto';
import Modal from 'src/components/containers/Modal/Modal';
import { LazyImage } from 'src/components/utils/LazyImage';
import { Image } from 'src/types/Image';
import bgColorPalette from 'src/constants/backgroundColorPalette';
import LandscapeSettings from './Settings';
import {
  Grid,
  IconButton,
  makeStyles,
  Tooltip,
  Typography,
  Tabs,
  Tab,
} from '@material-ui/core';
import { TabPanel, TabContext } from '@material-ui/lab';
import classNames from 'classnames';
import wallpaperIcon from 'src/assets/white/wallpaper-icon.png';
import SlidingPane from 'react-sliding-pane';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import ColorLensIcon from '@material-ui/icons/ColorLens';
import { callbackify } from 'util';
import { openPanel } from 'src/store/panel/actions';
import { IPanel } from 'src/types/IPanel';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import { useDropzone } from 'react-dropzone';
import { Height } from '@material-ui/icons';
import DB from 'src/helpers/db';
import shortid from 'shortid';
import { toast } from 'react-toastify';
import CancelIcon from '@material-ui/icons/Cancel';


const PANEL_LANDSCAPE = 'landscape';
const PANEL_SETTINGS = 'settings';
const PANEL_INFO = 'information';

const useStyles = makeStyles(() => ({
  wrap: {
    display: 'flex',
    flexWrap: 'nowrap',
    height: '100%',
  },
  menuWrap: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: 'fit-content',
  },
  menuItem: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    paddingRight: '15px',
    margin: '0  20px 10px -10px',
    borderRadius: '0 35px 35px 0',
    '&:hover': {
      backgroundColor: 'rgb(0 0 0 / 12%)',
    },
  },
  selected: {
    backgroundColor: 'rgb(13 55 206 / 25%) !important',
  },
  landscapeIcon: {
    // position: 'absolute',
    // bottom: '8px',
    // left: '20px',
  },
  settingsIcon: {
    position: 'absolute',
    right: '0',
  },
  informationIcon: {
    position: 'absolute',
    left: '0',
  },
  imageWrap: {
    overflow: 'hidden',
    cursor: 'pointer',
    position: 'relative',
    '&:hover': {
      '& > div': {
        opacity: '1 !important',
      },
    },
  },
  imagesContainer: {
    height: 'calc(60vh - 75px)',
    overflowY: 'auto',
    '&::-webkit-scrollbar-track': {
      border: '1px solid #ddd',
      padding: '2px 0',
      backgroundColor: 'rgba(245, 0, 87, .5)',
    },
    '&::-webkit-scrollbar': {
      width: '0px',
      color: 'rgba(245, 0, 87, .5)',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '0px',
      boxShadow: 'inset 0 0 6px rgba(0,0,0,.3)',
      backgroundColor: '#f50057',
      border: '1px solid #ddd',
    },
  },
  colorItem: {
    width: '70px',
    height: '70px',
    borderRadius: '50%',
  },
  imageOverlay: {
    opacity: 0,
    transition: 'opacity 0.8s ease-out',
    'z-index': 1000,
    'box-shadow': 'inset 0px 0px 50px #000000',
    position: 'absolute',
    top: '4px',
    left: '4px',
    bottom: '4px',
    right: '4px',
  },
  removeImage: {
    position: 'absolute',
    color: '#ca1111',
    top: '10px',
    right: '10px',
    zIndex: 1001,
  },
  dropZone: {
    height: '100px',
    border: '2px dashed rgba(255,255,255,0.47)',
    textAlign: 'center',
    color: 'white',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '& h3': {
      fontSize: '20px',
      fontWeight: '500',
      margin: '0',
      color: '#f1f1f1',
    },
    '& p.dropzone_text': {
      margin: '5px 0 0',
      '& span': {
        color: '#54f104',
        display: 'block',
        textAlign: 'center',
        '& .alert': {
          color: '#f57100',
        },
      },
    },
  },
  modalPaper: {
    width: '100%',
    maxWidth: '800px',
    height: '500px',
  },
  colorsWrap: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  selectedColor: {
    boxShadow: '0px 0px 8px #1a089e',
  },
  tabs: {
    '& .MuiTab-textColorSecondary': {
      color: 'rgba(255,255,255 , 0.7)',
    },
    '& .Mui-selected': {
      color: '#fff',
      fontWeight: 'bold',
    },
    '& .MuiTab-labelIcon': {
      minHeight: '50px',
      '& .MuiTab-wrapper': {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        '& *:first-child': {
          marginRight: '6px',
        },
      },
    },
  },
}));

interface IDispatchProps {
  getImagesGallery: () => Promise<void>;
  getMyImagesGallery: () => Promise<void>;
  setBackgroundImage: (image: string, type: string) => void;
  setTestBackgroundImage: (image: string) => void;
  clearTestBackgroundImage: () => void;
  setBackgroundColor: (color: string) => void;
  openPanel: (name: string) => void;
}

interface IStateProps {
  gallery: Image[];
  my_gallery: Image[];
  isBgImageOn: boolean;
  bgColor: string;
  bgImage: string;
  panel: IPanel;
}

type Props = IDispatchProps & IStateProps;

const Landscape: React.FC<Props> = ({
  isBgImageOn,
  gallery,
  my_gallery,
  bgColor,
  bgImage,
  panel,
  getImagesGallery,
  getMyImagesGallery,
  setBackgroundColor,
  setBackgroundImage,
  setTestBackgroundImage,
  clearTestBackgroundImage,
  openPanel,
}) => {
  const classes = useStyles();
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [optionsType, setOptionsType] = useState<'landscape' | 'settings'>();
  const [openedTab, setOpenedTab] = useState<number>(0);
  const [openedTabTitle, setOpenedTabTitle] = useState<string>();
  const [testImageTimeout, setTestImageTimeout] = useState<any>(null);




  const onDrop = useCallback(async (acceptedFiles) => {
    const countMyImage = (
      await DB.setStore('my_background', 'web_start').getKeys()
    ).length;
    if (countMyImage + acceptedFiles.length > 5) {
      toast.warning('Maximum number of images allowed is 5');
      return false;
    }
    acceptedFiles.forEach((file: any) => {
      const reader = new FileReader();
      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => console.log('file reading has failed');
      reader.onload = () => {
        const dataUrl = reader.result;
        DB.setStore('my_background', 'web_start').setItem(
          shortid.generate(),
          dataUrl
        );
      };
      reader.onloadend = () => {
        getMyImagesGallery();
      };
      reader.readAsDataURL(file);
    });
  }, []);

  const onDropRejected = useCallback(async (fileRejections) => {
    let arrFileName = [];
    fileRejections.forEach((fileReject: any) => {
      toast.error('File ' + (fileReject.file?.name || '') + ': ' + (fileReject.errors[0]?.message || ''));
    });
  }, []);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    onDropRejected,
    accept: 'image/jpeg, image/png',
    maxSize: 10485760 // 10MB
  });

  useEffect(() => {
    getImagesGallery();
    getMyImagesGallery();
  }, []);

  // const handleClose = useCallback(() => {
  //   setModalOpen(false);
  //   setOpenedTab(0);
  // }, [setModalOpen, setOpenedTab]);

  const handleOpen = (panelName: string) => {
    if (panel.name === panelName) {
      openPanel('');
    } else {
      gallery.sort(() => Math.random() - 0.5);
      openPanel(panelName);
    }
  };

  const [valueTab, setValueTab]: [string, Function] = React.useState('1');

  const handleChangeTab = (event: any, newValue: string) => {
    setValueTab(newValue);
    //.sort(() => Math.random() - 0.5)   
  };

  const handleRemoveMyBackground = async (name: string, e: any) => {
    e.stopPropagation();
    await DB.setStore('my_background', 'web_start').removeItem(name);
    getMyImagesGallery();
  };

  useEffect(() => {
    if (openedTab === 0) {
      setOpenedTabTitle('Background images');
    } else if (openedTab === 1) {
      setOpenedTabTitle('Background colors');
    } else if (openedTab === 2) {
      setOpenedTabTitle('My Background');
    } else {
      setOpenedTabTitle('Settings');
    }
  }, [openedTab]);

  const handleImageMouseEnter = useCallback(
    (image: string) => {
      clearTimeout(testImageTimeout);
      setTestImageTimeout(
        setTimeout(() => setTestBackgroundImage(image), 1000)
      );
    },
    [testImageTimeout, setTestImageTimeout, setTestBackgroundImage]
  );

  const handleImageMouseLeave = useCallback(() => {
    clearTimeout(testImageTimeout);
    clearTestBackgroundImage();
  }, [testImageTimeout, clearTestBackgroundImage]);

  const menu = useMemo(() => {
    return (
      <div className={classes.menuWrap}>
        {optionsType !== 'landscape' ? null : (
          <>
            <div
              className={classNames(classes.menuItem, {
                [classes.selected]: openedTab === 0,
              })}
              onClick={() => setOpenedTab(0)}
            >
              <IconButton>
                <InsertPhotoIcon />
              </IconButton>
              <Typography>Background images</Typography>
            </div>
            <div
              className={classNames(classes.menuItem, {
                [classes.selected]: openedTab === 1,
              })}
              onClick={() => setOpenedTab(1)}
            >
              <IconButton>
                <PaletteIcon />
              </IconButton>
              <Typography>Background colors</Typography>
            </div>
            <div
              className={classNames(classes.menuItem, {
                [classes.selected]: openedTab === 2,
              })}
              onClick={() => setOpenedTab(2)}
            >
              <IconButton>
                <PaletteIcon />
              </IconButton>
              <Typography>My Background</Typography>
            </div>
          </>
        )}
        {optionsType !== 'settings' ? null : (
          <div
            className={classNames(classes.menuItem, {
              [classes.selected]: openedTab === 2,
            })}
            onClick={() => setOpenedTab(2)}
          >
            <IconButton>
              <SettingsIcon />
            </IconButton>
            <Typography>Settings</Typography>
          </div>
        )}
      </div>
    );
  }, [openedTab, setOpenedTab, classes]);

  return (
    <>

      <div className={classes.informationIcon}>
        <IconButton onClick={() => {
          handleOpen(PANEL_INFO);
        }}>
          <InfoIcon className='Mui-whiteIcon' />
        </IconButton>

      </div>

      <div className='can-hide'>
        <Tooltip title='Open Landscape Settings'>
          <div className={classes.landscapeIcon}>
            <IconButton
              onClick={() => {
                handleOpen(PANEL_LANDSCAPE);
              }}
            >
              <img src={wallpaperIcon} style={{ width: '30px' }} />
            </IconButton>
          </div>
        </Tooltip>
      </div>
      <Tooltip title='Open Settings'>
        <div className={classes.settingsIcon}>
          <IconButton
            onClick={() => {
              handleOpen(PANEL_SETTINGS);
            }}
          >
            <SettingsIcon className='Mui-whiteIcon' />
          </IconButton>
        </div>
      </Tooltip>
      <SlidingPane
        className='custom-sliding-panel gallery-panel'
        overlayClassName='custom-overlay-sliding-panel'
        isOpen={panel.name === PANEL_LANDSCAPE}
        from='bottom'
        width='800px'
        hideHeader
        shouldCloseOnEsc
        closeIcon={<div>&times;</div>}
        onRequestClose={() => handleOpen('')}
      >
        <TabContext value={valueTab}>
          <Tabs
            value={valueTab}
            onChange={handleChangeTab}
            variant='fullWidth'
            indicatorColor='secondary'
            textColor='secondary'
            aria-label='icon tabs'
            className={classes.tabs}
          >
            <Tab
              icon={<PhotoLibraryIcon />}
              label='Change images'
              aria-label='phone'
              value='1'
            />
            <Tab
              icon={<ColorLensIcon />}
              label='Change color'
              aria-label='favorite'
              value='2'
            />
            <Tab
              icon={<PhotoCameraIcon />}
              label='My images'
              aria-label='favorite'
              value='3'
            />
          </Tabs>
          <TabPanel value='1'>
            <Grid
              container
              justifyContent='flex-start'
              alignItems='flex-start'
              spacing={1}
              className={classes.imagesContainer}
            >
              {gallery.map((x) => (
                <Grid
                  item
                  xs={4}
                  key={x.image}
                  className={classes.imageWrap}
                  onClick={() => setBackgroundImage(x.image, 'server')}
                  onMouseEnter={() => handleImageMouseEnter(x.image)}
                  onMouseLeave={handleImageMouseLeave}
                >
                  <div className={classes.imageOverlay} />
                  <LazyImage
                    src={x.media ?? x.image} 
                    alt={x.name}
                    selected={isBgImageOn && x.image === bgImage}
                  />
                </Grid>
              ))}
            </Grid>
          </TabPanel>
          <TabPanel value='2'>
            <Grid
              container
              justifyContent='flex-start'
              alignItems='flex-start'
              spacing={1}
              className={classes.imagesContainer}
            >
              <Grid item className={classes.colorsWrap}>
                {bgColorPalette.map((x) => (
                  <IconButton onClick={() => setBackgroundColor(x)} key={x}>
                    <div
                      style={{ background: x }}
                      className={classNames(classes.colorItem, {
                        [classes.selectedColor]: x === bgColor && !isBgImageOn,
                      })}
                    ></div>
                  </IconButton>
                ))}
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value='3'>
            <Grid
              container
              justifyContent='flex-start'
              alignItems='flex-start'
              spacing={1}
              className={classes.imagesContainer}
            >
              <Grid item xs={4} className={classes.imageWrap}>
                <div {...getRootProps({ className: classes.dropZone })}>
                  <input {...getInputProps()} />
                  {isDragAccept && (
                    <p className='dropzone_text'>
                      <span className='alert'>All files will be accepted</span>
                    </p>
                  )}
                  {isDragReject && (
                    <p className='dropzone_text'>
                      <span className='alert'>Some files will be rejected</span>
                    </p>
                  )}
                  {!isDragActive && (
                    <>
                      <h3>Drag &amp; drop</h3>
                      <p className='dropzone_text'>
                        <span>Your own images (max 10)</span>
                        <span>[ jpg, jpeg &amp; png only]</span>
                      </p>
                    </>
                  )}
                </div>
              </Grid>

              {my_gallery.map((x) => (
                <Grid
                  item
                  xs={4}
                  key={x.name}
                  className={classes.imageWrap}
                  onClick={() => setBackgroundImage(x.image, 'local')}
                  onMouseEnter={() => handleImageMouseEnter(x.image)}
                  onMouseLeave={handleImageMouseLeave}
                >
                  <div className={classes.imageOverlay} />
                  <LazyImage
                    src={x.image}
                    alt={x.name}
                    selected={isBgImageOn && x.image === bgImage}
                  />
                  <span
                    onClick={(e) => handleRemoveMyBackground(x.name, e)}
                    className={classes.removeImage}
                  >
                    <CancelIcon fontSize='small' />
                  </span>
                </Grid>
              ))}
            </Grid>
          </TabPanel>
        </TabContext>
      </SlidingPane>

      <SlidingPane
        className='settings-sliding-panel'
        overlayClassName='settings-overlay-sliding-panel'
        isOpen={panel.name === PANEL_SETTINGS}
        from='bottom'
        width='320px'
        hideHeader
        shouldCloseOnEsc
        closeIcon={<div>&times;</div>}
        onRequestClose={() => handleOpen('')}
      >
        <LandscapeSettings />
      </SlidingPane>

      <SlidingPane
        className='info-sliding-panel'
        overlayClassName='info-overlay-sliding-panel'
        isOpen={panel.name === PANEL_INFO}
        from='bottom'
        width='540px'
        hideHeader
        shouldCloseOnEsc
        closeIcon={<div>&times;</div>}
        onRequestClose={() => handleOpen('')}
      >
        <div className='info-header'><a href="https://webstars.org" target="_blank">Webstars</a>{" - Giving Users the Power to Make the World a Better Place through Search"}</div>
        <div className='info-content'>{"Refer your workplace, organization, your friends and family, together we can make a difference"}</div>
       

      </SlidingPane>

    </>
  );
};

function mapStateToProps({ landscape, panel }: AllState, props: any) {
  return {
    gallery: landscape.gallery,
    my_gallery: landscape.my_gallery,
    isBgImageOn: landscape.isBgImageOn,
    bgColor: landscape.bgColor,
    bgImage: landscape.bgImage,
    panel: panel,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return (bindActionCreators(
    {
      getImagesGallery: landscapeActions.getImagesGallery,
      getMyImagesGallery: landscapeActions.getMyImagesGallery,
      setBackgroundImage: landscapeActions.setBackgroundImage,
      setTestBackgroundImage: landscapeActions.setTestBackgroundImage,
      clearTestBackgroundImage: landscapeActions.clearTestBackgroundImage,
      setBackgroundColor: landscapeActions.setBackgroundColor,
      openPanel: (name) => openPanel(name),
    },
    dispatch
  ) as unknown) as IDispatchProps;
}

export default connect(mapStateToProps, mapDispatchToProps)(Landscape);
