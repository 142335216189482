import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  IconButton,
  Tooltip,
  makeStyles,
  Checkbox,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  Button,
  Typography
} from '@material-ui/core';
import { AllState } from 'src/types/AllState';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import Modal from 'src/components/containers/Modal/Modal';
import withoutMoving from 'src/components/hocs/withMoving/withoutMoving';
import { getTodoLists, createTodoList, clearTodoErrors, patchTodoList } from 'src/store/todoList/actions';
import moment from 'moment';
import { toast } from 'react-toastify';

const useStyles = makeStyles((theme) => ({
  rootList: {
    width: '100%',
    height: '100%',
    overflow: 'auto',
    
    '&::-webkit-scrollbar-track': {
      borderRadius: '10px',
      border: '1px solid #ccc',
      padding: '2px 0',
      backgroundColor: 'rgba(0,0,0,0.1)',
    },
    '&::-webkit-scrollbar': {
      width: '10px',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '5px',
      boxShadow: 'inset 0 0 6px rgba(0,0,0,.6)',
      background: 'linear-gradient(left, #fff, #e4e4e4)',
      border: '3px solid transparent',
      backgroundClip:'padding-box',
    },
    '& .MuiCollapse-wrapper': {
      padding: '5px 15px',
    },    
    '& .MuiListItem-dense' : {
      padding: '0px 15px',
    }
  },
  boxList: {
    height: '60%',
    
  },
  boxAction: {
    padding: '0px 15px',
    height: '20%',
  },
  formControl: {
    width: '100%',
  },
  buttonGroup: {
    marginTop: '20px',
    width: '100%',
    "& button": {
      float: 'right',
      marginLeft: '5px'
    }
  },
  title:{
    margin: theme.spacing(3, 0, 1),
  }
}));

const TodoListWidget: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [data, setData]: [any, Function] = useState('');

  const { todos, errors, success, responseTime, isAuthenticated } = useSelector(
    ({ todoLists: { todos, errors, success, responseTime }, user: { isAuthenticated } }: AllState) => ({
      todos,
      errors,
      success,
      responseTime,
      isAuthenticated
    })
  );
  
  const [isModalOpen, setModalOpen] = useState<boolean>(false);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(getTodoLists(moment().format('YYYY-MM-DD')));
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (errors.length == 0) {
      setData({...data, description: ''});
    }
  }, [errors]);

  useEffect(() => {
    if (success) {
      toast.success('Add To Do success');
    }
  }, [success, responseTime]);

  const handleChange = (event: any) => {
    const name = event.target.name;
    const value = event.target.value;
    setData({...data, [name]: value});
  }

  const handleChangeChkTodo = (id: number | undefined, event: any): any => {
    event.stopPropagation();
    dispatch(patchTodoList(id || 0, {
      status: event.target.checked ? 1 : 0,
      done_date: event.target.checked ? moment().format('YYYY-MM-DD HH:mm:ss') : null,
    }));
  }

  const handleAddTodo = () => {
    dispatch(createTodoList(data.description || ''));
  }

  const handleCancel = () => {
    setData({...data, description: ''});
    dispatch(clearTodoErrors());
  }

  const handleCloseModel = () => {
    setModalOpen(false);
    setData({...data, description: ''});
    dispatch(clearTodoErrors());
  }

  return (
    <>
    {isAuthenticated &&
    <>
      <div>
        <Tooltip title="Open My Stickers" style={{position: "absolute", top: "20px", right: "130px", zIndex: 1}}>
          <IconButton
            onClick={() => setModalOpen(true)}
            className='Mui-calendar'
          >
            <PlaylistAddCheckIcon className='Mui-whiteIcon' />
          </IconButton>
        </Tooltip>
      </div>
   
      <Modal
        open={isModalOpen}
        handleClose={handleCloseModel}
        header='My Stickers'
        attachedToRight
      >
        <Box mt={2} className={classes.boxList}>
          {todos?.length > 0 &&
            <List className={classes.rootList}>
              {todos.map((x: any) => {
              const labelId = `checkbox-list-label-${x.id}`;
              return (
                <ListItem key={x.id} role={undefined} dense button>
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={x.status == 1}
                      onChange={(e) => handleChangeChkTodo(x.id, e)}
                      disableRipple
                      inputProps={{ 'aria-labelledby': labelId }}
                      style={{zIndex: 1000}}
                    />
                  </ListItemIcon>
                  <ListItemText 
                    id={labelId} primary={x.description} 
                    style={x.status == 1 ? {textDecoration: 'line-through'} : {}}/>
                </ListItem>
                )
              })}
            </List>
          }
        </Box>
        <hr />
        <Box mt={2} className={classes.boxAction}>
        <Typography variant="h6" className={classes.title} >
        To do
          </Typography>
          <TextField
            id="description"
            name="description"
            label=""
            multiline
            rows={2}
            rowsMax={4}
            className={classes.formControl}
            InputLabelProps={{
              shrink: true,
            }}
            value={data.description || ''}
            onChange={handleChange}
            error={errors?.description ? true: false}
            helperText={errors?.description}
          />
          <div className={classes.buttonGroup}>
            <Button variant="contained" color="primary" onClick={handleAddTodo}>
              Add
            </Button>
            <Button variant="contained" onClick={handleCloseModel}>
              Cancel
            </Button>
          </div>
        </Box>
      </Modal>
      </>
     }
    </>
  );
};

export default withoutMoving(TodoListWidget, 'todoList');
