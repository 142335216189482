import { AxiosResponse } from 'axios';
import api from './api';

class TodoListService {
  
  public async getTodoLists(clientDate: string): Promise<AxiosResponse> {
    return await api({
      method: 'get',
      url: `/api/todo-lists?clientDate=${clientDate}`,
    });
  }

  public async createTodoList(
    description: string,
  ): Promise<AxiosResponse> {
    return await api({
      method: 'post',
      url: '/api/todo-lists',
      data: { description }
    });
  }

  public async patchTodoList(
    id: number,
    dataPatch: any
  ): Promise<AxiosResponse> {
    return await api({
      method: 'patch',
      url: `/api/todo-lists/${id}`,
      data: { ...dataPatch }
    });
  }
}
export default new TodoListService();
