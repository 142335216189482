import React from 'react';
import { useSelector } from 'react-redux';
import Slider from "react-slick";
import './ListChannel.scss';
import {
  ArrowLeft,
  ArrowRight
} from '@material-ui/icons';

import {
  Typography,
  CardMedia,
  CardActionArea,
  Button,
  Card,
  CardContent,
  ImageListItem,
  ImageListItemBar,
  IconButton

} from '@material-ui/core';

//import ImageListItem from '@material-ui/core/ImageListItem';
// import ImageListItemBar from '@material-ui/core/ImageListItemBar';
// import IconButton from '@material-ui/core/IconButton';
 import StarBorderIcon from '@material-ui/icons/StarBorder';

import DeleteIcon from '@material-ui/icons/Delete';
// import Card from '@material-ui/core/Card';
// import CardActionArea from '@material-ui/core/CardActionArea';
// import CardContent from '@material-ui/core/CardContent';
// import CardMedia from '@material-ui/core/CardMedia';
// import CardActions from '@material-ui/core/CardActions';
// import IconButton from '@material-ui/core/IconButton';
//import Typography from '@material-ui/core/Typography';

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={`channel-next`} onClick={onClick}>
        <span>Next</span>
        <ArrowRight  />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={`channel-prev`} onClick={onClick}>
        <ArrowLeft />
        <span>Previous</span>
    </div>
  );
}

interface Props {
  handleClick: (e, item) => void,
  handleClose: (e, item) => void
}

const ListVideo: React.FC<Props> = ({handleClick,handleClose}) => {

  const dataChannel = useSelector(({ channels }: any) => ({ ...channels }));

  const settings = {
    prevArrow: <SamplePrevArrow />,
    nextArrow: <SampleNextArrow />,
    arrows: true,
    edgeFriction: 1,
    infinite: false,
    swipeToSlide: true,
    variableWidth: true,
    accessibility: true,
    focusOnSelect: true,
    draggable: true
  };



  //console.log(dataChannel);
  return (
    <>
      <div className="list-channel-box">
        <div className="list-channel-title">
          My Favorite channel
        </div>
        { (dataChannel?.user_channels?.length !== 0) &&
          <div className="list-channel">
            <Slider {...settings}>
              {dataChannel?.user_channels?.map((item: any, index: number) => (

                <ImageListItem className="item-channel" key={`${item.title}`}>
                  <img src={`${item.thumbnails}`} alt={item.title || 'User Channel'} onClick={(e) => handleClick(e, item.channel_id)} />
                  <ImageListItemBar
                    title={item.title || 'User Channel'}
                    className="titleBar"
                    actionIcon={
                      <IconButton aria-label={item.title || 'User Channel'} onClick={(e) => handleClose(e, item.channel_id)}>
                        <DeleteIcon className="title" />
                      </IconButton>
                    }
                  />
                </ImageListItem>

              ))}
            </Slider>
          </div>
        }
      </div>
    </>
  )
}

export default ListVideo;