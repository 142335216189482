//import createMuiTheme from '@material-ui/core/styles/createMuiTheme';

//import {  createMuiTheme } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles';

const custom = {
  widgetBoxShadow:
    '0px 1px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
  colors: {
    lightGrey: '#8e8d8d63',
  },
};

const components = {
  widget: {
    transition: 'background 0.5s linear',
    borderRadius: '10px',
  },
  widgetOnHover: {
    boxShadow: custom.widgetBoxShadow,
    background: custom.colors.lightGrey,
  },
};

const theme = {
  custom,
  components,
  overrides: {
    MuiPaper: {
      root: {
        '&.Mui-quote': {
          cursor: 'pointer',
          'user-select': 'none',
          boxShadow: 'none',
          background: 'transparent',
          width: 'fit-content',
          maxWidth: '700px',
          '@media (max-width:800px)': {
            maxWidth: '550px',
          },
          
          '@media (min-width:1025px)': {
            maxWidth: '800px',
          },
          
          padding: '20px',
          position: 'absolute',
          right: '50%',
          transform: 'translateX(50%)',
          ...components.widget,

          '&:hover': {
            '& p:last-child': {
              opacity: 1,
            },
            ...components.widgetOnHover,
          },
        },
        '&.Mui-searchHistory': {
          background: 'rgb(255 255 255 / 95%)',
          color: '#222',
          position: 'absolute',
          bottom: '60px',
          right: 'calc(50% + 5px)',
          transform: 'translateX(50%)',
          width: '100%',
          maxWidth: '720px',
          padding: '0 15px',
        },
        '&.Mui-weather': {
          cursor: 'pointer',
          userSelect: 'none',
          position: 'relative',
          //background: '#ffffff9e',
          padding: '10px 15px',

          '&:after': {
            position: 'absolute',
            content: 'close-quote',
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
          },
        },
      },
    },
    MuiTypography: {
      root: {
        '&.Mui-useName': {
          fontSize: '23px',
          color: 'white',
        },
        '&.Mui-useEmail': {
          color: 'white',
        },
        '&.Mui-quote': {          
          fontFamily: 'Times New Roman',
          textAlign: 'center',
          lineHeight:'normal',
        },
        '&.Mui-quoteAuthor': {
          'font-size': '24px',
          'font-weight': 'bold',
          'text-align': 'end',
          'margin-top': '5px',
          transition: 'opacity 0.5s linear',
          opacity: 0,
          fontFamily: 'Times New Roman',
        },
        '&.Mui-weather': {
          fontFamily: 'Roboto',
          textAlign: 'center',
          color: '#2d2d2d',
        },
        '&.Mui-weatherRange': {
          marginTop: '-7px',
          marginBottom: '2px',
        },
        '&.Mui-weatherCity': {
          fontSize: '22px',
          fontWeight: 600,
        },
        '&.Mui-weatherMain': {
          fontSize: '16px',
          marginTop: '-20px',
        },
        '&.Mui-weatherIcon': {
          display: 'flex',
          gap: '8px',
        },
        '&.Mui-weatherDegrees': {
          fontSize: '24px',
          '& > span': {
            fontSize: '30px',
            fontWeight: 600,
          },
        },
        '&.Mui-searchItem': {
          cursor: 'pointer',
          margin: '15px 0',
          lineHeight: '14px',
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
        },
        '&.Mui-modalHeader': {
          marginLeft: '15px',
          marginTop: '7px',
          fontWeight: 600,
        },
      },
    },
    MuiFormControl: {
      root: {
        '&.Mui-searchInput': {
          margin: 0,
        },
      },
    },
    MuiIconButton: {
      root: {
        '&.Mui-mic': {
          transition: 'padding 0.25s linear',
        },
        '&.Mui-activeMic': {
          backgroundColor: 'rgb(63 81 181 / 24%) !important',
          padding: '8px',
        },
        '&.Mui-calendar': {
          padding: '12px',
          background: 'rgba(0, 0, 0, 0.3)',
        },
        '&.Non-background': {
          background: 'none',
          '& span.MuiIconButton-label':{
            fontSize:'14px',
            fontWeight:'bold',
            color:'#22589A',
            padding:'10px',
            borderRadius: '15px',
            backgroundColor:'rgba(255,255,255,0.65)',
          }
        }
      },
    },
    MuiSvgIcon: {
      root: {
        '&.Mui-whiteIcon': {
          fill: 'white',
        },
        '&.Mui-orangeIcon': {
          fill: 'darkorange',
        },
        '&.Mui-searchIcon': {
          fontSize: '30px',
          cursor: 'pointer',
        },
        '&.Mui-mr-5': {
          marginRight: '5px',
        },
      },
    },
    MuiFormControlLabel: {
      root: {
        'user-select': 'none',
        '&.Mui-ml-zero': {
          marginLeft: 0,
        },
      },
    },
    MuiFormLabel: {
      root: {
        '&.Mui-black': {
          color: 'initial',
          '&$focused': {
            color: 'initial',
          },
        },
        '&.Mui-settingsLabel': {
          display: 'block',
          margin: '10px 0',
          fontWeight: 600,
        },
      },
    },
    MuiButton: {
      root: {
        '&.Mui-submitFormBtn': {
          margin: '0px 0px',
          width: 'calc(100% + 0px)',
          borderRadius: '0 0 10px 10px',
          height: '43px',
        },
        '&.Mui-socialBtn': {
          height: '34px',
          padding: '6px 10px',
          fontSize: '13px',
          minWidth: '120px',
        },
      },
      label: {
        'text-transform': 'none',
      },
    },
    MuiOutlinedInput: {
      root: {
        '&.Mui-searchInput': {
          background: 'white',
          lineHeight: '23px',
          opacity: '0.35',
          fontWeight: 600,
          fontSize: '19px',
          transition: 'opacity 0.4s linear',
          '&:hover, &$focused, &.Mui-hasValue': {
            opacity: 1,
          },
          '&$focused, .MuiOutlinedInput-notchedOutline': {
            border: 'none',
          },
        },
      },
      input: {
        padding: '13.5px 14px',
      },
    },
    MuiTab: {
      root: {
        '&.Mui-full': {
          flex: 1,
        },
      },
    },
    MuiAvatar: {
      root: {
        '&.Mui-bigAvatar': {
          width: '40px',
          height: '40px',
        }
      },
    },
    MuiAutocomplete: {
      root: {
        '&.Mui-search': {
          width: '100%',
        },
      },
      input: {
        padding: '6.5px 4px!important',
        '&.Mui-search': {
          padding: '4px !important',
        },
      },
      inputRoot: {
        '&.Mui-searchInput': {
          paddingRight: '10px !important',
          paddingTop: '4px !important',
          paddingBottom: '4px !important',
        },
      },
    },
    MuiDivider: {
      root: {
        '&.Mui-searchAdornmentDivider': {
          height: '30px',
          margin: '0 10px',
        },
      },
    },
  },
};

export default createTheme(theme);
