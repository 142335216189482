import React, { useState, useEffect } from 'react';
import { CssBaseline, MuiThemeProvider } from '@material-ui/core';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import Background from './Background/Background';
import HomeLogoWidget from 'src/components/widgets/HomeLogo/HomeLogo';
import ClockWidget from 'src/components/widgets/Clock/Clock';
import QuoteWidget from 'src/components/widgets/Quote/Quote';
import Landscape from 'src/components/widgets/Landscape/Landscape';
import SearchWidget from 'src/components/widgets/Search/Search';
import WeatherWidget from 'src/components/widgets/Weather/Weather';
import UserAuthWidget from 'src/components/widgets/UserAuth/UserAuth';
import SearchHistoryWidget from 'src/components/widgets/SearchHistory/SearchHistory';
import SpeedTestWidget from 'src/components/widgets/SpeedTest/SpeedTest';
import CounterWidget from 'src/components/widgets/Counter/Counter';
import BestOfChromeWidget from 'src/components/widgets/BestOfChrome/BestOfChrome';
import FavoritesWidget from 'src/components/widgets/Favorites/Favorites';
import CalendarWidget from 'src/components/widgets/Calendar/Calendar';
import ChannelWidget from 'src/components/widgets/Channel/Channel';
import EditWidget from 'src/components/widgets/Edit/Edit';
import useHiddenWidgets from 'src/hooks/useHiddenWidgets';
import useUserData from 'src/hooks/useUserData';
import theme from 'src/styles/theme';
import './app.styles.scss';
import "react-sliding-pane/dist/react-sliding-pane.css";
import TodoListWidget from 'src/components/widgets/TodoList/TodoList';
// import PartnerAlert from 'src/components/utils/PartnerAlert';

const App: React.FC = () => {
  useHiddenWidgets();
  useUserData();
  
  const [state, setState] = useState({
    isPaneOpen: false
  });

  return (
    <>
    {/* <PartnerAlert /> */}
    <MuiThemeProvider theme={theme}>
      <ToastContainer newestOnTop position='bottom-left' />
      <CssBaseline />
      <Background />
      <HomeLogoWidget />     
      <div className='content'>
     
        <ClockWidget />
        <WeatherWidget />
        <SearchWidget />
        <FavoritesWidget />
        <QuoteWidget />
        <UserAuthWidget />
        <CalendarWidget />
        <TodoListWidget />
        
        <div className="Mui-taskbar">
          <Landscape />
          <CounterWidget />
          {/* <SearchHistoryWidget /> */}
          <SpeedTestWidget />
          {/* <BestOfChromeWidget /> */}
          <ChannelWidget />
        </div>
      </div>
    </MuiThemeProvider>
    </>
  );
};

export default App;
