import queryString from 'query-string';
import { v4 as uuidv4 } from 'uuid';
import DB from "src/helpers/db";
import api from 'src/services/api';

export async function install()
{
  const code = await DB.setStore('extension_info', 'web_start').getItem('code');
  if (!code) {
    chrome?.history?.search({
      'text': 'https://chrome.google.com/webstore',
      'startTime': Date.now() - 3600000, // Before now 1 hour
      'maxResults': 1
    },
    async function(historyItems) {
      let extensionInfo = { code: uuidv4() };
      chrome?.runtime?.sendMessage({ message: {code: extensionInfo['code']} });
      await DB.setStore('extension_info', 'web_start').setItem('code', extensionInfo['code']);
      let url = historyItems[0]?.url || null;
      if (url && url.includes(process.env.EXTENSION_ID || '')) {
        let arrParams = url.split('?');
        if (arrParams.length == 2) {
          let qs = queryString.parse(arrParams[1]);
          if (qs.pid) {
            extensionInfo['pid'] = qs.pid;
            await DB.setStore('extension_info', 'web_start').setItem('pid', qs.pid);
          }
        }
      }
      await api({
        method: 'post',
        url: '/api/extensions',
        data: extensionInfo
      });
      
    });
  }
}

