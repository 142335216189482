import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import * as quoteActions from 'src/store/quote/actions';
import { AllState } from 'src/types/AllState';
import { Quote, QuotePosition, QuoteSize } from 'src/types/Quote';
import Modal from 'src/components/containers/Modal/Modal';
import { IQuoteStoreState } from 'src/types/QuoteStoreState';
import ColorPicker from './ColorPicker';
import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  Switch,
  Typography,
  Tooltip,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { IPanel } from 'src/types/IPanel';

interface IDispatchProps {
  getRandomDailyQuote: () => Promise<void>;
  updateQuoteOptions: (options: Partial<IQuoteStoreState>) => void;
}

interface IStateProps {
  quote: Quote | null;
  quoteColor: string;
  position: QuotePosition;
  fontSize: QuoteSize;
  color: string;
  isOn: boolean;
  isBgImageOn: boolean;
  isAuthenticated: boolean;
  panel: IPanel;
}

type Props = IDispatchProps & IStateProps;
const useStyles = makeStyles((theme) => ({
  quote_setting: {},
  'Mui-quote': {
    maxWidth: '600px',
    [theme.breakpoints.up('md')]: {
      maxWidth: '800px',
    },
  },
  quote: {
    '& .Mui-quote': {},
  },
}));

const QuoteWidget: React.FC<Props> = ({
  isOn,
  quote,
  color,
  fontSize,
  position,
  quoteColor,
  isBgImageOn,
  isAuthenticated,
  panel,
  getRandomDailyQuote,
  updateQuoteOptions,
}) => {
  const [isModalOpen, setModalOpen] = useState<boolean>(false);

  useEffect(() => {
    getRandomDailyQuote();
  }, []);

  const handleOptionsChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      updateQuoteOptions({
        [e.target.name]: e.target.value,
      });
    },
    [updateQuoteOptions]
  );

  const handleSwitchQuote = useCallback(() => {
    if (!isBgImageOn) {
      setModalOpen(false);
      updateQuoteOptions({
        isOn: !isOn,
      });
    }
  }, [updateQuoteOptions, setModalOpen, isBgImageOn, isOn]);

  const dynamicStyles = useMemo(() => {
    return {
      root: {
        [position]: ( position =='bottom'?'8%':'28%'),
        zIndex:1,
      },
      quote: {
        fontSize: `${fontSize}px`,
        color: color,
      },
      authorCaption: {
        color: color,
      },
    };
  }, [position, fontSize, color]);

  const classes = useStyles();
  return quote && isOn && panel.name === '' ? (
    <>
      <Tooltip title="Double click to change the setting for Daily Quote" placement="top">
      <Paper
        className='Mui-quote'
        //className={classes.quote}
        style={dynamicStyles.root}
        onDoubleClick={() => setModalOpen(true)}
      >
        <Typography style={dynamicStyles.quote} className='Mui-quote'>
          <i>{quote.text}</i>
        </Typography>
        <Typography
          style={dynamicStyles.authorCaption}
          className='Mui-quoteAuthor'
        >
          <i>- {quote.author}</i>
        </Typography>
      </Paper>
      </Tooltip>
      <div className={classes.quote_setting}>
        <Modal
          useBackdrop
          open={isModalOpen}
          header='Quote'
          handleClose={() => setModalOpen(false)}
        >
          <Box px={3} pb={2}>
            <Grid container spacing={2}>
              {!isBgImageOn && isAuthenticated ? (
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={isOn}
                        onChange={handleSwitchQuote}
                        color='primary'
                      />
                    }
                    label='On'
                    color='white'
                  />
                </Grid>
              ) : (
                ''
              )}
              <Grid item xs={12}>
                <FormControl component='fieldset'>
                  <FormLabel component='legend'>Position</FormLabel>
                  <RadioGroup
                    row
                    name='position'
                    value={position}
                    onChange={handleOptionsChange}
                  >
                    <FormControlLabel
                      value='top'
                      control={<Radio color='primary' />}
                      label='Top'
                    />
                    <FormControlLabel
                      value='bottom'
                      control={<Radio color='primary' />}
                      label='Bottom'
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl component='fieldset'>
                  <FormLabel component='legend'>Size</FormLabel>
                  <RadioGroup
                    row
                    name='size'
                    value={fontSize}
                    onChange={handleOptionsChange}
                  >
                    {(Object.keys(QuoteSize) as Array<
                      keyof typeof QuoteSize
                    >).map((i) => (
                      <FormControlLabel
                        key={i}
                        value={QuoteSize[i]}
                        control={<Radio color='primary' />}
                        label={i}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl component='fieldset'>
                  <FormLabel>Color</FormLabel>
                  <Box pt={2} ml={-2}>
                    <ColorPicker
                      quoteColor={quoteColor}
                      updateQuoteOptions={updateQuoteOptions}
                    />
                  </Box>
                </FormControl>
              </Grid>
            </Grid>
          </Box>
        </Modal>
      </div>
    </>
  ) : (
    <></>
  );
};

function mapStateToProps(
  { quote, landscape, user, panel }: AllState,
  props: any
) {
  return {
    isAuthenticated: user.isAuthenticated,
    isBgImageOn: landscape.isBgImageOn,
    quoteColor: quote.color,
    quote: quote.quote,
    position: quote.position,
    fontSize: quote.size,
    color: quote.color,
    isOn: quote.isOn,
    panel: panel,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return (bindActionCreators(
    {
      getRandomDailyQuote: quoteActions.getRandomDailyQuote,
      updateQuoteOptions: quoteActions.updateQuoteOptions,
    },
    dispatch
  ) as unknown) as IDispatchProps;
}

export default connect(mapStateToProps, mapDispatchToProps)(QuoteWidget);
