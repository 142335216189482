import Immutable from 'seamless-immutable';
import * as types from './actionTypes';
import { IUserStoreState } from '../../types/UserStoreState';
import * as _ from 'lodash';
import moment from 'moment';

const emptyState: IUserStoreState = {
  authPopup: {},
  isWaiting: true,
  isAuthenticated: false,
  arePreferencesLoaded: false,
  areWidgetsRenderable: false,
  loggedInUser: null,
  calendarEvents: [],
  calendarEventDates: [],
  responseAddCalendarEventInvalid: null
};

const initialState = Immutable(_.cloneDeep(emptyState));

export default function reduce(state = initialState, action: any = {}): any {
  switch (action.type) {
    case types.OPEN_AUTH_POPUP:
      return {
        ...state,
        authPopup: {...action.authPopup}
      };
    case types.LOGIN_ERROR:
      return {
        ...state,
        isWaiting: false,
        isAuthenticated: false,
        areWidgetsRenderable: true,
      };

    case types.LOGIN_SUCCESS:
      return {
        ...state,
        isWaiting: false,
        isAuthenticated: true,
        loggedInUser: action.loggedInUser,
      };

    case types.LOGOUT_USER:
      return {
        ...state,
        isAuthenticated: false,
        loggedInUser: null,
        areWidgetsRenderable: true,
      };

    case types.PREFERENCES_LOADED:
      return {
        ...state,
        arePreferencesLoaded: true,
        areWidgetsRenderable: true,
      };

    case types.SET_CALENDAR_EVENTS:
      return {
        ...state,
        calendarEvents: action.payload,
      };
    case types.ADD_CALENDAR_EVENT_SUCCESS:
      return {
        ...state,
        responseAddCalendarEventSuccess: action.payload,
        responseUpdateCalendarEventSuccess: null,
        responseAddCalendarEventInvalid: null,
        responseAddCalendarEventFail: null,
      };
    case types.UPDATE_CALENDAR_EVENT_SUCCESS:
      return {
        ...state,
        responseAddCalendarEventSuccess: null,
        responseUpdateCalendarEventSuccess: action.payload,
        responseAddCalendarEventInvalid: null,
        responseAddCalendarEventFail: null,
      };
    case types.ADD_CALENDAR_EVENT_INVALID:
      return {
        ...state,
        responseAddCalendarEventSuccess: null,
        responseUpdateCalendarEventSuccess: null,
        responseAddCalendarEventInvalid: action.payload,
        responseAddCalendarEventFail: null,
      };
    case types.ADD_CALENDAR_EVENT_FAIL:
      return {
        ...state,
        responseAddCalendarEventSuccess: null,
        responseUpdateCalendarEventSuccess: null,
        responseAddCalendarEventInvalid: null,
        responseAddCalendarEventFail: action.payload,
      };

    case types.CLEAN_ADD_CALENDAR_EVENT:
      return {
        ...state,
        responseAddCalendarEventSuccess: null,
        responseUpdateCalendarEventSuccess: null,
        responseAddCalendarEventInvalid: null,
        responseAddCalendarEventFail: null,
      };
    
      case types.GET_CALENDAR_EVENT_DATES:
        return {
          ...state,
          calendarEventDates: action.payload,
        }

    default:
      return state;
  }
}
