import * as types from './actionTypes';
import QuoteService from '../../services/quote';
import UserService from '../../services/user';
import { IQuoteStoreState } from 'src/types/QuoteStoreState';
import { Quote } from 'src/types/Quote';
import { UserPreferenceTypes } from 'src/types/UserPreferenceType';

export function updateQuoteOptions(
  options: Partial<IQuoteStoreState>
): (dispatch: any, getState: any) => Promise<void> {
  return async (dispatch: any, getState: any): Promise<void> => {
    try {
      if (!!options) {
        await dispatch({
          type: types.UPDATE_QUOTE_OPTIONS,
          payload: options,
        });

        const {
          quote: { quote, ...info },
          user: { isAuthenticated },
        } = getState();

        if (isAuthenticated) {
          const data = { ...info, ...options };
          await UserService.postUserPreference(data, UserPreferenceTypes.quote);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
}

export function getRandomDailyQuote(): (
  dispatch: any,
  getState: any
) => Promise<void> {
  return async (dispatch: any, getState: any): Promise<void> => {
    try {
      const { data } = await QuoteService.getRandomDailyQuote();

      if (data.quote) {
        dispatch({
          type: types.GET_DAILY_QUOTE,
          quote: data.quote as Quote,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };
}
