import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { AllState } from 'src/types/AllState';
import { bindActionCreators, Dispatch } from 'redux';
import * as quoteActions from 'src/store/quote/actions';
import * as clockActions from 'src/store/clock/actions';
import * as weatherActions from 'src/store/weather/actions';
import { IQuoteStoreState } from 'src/types/QuoteStoreState';
import * as landscapeActions from 'src/store/landscape/actions';
import { setShowChannel } from 'src/store/channels/actions';
import {
  Switch,
  Divider,
  FormLabel,
  TextField,
  FormControlLabel,
  Typography,
  makeStyles,
  MenuList,
  MenuItem,
  Grid,
} from '@material-ui/core';

import { Autocomplete } from '@material-ui/lab';
import voiceSearchLanguages, {
  Language,
} from 'src/constants/voiceSearchLanguages';

const useStyles = makeStyles({
  root: {
    color: '#ffd',
    '& .MuiList-root': {
      padding: '4px 0',
      '& .MuiMenuItem-root': {
        padding: '0px 10px',
        '& .MuiFormLabel-root': {
          fontSize: '18px',
          color: '#fff',
        },

        '& .MuiTypography-body1': {
          fontSize: '14px',
        },
      },
      '& .MuiDivider-root': {
        backgroundColor: 'rgba(221, 226, 235, 0.6)',
        marginBottom: '8px',
      },
      '& .MuiAutocomplete-root': {
        //border: '1px solid #ddd',

        '& .MuiInputBase-fullWidth': {
          color: '#eee',
        },
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: 'rgba(221, 226, 235, 0.6)',
          top: '5px',
        },
        '& .MuiInputLabel-formControl': {
          top: '8px',
        },
      },
    },
  },
});
interface IDispatchProps {
  getRandomDailyImage: () => void;
  switchIsClockOn: (isOn: boolean) => void;
  switchIsWeatherOn: (isOn: boolean) => void;
  updateLandscapeOption: (field: string, val: any) => void;
  updateQuoteOptions: (options: Partial<IQuoteStoreState>) => void;
  switchIsChannelOn: (field: string, val: boolean) => void;
}

interface IStateProps {
  isRandom: boolean;
  isQuoteOn: boolean;
  isClockOn: boolean;
  isWeatherOn: boolean;
  isCalendarOn: boolean;
  isSpeedTestOn: boolean;
  voiceSearchLang: string;
  isSpeedTestDark: boolean;
  isShowChannelAll: boolean;
  isShowChannelWebstart: boolean;
  isShowChannelPartner: boolean;
  isAuthenticated: boolean;
}

type Props = IDispatchProps & IStateProps;

const LandscapeSettings: React.FC<Props> = ({
  isRandom,
  isQuoteOn,
  isClockOn,
  isWeatherOn,
  isCalendarOn,
  isShowChannelAll,
  isShowChannelWebstart,
  isShowChannelPartner,
  isSpeedTestOn,
  isSpeedTestDark,
  isAuthenticated,
  voiceSearchLang,
  switchIsClockOn,
  switchIsWeatherOn,
  updateQuoteOptions,
  getRandomDailyImage,
  updateLandscapeOption,
  switchIsChannelOn
}) => {
  const classes = useStyles();

  useEffect(() => {
    if (isRandom) {
      getRandomDailyImage();
    }
  }, [isRandom]);

  const handleQuoteSwitchInput = useCallback(
    () =>
      updateQuoteOptions({
        isOn: !isQuoteOn,
      }),
    [isQuoteOn, updateQuoteOptions]
  );

  const switchLandscapeOption = useCallback(
    (field, val) => updateLandscapeOption(field, val),
    [updateLandscapeOption]
  );

  const handleChannelSwitch = useCallback(
    (field, val) => switchIsChannelOn(field, val),
    [switchIsChannelOn]
  );

  const handleWeatherSwitch = useCallback(
    () => switchIsWeatherOn(!isWeatherOn),
    [isWeatherOn]
  );

  const handleClockSwitch = useCallback(() => switchIsClockOn(!isClockOn), [
    isClockOn,
  ]);

  const getCheckbox = useCallback(
    (label: string, onChange: (props: any) => any, checked: boolean) => {
      return (
        <div>
          <FormControlLabel
            className='Mui-ml-zero'
            key={label}
            control={
              <Switch checked={checked} onChange={onChange} color='primary' />
            }
            label={label}
            color='white'
          />
        </div>
      );
    },
    []
  );

  return (
    <div className={classes.root}>
      <MenuList>
        <MenuItem>
          <FormLabel className='Mui-settingsLabel'>Settings</FormLabel>
        </MenuItem>
        <Divider />
        <MenuItem>
          {getCheckbox(
            'Random daily landscape',
            () => switchLandscapeOption('isRandom', !isRandom),
            isRandom
          )}
        </MenuItem>
        <MenuItem>
          {getCheckbox(
            'Daily quote display',
            handleQuoteSwitchInput,
            isQuoteOn
          )}
        </MenuItem>

        <MenuItem>
        <Grid  container style={{ width: '100%' }}>
          <Grid item xs={12}>
          {getCheckbox(
            'Speed Test display',
            () => switchLandscapeOption('isSpeedTestOn', !isSpeedTestOn),
            isSpeedTestOn
          )}
          </Grid>
          <Grid  item xs={12} sm={1}></Grid>
          <Grid  item xs={12} sm={5}>
            {!isSpeedTestOn
              ? null
              : getCheckbox(
                  isSpeedTestDark ? 'Dark Mode' : 'White Mode',
                  () =>
                    switchLandscapeOption('isSpeedTestDark', !isSpeedTestDark),
                  isSpeedTestDark
              )}

          </Grid>
        </Grid>
         
        </MenuItem>

        <MenuItem>
          <FormLabel className='Mui-settingsLabel'>Voice Search</FormLabel>
        </MenuItem>
        <Divider />
        <MenuItem>
          <Autocomplete
            id='combo-box-demo'
            value={
              voiceSearchLanguages.find((x) => x.value === voiceSearchLang) ??
              voiceSearchLanguages.find((x) => x.value === 'en-US')
            }
            options={voiceSearchLanguages}
            onChange={(e, val: Language | null) =>
              updateLandscapeOption('voiceSearchLang', val?.value ?? '')
            }
            getOptionLabel={(option) => option.title}
            style={{ width: 300 }}
            renderInput={(params) => (
              <TextField
                {...params}
                label='Language for search'
                variant='outlined'
              />
            )}
          />
        </MenuItem>
        <MenuItem>
          {getCheckbox('Clock display', handleClockSwitch, isClockOn)}
        </MenuItem>
        <MenuItem>
          {getCheckbox('Weather display', handleWeatherSwitch, isWeatherOn)}
        </MenuItem>
        <MenuItem>
          {getCheckbox(
            'Calendar display',
            () => switchLandscapeOption('isCalendarOn', !isCalendarOn),
            isCalendarOn
          )}
        </MenuItem>
        {isAuthenticated && <>
        <MenuItem>
          <FormLabel className='Mui-settingsLabel'>Favorite Channels</FormLabel>
        </MenuItem>
        <Divider />
        <MenuItem>
        <Grid  container style={{ width: '100%' }}>
          <Grid item xs={12}>
          {getCheckbox(
            'My Youtube Channel',
            () => handleChannelSwitch('all', !isShowChannelAll),
            isShowChannelAll
          )}
          </Grid>
          <Grid  item xs={12} sm={1}></Grid>
          <Grid  item xs={12} sm={5}>
            {!isShowChannelAll
              ? null
              : getCheckbox(
                  'Webstars',
                  () =>
                  handleChannelSwitch('webstart', !isShowChannelWebstart),
                  isShowChannelWebstart
                )}
          </Grid>
          <Grid  item xs={12} sm={5}>

          {!isShowChannelAll
              ? null
              : getCheckbox(
                  'Partners',
                  () =>
                  handleChannelSwitch('partner', !isShowChannelPartner),
                  isShowChannelPartner
                )}

          </Grid>
        </Grid>
        </MenuItem>
        </>}
      </MenuList>
    </div>

    // <Grid item xs={12}>
    //   <Box p={2} pt={0}>
    //     {!isRandom && (
    //       <>
    //         <Box mb={1}>
    //           <FormLabel className='Mui-settingsLabel'>Background</FormLabel>
    //         </Box>
    //         {getCheckbox(
    //           'Random daily landscape',
    //           () => switchLandscapeOption('isRandom', !isRandom),
    //           isRandom
    //         )}
    //         <Divider />
    //       </>
    //     )}
    //     <Box mt={2} mb={1}>
    //       <FormLabel className='Mui-settingsLabel'>Quote</FormLabel>
    //       <Divider />
    //     </Box>
    //     <div>
    // {getCheckbox(
    //   'Daily quote display',
    //   handleQuoteSwitchInput,
    //   isQuoteOn
    // )}
    //     </div>
    //     <Box mt={2} mb={1}>
    //       <Divider />
    //       <FormLabel className='Mui-settingsLabel'>Speed Test</FormLabel>
    //       <Divider />
    //     </Box>
    //     <div>
    // {getCheckbox(
    //   'Speed Test display',
    //   () => switchLandscapeOption('isSpeedTestOn', !isSpeedTestOn),
    //   isSpeedTestOn
    // )}
    // {!isSpeedTestOn
    //   ? null
    //   : getCheckbox(
    //       'Speed Test grayscale',
    //       () =>
    //         switchLandscapeOption('isSpeedTestDark', !isSpeedTestDark),
    //       isSpeedTestDark
    //     )}
    //     </div>
    //     <Box mt={2} mb={3}>
    // <Divider />
    // <FormLabel className='Mui-settingsLabel'>Voice Search</FormLabel>
    // <Divider />
    //     </Box>
    //     <Box maxWidth={300}>
    // <Autocomplete
    //   value={
    //     voiceSearchLanguages.find((x) => x.value === voiceSearchLang) ??
    //     voiceSearchLanguages.find((x) => x.value === 'en-US')
    //   }
    //   options={voiceSearchLanguages}
    //   onChange={(e, val: Language | null) =>
    //     updateLandscapeOption('voiceSearchLang', val?.value ?? '')
    //   }
    //   getOptionLabel={(option) => option.title}
    //   renderInput={(params) => (
    //     <TextField
    //       {...params}
    //       label='Language for search'
    //       variant='outlined'
    //     />
    //   )}
    // />
    //     </Box>
    //     <Box mt={2} mb={1}>
    //       <Divider />
    //       <FormLabel className='Mui-settingsLabel'>Clock</FormLabel>
    //       <Divider />
    //     </Box>
    //     {getCheckbox('Clock display', handleClockSwitch, isClockOn)}
    //     <Box mt={2} mb={1}>
    //       <Divider />
    //       <FormLabel className='Mui-settingsLabel'>Weather</FormLabel>
    //       <Divider />
    //     </Box>
    //     {getCheckbox('Weather display', handleWeatherSwitch, isWeatherOn)}
    //     <Box mt={2} mb={1}>
    //       <Divider />
    //       <FormLabel className='Mui-settingsLabel'>Calendar</FormLabel>
    //       <Divider />
    //     </Box>
    // {getCheckbox(
    //   'Calendar display',
    //   () => switchLandscapeOption('isCalendarOn', !isCalendarOn),
    //   isCalendarOn
    // )}
    //   </Box>
    // </Grid>
  );
};

function mapStateToProps({ landscape, quote, clock, weather, channels, user }: AllState) {
  return {
    isRandom: landscape.isRandom,
    isSpeedTestOn: landscape.isSpeedTestOn,
    isSpeedTestDark: landscape.isSpeedTestDark,
    voiceSearchLang: landscape.voiceSearchLang,
    isCalendarOn: landscape.isCalendarOn,
    isQuoteOn: quote.isOn,
    isClockOn: clock.isOn,
    isWeatherOn: weather.isOn,
    isShowChannelAll: channels?.showChannel?.all || false,
    isShowChannelWebstart: channels?.showChannel?.webstart || false,
    isShowChannelPartner: channels?.showChannel?.partner || false,
    isAuthenticated: user.isAuthenticated,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return (bindActionCreators(
    {
      getRandomDailyImage: landscapeActions.getRandomDailyImage,
      updateLandscapeOption: landscapeActions.updateLandscapeOption,
      updateQuoteOptions: quoteActions.updateQuoteOptions,
      switchIsClockOn: clockActions.switchIsOn,
      switchIsWeatherOn: weatherActions.switchIsOn,
      switchIsChannelOn: setShowChannel,
    },
    dispatch
  ) as unknown) as IDispatchProps;
}

export default connect(mapStateToProps, mapDispatchToProps)(LandscapeSettings);
