import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Draggable, { DraggableData } from 'react-draggable';
import * as positionsActions from 'src/store/positions/actions';
import { PositionField } from 'src/types/PositionsStoreState';
import { AllState } from 'src/types/AllState';
import classNames from 'classnames';
import './withMoving.styles.scss';

const withMoving = (
  Component: React.FC<any>,
  storeFieldName: PositionField
) => (props: any) => {
  const dispatch = useDispatch();

  const { isAuthenticated, areWidgetsRenderable, ...positions } = useSelector(
    (state: AllState) => ({
      isAuthenticated: state.user.isAuthenticated,
      ...state.positions,
      areWidgetsRenderable: state.user.areWidgetsRenderable,
    })
  );

  const [isMoving, setMoving] = useState<boolean>(false);

  const positionChanged = useCallback(
    (x: number, y: number) =>
      positions[storeFieldName]?.x !== x || positions[storeFieldName]?.y !== y,
    [positions, storeFieldName]
  );

  const handleStop = useCallback(
    (e, { x, y }: DraggableData) => {
      if (isAuthenticated && isMoving && positionChanged(x, y)) {
        dispatch(positionsActions.setPosition({ x, y }, storeFieldName));
      }

      setTimeout(() => {
        setMoving(false);
      }, 0);
    },
    [
      setMoving,
      dispatch,
      positionChanged,
      storeFieldName,
      isMoving,
      isAuthenticated,
    ]
  );

  return (
    <Draggable
      bounds='body'
      position={positions[storeFieldName]}
      onStop={handleStop}
      disabled={!isAuthenticated}
      onDrag={() => setMoving(true)}
      defaultPosition={positions[storeFieldName]}
    >
      <div
        className={classNames(
          'can-move',
          { ['hidden']: !areWidgetsRenderable },
          storeFieldName
        )}
      >
        <Component {...props} isMoving={isMoving} />
      </div>
    </Draggable>
  );
};

export default withMoving;
