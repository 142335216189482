import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import MobileStepper from '@material-ui/core/MobileStepper';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';


const tutorialSteps = [   
    {
        label: '2. Click on the icon of National Geographic (Official)',
        imgPath:
            'https://webstars.us/img/stepper/step2_964x260.png',
    },
    {
        label: '3. Copy code "UCIEgH5vCwFLIczPSRAb2ASw" from the browser after "channel/" Paste it on the add "Channel ID" line and click "Add" ',
        imgPath:
            'https://webstars.us/img/stepper/step3_964x260.png',
    },
    
];

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        flexGrow: 1,
        '& .MuiMobileStepper-progress':{
            width:'30%',
        }
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        height: 60,
        paddingLeft: theme.spacing(4),
        backgroundColor: theme.palette.background.default,
    },
    img: {
        height: 260,
        display: 'block',
        overflow: 'hidden',
        width: '100%',
    },
}));

function HowtoAddChannel() {
    const classes = useStyles();
    const theme = useTheme();
    const [activeStep, setActiveStep] = React.useState(0);
    const maxSteps = tutorialSteps.length;

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStepChange = (step) => {
        setActiveStep(step);
    };

    return (
        
        <div className={classes.root}>
            {/* <h2 id="modal-title">
                Where to get Channel ID  from YouTube
            </h2> */}
            <MobileStepper
                variant="progress"
                steps={maxSteps}
                position="static"
                activeStep={activeStep}
                className={classes.root}
                nextButton={
                    <Button size="small" onClick={handleNext} disabled={activeStep === 1}>
                        Step2
                        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                    </Button>
                }
                backButton={
                    <Button size="small" onClick={handleBack} disabled={activeStep === 0}>                        
                        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                        Step1
                    </Button>
                }
            />


            <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={activeStep}
                onChangeIndex={handleStepChange}
                enableMouseEvents
            >
                {tutorialSteps.map((step, index) => (
                    <div key={step.label}>
                        {Math.abs(activeStep - index) <= 2 ? (
                            <img className={classes.img} src={step.imgPath} alt={step.label} />
                        ) : null}
                    </div>
                ))}
            </SwipeableViews>

        </div>
    );
}

export default HowtoAddChannel;