import * as _ from 'lodash';
import { AxiosResponse } from 'axios';
import * as types from './actionTypes';
import UserService from '../../services/user';
import { User } from '../../models';
import chromeStore from 'src/services/chromeStore';
import * as quoteActions from '../quote/actions';
import * as landscapeActions from '../landscape/actions';
import * as positionsActions from '../positions/actions';
import * as weatherActions from '../weather/actions';
import * as clockActions from '../clock/actions';
import * as channelActions from '../channels/actions';
import promotionStorage from 'src/services/promotionStore';
import PromotionService from 'src/services/promotion';
import { getPromotions } from 'src/store/promotions/actions';
import moment from 'moment';
import DB from "src/helpers/db";

export function openAuthPopup(authPopup: any)
{
  return {
    type: types.OPEN_AUTH_POPUP,
    authPopup
  }
}

export function setPreferencesLoaded() {
  return {
    type: types.PREFERENCES_LOADED,
  };
}

export function register(
  data: Partial<User>
): (dispatch: any, getState: any) => Promise<string | null> {
  return async (dispatch: any, getState: any): Promise<string | null> => {
    try {
      data['extension_code'] = await DB.setStore('extension_info', 'web_start').getItem('code') || null;
      const axiosResponse: AxiosResponse = await UserService.register(data);

      chromeStore.set('token', axiosResponse.data.token);

      await dispatch({
        type: types.LOGIN_SUCCESS,
        loggedInUser: new User(axiosResponse.data.user),
      });

      const { favoriteSites } = await promotionStorage.getPromotions();
      await PromotionService.syncFavorites(favoriteSites, axiosResponse.data.user.id);
      const result = await PromotionService.getPromotions();

      await chromeStore.set('bestOfChrome', result.data.bestOfChrome);
      await chromeStore.set('favoriteSites', result.data.favoriteSites);
      await chromeStore.set('topSocialMedias', result.data.topSocialMedias);

      await dispatch(getPromotions());

      return null;
    } catch (error : any) {
      dispatch({
        type: types.LOGIN_ERROR,
      });

      const { response } = error;
      const data = response?.data;

      const errorMessage =
        data?.message ??
        'A server error has occured while attempting to login.';

      return data?.errors ?? errorMessage;
    }
  };
}

export function login(
  data: Partial<User>
): (dispatch: any, getState: any) => Promise<string | null> {
  return async (dispatch: any, getState: any): Promise<string | null> => {
    try {
      const axiosResponse: AxiosResponse = await UserService.login(data);

      chromeStore.set('token', axiosResponse.data.token);

      await dispatch({
        type: types.LOGIN_SUCCESS,
        loggedInUser: new User(axiosResponse.data.user),
      });

      const { favoriteSites } = await promotionStorage.getPromotions();
      await PromotionService.syncFavorites(favoriteSites, axiosResponse.data.user.id);
      const result = await PromotionService.getPromotions();

      await chromeStore.set('bestOfChrome', result.data.bestOfChrome);
      await chromeStore.set('favoriteSites', result.data.favoriteSites);
      await chromeStore.set('topSocialMedias', result.data.topSocialMedias);

      await dispatch(getPromotions());

      await dispatch(getUserPreferences());

      return null;
    } catch (error : any) {
      dispatch({
        type: types.LOGIN_ERROR,
      });

      const { response } = error;
      const data = response?.data;

      const errorMessage =
        data?.message ??
        (response?.status === 401
          ? 'Incorrect email/password combination.'
          : 'A server error has occured while attempting to login.');

      return data?.errors ?? errorMessage;
    }
  };
}

export function logout(): (dispatch: any, getState: any) => Promise<any> {
  return async (dispatch: any, getState: any): Promise<any> => {
    chromeStore.remove('token');
    await dispatch({ type: types.LOGOUT_USER });
  };
}

export function socialAuth(
  access_token: string,
  socialType: 'google' | 'facebook',
  appType: 'extension' | 'web' = 'extension'
): (dispatch: any, getState: any) => Promise<any> {
  return async (dispatch: any, getState: any): Promise<any> => {
    try {
      const data = {extension_code: await DB.setStore('extension_info', 'web_start').getItem('code') || null, app_type: appType};
      const authFunc =
        socialType === 'google'
          ? UserService.googleAuth
          : UserService.facebookAuth;

      const axiosResponse: AxiosResponse = await authFunc(access_token, data);

      chromeStore.set('token', axiosResponse.data.token);

      await dispatch({
        type: types.LOGIN_SUCCESS,
        loggedInUser: new User(axiosResponse.data.user),
      });

      const { favoriteSites } = await promotionStorage.getPromotions();
      await PromotionService.syncFavorites(favoriteSites, axiosResponse.data.user.id);
      const result = await PromotionService.getPromotions();

      await chromeStore.set('bestOfChrome', result.data.bestOfChrome);
      await chromeStore.set('favoriteSites', result.data.favoriteSites);
      await chromeStore.set('topSocialMedias', result.data.topSocialMedias);

      await dispatch(getPromotions());
      
      await dispatch(getUserPreferences());

      return null;
    } catch (e : any) {
      dispatch({
        type: types.LOGIN_ERROR,
      });

      return (
        e?.response?.data?.message ??
        'A server error has occured while attempting to login.'
      );
    }
  };
}

export function getUserData(): (dispatch: any, getState: any) => Promise<void> {
  return async (dispatch: any, getState: any): Promise<void> => {
    try {
      const axiosResponse: AxiosResponse = await UserService.getUserData();

      await dispatch({
        type: types.LOGIN_SUCCESS,
        loggedInUser: new User(axiosResponse.data.user),
      });

      await dispatch(getUserPreferences());
    } catch (error) {
      console.log(error);
    }
  };
}

export function getCalendarEvents(
  startDate: string,
  endDate: string
): (dispatch: any, getState: any) => Promise<void> {
  return async (dispatch: any, getState: any): Promise<void> => {
    try {
      const { data }: AxiosResponse = await UserService.getCalendarEvents(
        startDate,
        endDate
      );

      if (data.events) {
        await dispatch({
          type: types.SET_CALENDAR_EVENTS,
          payload: data.events,
        });
      }

      // await dispatch(getUserPreferences());
    } catch (error) {
      console.log(error);
    }
  };
}

export function getCalendarEventDates(
  startDate: string,
  endDate: string
): (dispatch: any, getState: any) => Promise<void> {
  return async (dispatch: any, getState: any): Promise<void> => {
    try {
      const { data }: AxiosResponse = await UserService.getCalendarEventDates(
        startDate,
        endDate
      );

      if (data.dates) {
        const arrDate = data.dates.map((item: any) => {
          return item.start_date;
        });
        await dispatch({
          type: types.GET_CALENDAR_EVENT_DATES,
          payload: arrDate,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
}

export function cleanAddCalendarEvent() {
  return {
    type: types.CLEAN_ADD_CALENDAR_EVENT,
  }
}

export function createCalendarEvents(
  startDate: string,
  dueDate: string,
  title: string,
  description: string
): (dispatch: any, getState: any) => Promise<void> {
  return async (dispatch: any, getState: any): Promise<void> => {
    try {
      const { data } = await UserService.createCalendarEvents(
        startDate,
        dueDate,
        title,
        description
      );

      if (data) {
        await dispatch({
          type: types.ADD_CALENDAR_EVENT_SUCCESS,
          payload: {...data, responseTime: moment()},
        });
      }
    } catch (error : any) {
      
      console.log(error);
      if (error.response?.data.status == 'error') {
        await dispatch({
          type: types.ADD_CALENDAR_EVENT_INVALID,
          payload: {...error.response?.data?.errors, responseTime: moment()},
        });
      }
    }
  };
}

export function patchCalendarEvents(
  id: number,
  dataPatch: any
): (dispatch: any, getState: any) => Promise<void> {
  return async (dispatch: any, getState: any): Promise<void> => {
    try {
      await UserService.patchCalendarEvents(id, dataPatch);
      const { user } = getState();
      const { calendarEvents } = user;
      const curEvent = calendarEvents.find((x: any) => x.id == id);
      Object.keys(dataPatch).map((key) => {
        curEvent[key] = dataPatch[key];
      });
      await dispatch({
        type: types.SET_CALENDAR_EVENTS,
        payload: calendarEvents,
      });
    } catch (error) {
      console.log(error);
    }
  };
}

export function deleteCalendarEvents(
  id: number
): (dispatch: any, getState: any) => Promise<void> {
  return async (dispatch: any, getState: any): Promise<void> => {
    try {
      await UserService.deleteCalendarEvents(id);
      const { user } = getState();
      const { calendarEvents } = user;
      const curEvent = calendarEvents.filter((x: any) => x.id != id);
      await dispatch({
        type: types.SET_CALENDAR_EVENTS,
        payload: curEvent,
      });
    } catch (error) {
      console.log(error);
    }
  };
}

export function updateCalendarEvents(
  id: number,
  startDate: string,
  dueDate: string,
  title: string,
  description: string
): (dispatch: any, getState: any) => Promise<void> {
  return async (dispatch: any, getState: any): Promise<void> => {
    try {
      const { data } = await UserService.updateCalendarEvents(id, startDate, dueDate, title, description);

      if (data) {
        await dispatch({
          type: types.UPDATE_CALENDAR_EVENT_SUCCESS,
          payload: {...data, responseTime: moment()},
        });
      }
    } catch (error : any ) {
      console.log(error);
      if (error.response?.data.status == 'error') {
        await dispatch({
          type: types.ADD_CALENDAR_EVENT_INVALID,
          payload: {...error.response?.data?.errors, responseTime: moment()},
        });
      }
    }
  };
}

export function getUserPreferences(): (
  dispatch: any,
  getState: any
) => Promise<void> {
  return async (dispatch: any, getState: any): Promise<void> => {
    try {
      const { data }: AxiosResponse = await UserService.getUserPreferences();

      if (data?.preferences) {
        const { preferences } = data;

        await dispatch(quoteActions.updateQuoteOptions(preferences.quote));

        if (preferences.clock) {
          await dispatch(clockActions.updateClockOptions(preferences.clock));
        }

        if (preferences.positions) {
          await dispatch(
            positionsActions.updatePositions(preferences.positions)
          );
        }

        if (preferences.channel) {
          if (typeof(preferences.channel) == 'string') {
            preferences.channel = JSON.parse(preferences.channel);
          }
          await dispatch(
            channelActions.updateChannelOptions(preferences.channel)
          );
        }

        if (preferences.weather) {
          await dispatch(
            weatherActions.updateWeatherOptions(preferences.weather)
          );
        }

        if (preferences.landscape) {
          if (preferences.landscape.isRandom) {
            delete preferences.landscape.bgImage;
          }

          await dispatch(
            landscapeActions.updateLandscapeOptions(preferences.landscape)
          );

          if (preferences.landscape.isRandom) {
            await dispatch(landscapeActions.getRandomDailyImage());
          }
        } else {
          await dispatch(landscapeActions.getRandomDailyImage());
        }
      }

      await dispatch({
        type: types.PREFERENCES_LOADED,
      });
    } catch (error) {
      console.log(error);
    }
  };
}
