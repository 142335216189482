import React from 'react';
import Modal from 'src/components/containers/Modal/Modal';
import HowtoAddChannel from './howto';
import './popupIntruction.scss';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

interface Props {
  isPopupInstruction: boolean, 
  setIsPopupInstruction: (isPopupInstruction) => void
}

const PopupInstruction: React.FC<Props> = ({
  isPopupInstruction,
  setIsPopupInstruction
}) => {
  
  const handleClose = () => {
    setIsPopupInstruction(false);
  }
  return (
    <>
    <Modal useBackdrop open={isPopupInstruction} handleClose={handleClose} paperClassName='popup-intruction'
    aria-labelledby="modal-title">  
    <span id="modal-title">Where to get Channel ID  from YouTube</span>
      <HowtoAddChannel />
    </Modal>
    </>
  );
}

export default PopupInstruction;