import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as luxon from 'luxon';
import {
  Box,
  IconButton,
  Tooltip,
  Tabs,
  Tab,
  makeStyles,
  Checkbox,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Collapse,
  Typography,
} from '@material-ui/core';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import EventNoteIcon from '@material-ui/icons/EventNote';
import GetAppIcon from '@material-ui/icons/GetApp';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import Modal from 'src/components/containers/Modal/Modal';
import withoutMoving from 'src/components/hocs/withMoving/withoutMoving';
import { getCalendarEvents, logout, patchCalendarEvents, getCalendarEventDates } from 'src/store/user/actions';
import { AllState } from 'src/types/AllState';
import useGoogleLogin from 'src/hooks/useGoogleLogin';
import { TabPanel, TabContext } from '@material-ui/lab';
import DateRangeIcon from '@material-ui/icons/DateRange';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import AddEventCard from './AddEventCard';
import moment from 'moment';
// import CommentIcon from '@material-ui/icons/Comment';
// import FacebookIcon from '@material-ui/icons/Facebook';
// import InstagramIcon from '@material-ui/icons/Instagram';
// import TwitterIcon from '@material-ui/icons/Twitter';
// import tiktok from 'src/assets/tiktok-3-xxl.png';
// import Instagram from 'src/assets/instagram-logo.jpeg';


const useStyles = makeStyles((theme) => ({
  tabs: {
    '& .MuiTab-textColorSecondary': {
      color: 'black',
    },
    '& .Mui-selected': {
      color: 'black',
      fontWeight: 'bold',
    },
    '& .MuiTab-labelIcon': {
      minHeight: '50px',
      '& .MuiTab-wrapper': {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        '& *:first-child': {
          marginRight: '6px',
        },
      },
    },
  },
  tabPanel: {
    '&.MuiTabPanel-root': {
      padding: '10px 0px',
    },
  },
  formContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formGroup: {
    marginTop: '20px',
    width: '100%',
  },
  formControl: {
    width: '100%',
  },
  buttonGroup: {
    marginTop: '20px',
    width: '100%',
    "& button": {
      float: 'right',
      marginLeft: '5px'
    }
  },
  rootEventCard: {
    marginTop: '10px',

  },
  contentEventCard: {
    maxHeight: '250px',
    overflow: 'auto',
    '&::-webkit-scrollbar-track': {
      borderRadius: '10px',
      border: '1px solid #ccc',
      padding: '2px 0',
      backgroundColor: 'rgba(0,0,0,0.1)',
    },
    '&::-webkit-scrollbar': {
      width: '10px',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '5px',
      boxShadow: 'inset 0 0 6px rgba(0,0,0,.6)',
      background: 'linear-gradient(left, #fff, #e4e4e4)',
      border: '3px solid transparent',
      backgroundClip:'padding-box',
    },
  },
  rootList: {
    // width: '100%',
    marginLeft: '-15px',
    marginRight: '-15px',
    maxHeight: '250px',
    overflow: 'auto',
    '&::-webkit-scrollbar-track': {
      borderRadius: '10px',
      border: '1px solid #ccc',
      padding: '2px 0',
      backgroundColor: 'rgba(0,0,0,0.1)',
    },
    '&::-webkit-scrollbar': {
      width: '10px',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '5px',
      boxShadow: 'inset 0 0 6px rgba(0,0,0,.6)',
      background: 'linear-gradient(left, #fff, #e4e4e4)',
      border: '3px solid transparent',
      backgroundClip:'padding-box',
    },
    '& .MuiCollapse-wrapper': {
      padding: '2px 15px',
    },
    '& .MuiListItem-dense' : {
      padding: '0px 15px',
    }
  },
  highlight: {
    color: 'white',
    backgroundColor: 'orange !important',
  },
  title:{
    margin: theme.spacing(3, 0, 1),
    borderBottom: '2px dashed #777',
  }
}));

const CalendarWidget: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const googleLogin = useGoogleLogin();

  const { calendarEvents: events, calendarEventDates: eventDates, loggedInUser, isCalendarOn } = useSelector(
    ({ user, landscape: { isCalendarOn } }: AllState) => ({
      ...user,
      isCalendarOn,
    })
  );

  const [openCollapse, setOpenCollapse]: [any, any] = useState('');

  const [chkEvent, setChkEvent]: [any, any] = React.useState({});
  const handleChangeChkEvent = (id: number | undefined, event: any): any => {
    event.stopPropagation();
    dispatch(patchCalendarEvents(id || 0, {
      status: event.target.checked ? 1 : 0,
      done_date: event.target.checked ? moment().format('YYYY-MM-DD HH:mm:ss') : null,
    }));
  }

  const [valueTab, setValueTab]: [string, Function] = React.useState('1');
  const [dataEvent, setDataEvent]: [any, Function] = React.useState({});
  const handleChangeTab = (event: any, newValue: string, data: any = {}) => {
    if (newValue == '2') {
      if (data && data.start_date) {
        data['start_date'] = data.start_date.replace('.000Z', '');
      }
      if (data && data.due_date) {
        data['due_date'] = data.due_date.replace('.000Z', '');
      }
      setDataEvent({...data});
    } else if (newValue == '1') {
      const start = moment(dateValue).utcOffset(0, true).format('YYYY-MM-DD');
      const end = moment(dateValue).utcOffset(0, true).format('YYYY-MM-DD');
      if (!data.action || data.action != 'delete') {
        dispatch(getCalendarEvents(start, end));
      }
      // handleUpdateCalendarEvents(new Date());
    }
    setValueTab(newValue || '1');
  };

  const [dateValue, setDateValue] = useState(new Date());
  const [isModalOpen, setModalOpen] = useState<boolean>(false);

  // useEffect(() => {
  //   let activeStartDate = new Date();
  //   handleUpdateCalendarEvents(activeStartDate);
  // }, []);

  useEffect(() => {
    if (loggedInUser) {
      const start = moment(dateValue).utcOffset(0, true).format('YYYY-MM-DD');
      const end = moment(dateValue).utcOffset(0, true).format('YYYY-MM-DD');
      dispatch(getCalendarEvents(start, end));
    }
  }, [dateValue, loggedInUser]);

  useEffect(() => {
    if (
      isModalOpen &&
      loggedInUser?.type === 'google' &&
      !loggedInUser?.hasRefresh
    ) {
      dispatch(logout());
      googleLogin(true);
    }
  }, [loggedInUser, isModalOpen]);

  // const handleChangeCalendarView = ( {activeStartDate, value, view}: any ) => {
  //   handleUpdateCalendarEvents(activeStartDate);
  // }

  // const handleUpdateCalendarEvents = (activeStartDate: Date) => {
  //   let startOfWeek = moment(activeStartDate).startOf('isoWeek').format('YYYY-MM-DD');
  //   var lastDayOfMonth = new Date(activeStartDate.getFullYear(), activeStartDate.getMonth() + 1, 0);
  //   let endOfWeek   = moment(lastDayOfMonth).endOf('isoWeek').format('YYYY-MM-DD');
  //   dispatch(getCalendarEventDates(startOfWeek, endOfWeek));
  // }

  return (
    <>
      {!chrome.identity && (<div>
        <Tooltip title="Add webstars to your chrome extension" style={{ position: "absolute", top: "14px", left: "150px", zIndex: 1 }}>
          <IconButton
            href="https://chrome.google.com/webstore/detail/webstars/gajgolblkkcdpaclnebdcgpfmpablpib?hl=en-US"  target='_blank'  
            className='Non-background'       
          >
            <GetAppIcon className='Mui-orangeIcon' /> Add Extension
          </IconButton>
        </Tooltip>
      </div>)}
      {/* <div>
        <FacebookIcon style={{ color:'blue',fontSize: 37, position: "absolute", top: "28px", left: "130px", zIndex: 1 }} />  
        <InstagramIcon style={{ color:'#C93892',fontSize: 37, position: "absolute", top: "28px", left: "170px", zIndex: 1 }} />        
        <TwitterIcon style={{ color:'#0096FF',fontSize: 37, position: "absolute", top: "28px", left: "210px", zIndex: 1 }} />     
        <img src={tiktok} style={{ color:'#0096FF',height: 28, position: "absolute", top: "28px", left: "260px", zIndex: 1 }} />   
      </div> */}
      {isCalendarOn && (
        <div>
          <Tooltip title="Open Calendar" style={{position: "absolute", top: "20px", right: "75px", zIndex: 1}}>
            <IconButton
              onClick={() => setModalOpen(true)}
              className='Mui-calendar'
            >
              <EventNoteIcon className='Mui-whiteIcon' />
            </IconButton>
          </Tooltip>
        </div>
      )}
      
      <Modal
        open={isModalOpen}
        handleClose={() => setModalOpen(false)}
        header='Calendar'
        attachedToRight
      >
        <TabContext value={valueTab}>
          <Tabs
            value={valueTab}
            onChange={handleChangeTab}
            variant='fullWidth'
            indicatorColor='secondary'
            textColor='secondary'
            aria-label='icon tabs'
            className={classes.tabs}
          >
            <Tab
              icon={<DateRangeIcon />}
              label='Calendar'
              aria-label='Calendar'
              value='1'
            />
            {loggedInUser &&
            <Tab
              icon={<PlaylistAddIcon />}
              label='Add event'
              aria-label='event'
              value='2'
            />
            }
          </Tabs>
          <TabPanel value='1' className={classes.tabPanel}>
            <Box mx={2} pb={2}>
            {/* {loggedInUser &&
            <Button
              variant='contained'
              color='primary'
              component='a'
              onClick={() => setValueTab("2")}
            >
              Add event
            </Button>
            } */}
            <Box mt={2}>
              <Calendar
                onChange={(d) => setDateValue(d as any)}
                value={dateValue}
                locale='en'
                // onActiveStartDateChange={handleChangeCalendarView}
                // tileClassName={({ date, view }) => {
                //   if(eventDates && eventDates.find((x: any) => x === moment(date).format("YYYY-MM-DD"))){
                //     return classes.highlight;
                //   }
                //   return '';
                // }}
              />
            </Box>
            <Typography variant="h6" className={classes.title} >
            Events for {
              moment(dateValue).utcOffset(0, true).format('YYYY-MM-DD') == moment().format('YYYY-MM-DD') ?
              'Today' :
              moment(dateValue).utcOffset(0, true).format('MMMM DD YYYY')
            }
            </Typography>
            <div >
            {events?.length > 0 &&
            <List className={classes.rootList}>
              {events.map((x) => {
              if (moment(x.done_date?.replace('.000Z', '')).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD')
                && x.status == 1) {
                return false;
              }
              const labelId = `checkbox-list-label-${x.id}`;
              return (<>
                <ListItem key={x.id} role={undefined} dense  >
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={x.status == 1}
                      onChange={(e) => handleChangeChkEvent(x.id, e)}
                      disableRipple
                      inputProps={{ 'aria-labelledby': labelId }}
                      style={{zIndex: 1000}}
                    />
                  </ListItemIcon>
                
       
                  <ListItemText id={labelId} primary={moment(x.start_date.replace('.000Z', '')).format("LT") + ' ' + x.title} secondary='' style={x.status == 1 ? {textDecoration: 'line-through'} : {}} onClick={(e) => handleChangeTab(e, '2', x)} />
                  <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="comments" onClick={() => setOpenCollapse(openCollapse == x.id ? '' : x.id)}>
                    {(openCollapse == x.id)?<ArrowDropUpIcon />:<ArrowDropDownIcon /> }  
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
                <Collapse in={openCollapse == x.id} timeout="auto" unmountOnExit>
                  {x.description}
                  {/* <ListItemText inset primary={x.description} /> */}
                </Collapse>
                </>)
              })}
            </List>
            }
            </div>
            </Box>
          </TabPanel>
          {loggedInUser &&
          <TabPanel value='2' >
            <AddEventCard
              handleChangeTab={handleChangeTab}
              dataEvent={dataEvent}
            />
          </TabPanel>
          }
        </TabContext>
      </Modal>
    </>
  );
};

export default withoutMoving(CalendarWidget, 'calendar');
