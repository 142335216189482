import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as _ from 'lodash';
import InputAdornment from '@material-ui/core/InputAdornment';
import Autocomplete, {
  AutocompleteRenderInputParams,
} from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import MicIcon from '@material-ui/icons/Mic';
import CloseIcon from '@material-ui/icons/Close';
import { Button, Divider, IconButton } from '@material-ui/core';
import classNames from 'classnames';
import * as searchActions from 'src/store/search/actions';
import { AllState } from 'src/types/AllState';
import SpeechRecognition, {
  useSpeechRecognition,
} from 'react-speech-recognition';
import { makeStyles } from '@material-ui/core/styles';
import { IPanel } from 'src/types/IPanel';
import { FORM_CODE, PC } from 'src/constants/bingId';

//import './search.styles.scss';

const useStyles = makeStyles((theme) => ({
  searchWrap: {
    position: 'absolute',
    right: '50%',
    transform: 'translateX(50%)',
    top: '20vh',
    width: '60vw',
    maxWidth: '60vw',
    [theme.breakpoints.up('lg')]: {
      width: '45vw',
      maxWidth: '45vw',
    },
    display: 'flex',
    gap: '15px',
    'z-index': '1',
    '&::-webkit-scrollbar-track': {
      border: '1px solid #ddd',
      padding: '2px 0',
      backgroundColor: 'rgba(245, 0, 87, .5)',
    },
    '&::-webkit-scrollbar': {
      width: '8px',
      color: 'rgba(245, 0, 87, .5)',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '0px',
      boxShadow: 'inset 0 0 6px rgba(0,0,0,.3)',
      backgroundColor: '#f50057',
      border: '1px solid #ddd',
    },
  },
}));

const SearchWidget: React.FC = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { loggedInUser, voiceSearchLang, namePanel } = useSelector(
    ({ user, landscape, panel }: AllState) => ({
      ...user,
      ...landscape,
      namePanel: panel.name,
    })
  );

  const [value, setValue] = useState<string>('');
  const [options, setOptions] = useState<string[]>([]);
  const [fontSize, setFontSize] = useState('1em');

  const { transcript, listening } = useSpeechRecognition();

  const openInNewTab = (url:string) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  const handleSearch = useCallback(async () => {
    const encodedVal = encodeURIComponent(value);

    if (value.trim().length > 0) {
      await dispatch(searchActions.search(encodedVal));
      await dispatch(searchActions.getUserSearchInfo());
      // if (chrome?.tabs) {
      //   chrome?.tabs?.create({
      //     url: `https://search.yahoo.com/search?p=${encodedVal}`,
      //   });
      // }else{
      //   openInNewTab(`https://search.yahoo.com/search?p=${encodedVal}`);
      // }
      if (chrome?.tabs) {
        chrome?.tabs?.create({
          url: `https://www.bing.com/search?FORM=${FORM_CODE}&PC=${PC}&q=${encodedVal}`,
        });
      }else{
        openInNewTab(`https://www.bing.com/search?FORM=${FORM_CODE}&PC=${PC}&q=${encodedVal}`);
      }
    }
  }, [value, loggedInUser]);

  const getSearchSuggestions = useCallback(
    async (val: string) => {
      if (chrome?.tabs) {
        const suggestions = await searchActions.getSuggestions(val);
        setOptions(suggestions);
        //console.log(suggestions);
      }
    },
    [setOptions]
  );

  const debouncedGetSuggestions = useMemo(
    () =>
      _.debounce((arg) => {
        getSearchSuggestions(arg);
      }, 200),
    [getSearchSuggestions]
  );
  //Deanguyen - change to bing suggestion
  useEffect(() => {
    if (value.trim().length > 0) {
      debouncedGetSuggestions(value);
    }
  }, [value]);

  const handleVoiceSearch = useCallback(() => {
    if (SpeechRecognition.browserSupportsSpeechRecognition()) {
      SpeechRecognition.startListening({
        language: !!voiceSearchLang ? voiceSearchLang : 'en-US',
      });
    }
  }, [SpeechRecognition, voiceSearchLang]);

  useEffect(() => {
    if (transcript !== '') {
      setValue(transcript);
    }
  }, [transcript]);

  const toggleFontSize = useCallback(() => {
    const allowedFontSizes = ['0.75em', '1em', '1.25em', '1.5em'];

    for (let i = 0; i < allowedFontSizes.length; i++) {
      if (fontSize === allowedFontSizes[i]) {
        setFontSize(
          allowedFontSizes[i === allowedFontSizes.length - 1 ? 0 : i + 1]
        );
        break;
      }
    }
  }, [setFontSize, fontSize]);

  const handleRenderInput = useCallback(
    (params: AutocompleteRenderInputParams) => (
      <TextField
        {...params}
        fullWidth
        className='Mui-searchInput'
        variant='outlined'
        margin='normal'
        multiline={true}
        placeholder='Powered by Bing'
        onChange={(e) => setValue(e.target.value)}
        onKeyPress={(ev) => {
          if (ev.key === 'Enter') {
            ev.preventDefault();
            handleSearch();
          }
        }}
        InputProps={{
          ...params.InputProps,
          style: { fontSize },
          startAdornment: (
            <InputAdornment position='start' onClick={() => toggleFontSize()}>
              <ZoomInIcon className='Mui-searchIcon' />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position='end'>
              {value.trim().length > 0 && (
                <>
                  <IconButton onClick={() => setValue('')} size='small'>
                    <CloseIcon />
                  </IconButton>
                  <Divider
                    orientation='vertical'
                    className='Mui-searchAdornmentDivider'
                  />
                </>
              )}
              <IconButton
                onClick={handleVoiceSearch}
                color='primary'
                size='small'
                className={classNames('Mui-mic', {
                  'Mui-activeMic': listening,
                })}
              >
                <MicIcon />
              </IconButton>
            </InputAdornment>
          ),
          classes: {
            root: classNames('Mui-searchInput', {
              'Mui-hasValue': value.length > 0,
            }),
          },
        }}
      />
    ),
    [
      handleSearch,
      handleVoiceSearch,
      toggleFontSize,
      setValue,
      value,
      listening,
    ]
  );

  return (
    <>
    {namePanel ==='' && <div className={classes.searchWrap}>
      <Autocomplete
        freeSolo
        openOnFocus
        value={value}
        options={options}
        className='Mui-search'
        renderInput={handleRenderInput}
        onChange={(e, val) => setValue(val ?? '')}
      />
      <Button color='primary' variant='contained' onClick={handleSearch}>
        Go
      </Button>
    </div>}
    </>
  );
};

export default SearchWidget;
