import chromeStore from './chromeStore';

class PromotionStorageService {
  public async getPromotions(): Promise<any> {
    return {
      bestOfChrome: await chromeStore.get('bestOfChrome') || [],
      favoriteSites: await chromeStore.get('favoriteSites') || [],
      topSocialMedias: await chromeStore.get('topSocialMedias') || [],
    };
  }

  public async getFavorites(): Promise<any> {
    return await chromeStore.get('favoriteSites');
  }

  public async createFavorite(favorite: any): Promise<any> {

    let favorites = await this.getFavorites();
    if (!favorites) {
      favorites = [];
    }
    favorites.push(favorite);
    return await chromeStore.set('favoriteSites', favorites);
  }

  public async updateFavorite(favorite: any): Promise<any> {
    let favorites = await this.getFavorites();
    if (favorites) {
      const current = favorites.find((item: any) => item.id == favorite.id);
      if (current) {
        Object.keys(current).forEach((key) => {
          if (favorite[key]) {
            current[key] = favorite[key];
          }
        });
        current['updatedAt'] = (new Date()).toISOString();
      }
    }
    return await chromeStore.set('favoriteSites', favorites);
  }

  public async deleteFavorite(id: string): Promise<any> {
    let favorites = await this.getFavorites();
    if (favorites) {
      favorites = favorites.filter((item: any) => item.id != id);
    }
    return await chromeStore.set('favoriteSites', favorites);
  }

  // public async reorderFavorites(
  //   data: { rowIndex: number; id: string | null }[]
  // ): Promise<any> {
  //   return await api({
  //     method: 'post',
  //     url: `/api/promotion/reorder-favorites`,
  //     data,
  //   });
  // }
}

export default new PromotionStorageService();
