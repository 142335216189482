import * as types from './actionTypes';
import WeatherService from '../../services/weather';
import UserService from '../../services/user';
import { City, IWeatherStoreState } from 'src/types/WeatherStoreState';
import { Weather } from 'src/types/Weather';
import { AllState } from 'src/types/AllState';

export function updateWeatherOptions(
  options: Partial<IWeatherStoreState>
): (dispatch: any, getState: any) => Promise<void> {
  return async (dispatch: any, getState: any): Promise<void> => {
    try {
      if (!!options) {
        await dispatch({
          type: types.UPDATE_WEATHER_OPTIONS,
          payload: options,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };
}

export function getWeather(
  city: City
): (dispatch: any, getState: any) => Promise<void> {
  return async (dispatch: any, getState: any): Promise<void> => {
    try {
      const { data } = await WeatherService.getWeather(
        city.name === 'Local' ? null : city
      );

      if (data.weather) {
        await dispatch({
          type: types.GET_WEATHER,
          weather: data.weather as Weather,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };
}

export function getCities(
  p?: string
): (dispatch: any, getState: any) => Promise<void> {
  return async (dispatch: any, getState: any): Promise<void> => {
    try {
      const { data } = await WeatherService.getCities(p);

      if (data.data) {
        dispatch({
          type: types.GET_CITIES,
          payload: data.data as City[],
        });
      }
    } catch (error) {
      console.error(error);
    }
  };
}

export function updateSelectedLocation(
  selectedLocation: City
): (dispatch: any, getState: any) => Promise<void> {
  return async (dispatch: any, getState: any): Promise<void> => {
    try {
      await dispatch({
        type: types.UPDATE_SELECTED_LOCATION,
        payload: selectedLocation,
      });

      const {
        weather: { isOn, weatherDergeeUnit },
        user: { isAuthenticated },
      } = getState();

      if (isAuthenticated) {
        await UserService.postUserPreference(
          { isOn, weatherDergeeUnit, selectedLocation },
          'weather'
        );
      }
    } catch (error) {
      console.error(error);
    }
  };
}

export function changeDegreeUnit(
  weatherDergeeUnit: string
): (dispatch: any, getState: any) => Promise<void> {
  return async (dispatch: any, getState: any): Promise<void> => {
    try {
      await dispatch({
        type: types.UPDATE_WEATHER_OPTIONS,
        payload: { weatherDergeeUnit },
      });

      const {
        weather: { isOn, selectedLocation },
        user: { isAuthenticated },
      } = getState();

      if (isAuthenticated) {
        await UserService.postUserPreference(
          { isOn, weatherDergeeUnit, selectedLocation },
          'weather'
        );
      }
    } catch (error) {
      console.error(error);
    }
  };
}

export function switchIsOn(
  isOn: boolean
): (dispatch: any, getState: any) => Promise<void> {
  return async (dispatch: any, getState: any): Promise<void> => {
    try {
      dispatch({
        type: types.UPDATE_WEATHER_OPTIONS,
        payload: { isOn },
      });

      const {
        weather: { selectedLocation, weatherDergeeUnit },
        user: { isAuthenticated },
      }: AllState = getState();

      if (isAuthenticated) {
        UserService.postUserPreference(
          { selectedLocation, weatherDergeeUnit, isOn },
          'weather'
        );
      }
    } catch (error) {
      console.error(error);
    }
  };
}
