import React, { useCallback, useState } from 'react';
import { makeStyles, Tooltip, IconButton } from '@material-ui/core';
import SpeedTestIcon from 'src/assets/speed-test-icon.png';
import { useDispatch, useSelector } from 'react-redux';
import { AllState } from 'src/types/AllState';
import classNames from 'classnames';
import withoutMoving from 'src/components/hocs/withMoving/withoutMoving';
import speedometer from 'src/assets/white/speedometer-icon.png';
import SlidingPane from "react-sliding-pane";
import { openPanel } from 'src/store/panel/actions';
import { IPanel } from 'src/types/IPanel';

const PANEL_NAME = 'speedtest';

const useStyles = makeStyles(({ components }: any) => ({
  root: {
    position: 'relative',
    width: 'fit-content',
    // padding: '10px',
    cursor: 'pointer',
    ...components.widget,
    '&:hover': {
      ...components.widgetOnHover,
    },
    '&:after': {
      position: 'absolute',
      content: 'close-quote',
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
    },
  },
  image: {
    width: '48px',
  },
  dark: {
    filter: 'grayscale(1) brightness(45%)',
  },
}));

interface Props {
  isMoving: boolean;
  panel: IPanel;
}

const SpeedTestWidget: React.FC<Props> = ({ isMoving }) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const { isSpeedTestOn, isSpeedTestDark } = useSelector(
    ({ landscape }: AllState) => landscape
  );

  const { name } = useSelector(
    ({ panel }: AllState) => panel
  );

  //const  s_style  = isSpeedTestDark??'w';
  const handleOpen = () => {
    if (name === PANEL_NAME) {
      dispatch(openPanel(''));
    } else {
      dispatch(openPanel(PANEL_NAME));
    }
  }

  return (
    <>
    {isSpeedTestOn &&
    <>
    <div className='can-hide'>
      <Tooltip title="Speed Test">
        <div>
          <IconButton onClick={handleOpen}>
            <img src={speedometer} style={{width: "30px"}} />
          </IconButton>
        </div>
      </Tooltip>
    </div>
    
    <SlidingPane
        className="ce-sliding-panel"
        overlayClassName="ce-overlay-sliding-panel"
        isOpen={name === PANEL_NAME}
        from="bottom"
        width="100%"
        hideHeader
        shouldCloseOnEsc  
        closeIcon={<div>&times;</div>}
        onRequestClose={handleOpen}
      >
        <iframe 
          style={{width: "100%", height: "100%", border: "0px"}}
          src={`https://www.metercustom.net/plugin/?hl=en&th=${isSpeedTestDark?'':'w'}`}>
        </iframe>
      </SlidingPane>
      </>
    }
    </>
  );
};

export default withoutMoving(SpeedTestWidget, 'speedTest');
