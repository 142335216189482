import SearchService from '../../services/search';
import * as types from './actionTypes';

export async function getSuggestions(value: string): Promise<string[]> {
  return new Promise((resolve) => {
    try {
      chrome?.runtime?.sendMessage(
        {
          messageType: 'getSearchSuggestions',
          value,
        },
        (res) => resolve(res.success ? (res.searchResults as string[]) : [])
      );
    } catch (err) {
      console.log(err);
      return [];
    }
  });
}

//Not used, because otherwise the history is recorded twice.
export function search(
  value: string
): (dispatch: any, getState: any) => Promise<void> {
  return async (dispatch: any, getState: any): Promise<void> => {
    try {
      await SearchService.search(value);
    } catch (error) {
      console.error(error);
    }
  };
}

export function getUserSearchInfo(): (
  dispatch: any,
  getState: any
) => Promise<void> {
  return async (dispatch: any, getState: any): Promise<void> => {
    try {
      const { data } = await SearchService.getUserSearchInfo();      
      await dispatch({
        type: types.SET_SEARCH_DATA,
        payload: { count: parseInt(data.count), searches: data.rows },
      });
    } catch (error) {
      console.error(error);
    }
  };
}
