import * as types from './actionTypes';
import LandscapeService from '../../services/landscape';
import UserService from '../../services/user';
import { AllState } from 'src/types/AllState';
import { ILandscapeStoreState } from 'src/types/LandscapeStoreState';

export function setTestBackgroundImage(image: string) {
  return {
    type: types.SET_TEST_BACKGROUND_IMAGE,
    payload: image,
  };
}

export function clearTestBackgroundImage() {
  return {
    type: types.CLEAR_TEST_BACKGROUND_IMAGE,
  };
}

export function updateLandscapeOptions(options: Partial<ILandscapeStoreState>) {
  return {
    type: types.UPDATE_LANDSCAPE_OPTIONS,
    payload: options,
  };
}

export function setBackgroundImage(
  image: string,
  type: string
): (dispatch: any, getState: any) => Promise<void> {
  return async (dispatch: any, getState: any): Promise<void> => {
    try {
      await dispatch({
        type: types.SET_BACKGROUND_IMAGE,
        payload: image,
      });

      await postLandscapePreferences(getState(), {
        bgImage: image,
        isRandom: false,
        isBgImageOn: true,
      }, type);
    } catch (error) {
      console.error(error);
    }
  };
}

export function setBackgroundColor(
  color: string
): (dispatch: any, getState: any) => Promise<void> {
  return async (dispatch: any, getState: any): Promise<void> => {
    try {
      const payload = {
        bgColor: color,
        isRandom: false,
        isBgImageOn: false,
        bgImage: '',
      };

      await dispatch({
        type: types.UPDATE_LANDSCAPE_OPTIONS,
        payload,
      });

      await postLandscapePreferences(getState(), payload);
    } catch (error) {
      console.error(error);
    }
  };
}

export function getRandomDailyImage(): (
  dispatch: any,
  getState: any
) => Promise<void> {
  return async (dispatch: any, getState: any): Promise<void> => {
    try {
      const image = await LandscapeService.getRandomDailyImage();

      await dispatch({
        type: types.GET_DAILY_IMAGE,
        image,
      });

      await postLandscapePreferences(getState());
    } catch (error) {
      console.error(error);
    }
  };
}

export function getImagesGallery(): (
  dispatch: any,
  getState: any
) => Promise<void> {
  return async (dispatch: any, getState: any): Promise<void> => {
    try {
      const gallery = await LandscapeService.getImagesGallery();

      dispatch({
        type: types.GET_IMAGES_GALLERY,
        gallery,
      });
    } catch (error) {
      console.error(error);
    }
  };
}

export function getMyImagesGallery(): (
  dispatch: any,
  getState: any
) => Promise<void> {
  return async (dispatch: any, getState: any): Promise<void> => {
    try {
      const my_gallery = await LandscapeService.getMyImagesGallery();

      dispatch({
        type: types.GET_MY_IMAGES_GALLERY,
        my_gallery,
      });
    } catch (error) {
      console.error(error);
    }
  };
}

export function updateLandscapeOption(
  field: string,
  val: any
): (dispatch: any, getState: any) => Promise<void> {
  return async (dispatch: any, getState: any): Promise<void> => {
    try {
      await dispatch({
        type: types.UPDATE_LANDSCAPE_OPTIONS,
        payload: { [field]: val },
      });

      await postLandscapePreferences(getState(), { [field]: val });
    } catch (error) {
      console.error(error);
    }
  };
}

export async function postLandscapePreferences(
  state: AllState,
  extra: any = {},
  type: string = ''
): Promise<void> {
  try {
    const {
      landscape: { testBgImage, gallery, my_gallery, ...info },
      user: { isAuthenticated },
    } = state;

    const preferences = {
      ...info,
      ...extra,
    };

    if (isAuthenticated) {
      if (type && type == 'local') {
        const { data } = await LandscapeService.uploadBase64Background({ base64image: preferences.bgImage });
        preferences.bgImage = process.env.REACT_APP_API_BASE_URL + '/user-background/' + data.data?.fileName;
      }

      await UserService.postUserPreference(preferences);
    }

    const {
      bgColor,
      bgImage,
      isRandom,
      isBgImageOn,
    }: ILandscapeStoreState = preferences;

    await UserService.postLandscapePreferencesToChromeStorage({
      bgColor,
      bgImage,
      isRandom,
      isBgImageOn,
    });
  } catch (err) {
    console.log(err);
  }
}

export function getLandscapePreferencesFromChromeStorage(): (
  dispatch: any,
  getState: any
) => Promise<void> {
  return async (dispatch: any, getState: any): Promise<void> => {
    try {
      const {
        user: { isAuthenticated },
      } = getState();

      if (!isAuthenticated) {
        const payload = await UserService.getLandscapePreferencesFromChromeStorage();
        if (payload) {
          await dispatch({
            type: types.UPDATE_LANDSCAPE_OPTIONS,
            payload,
          });
        } else {
          await dispatch(getRandomDailyImage());
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
}
