import Immutable from 'seamless-immutable';
import * as types from './actionTypes';
import { IExtension } from 'src/types/IExtension';
import * as _ from 'lodash';

const emptyState: IExtension = {
  code: '',
  pid: '',
}

const initialState = Immutable(_.cloneDeep(emptyState));

export default function reduce(state = initialState, action: any = {}): any {
  switch (action.type) {
    case types.GET_EXTENSION_INFO:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
