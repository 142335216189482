import TodoListService from '../../services/todoList';
import { AllState } from 'src/types/AllState';
import * as types from './actionTypes';
import moment from 'moment';

export function getTodoLists(localDate: string): (
  dispatch: any,
  getState: any
) => Promise<void> {
  return async (dispatch: any, getState: () => AllState): Promise<void> => {
    try {
      dispatch({
        type: types.SET_WAITING,
        payload: true,
      });

      const { data } = await TodoListService.getTodoLists(localDate);

      await dispatch({
        type: types.GET_TODO_SUCCESS,
        payload: data,
      });
    } catch (error) {
      console.error(error);
    } finally {
      dispatch({
        type: types.SET_WAITING,
        payload: false,
      });
    }
  };
}

export function createTodoList(
  description: string
): (dispatch: any, getState: any) => Promise<void> {
  return async (dispatch: any, getState: any): Promise<void> => {
    try {
      const { data } = await TodoListService.createTodoList(
        description
      );

      if (data.data) {
        const { todoLists } = getState();
        const newTodoLists = { ...todoLists };
        newTodoLists.todos.unshift(data.data);
        await dispatch({
          type: types.ADD_TODO_SUCCESS,
          payload: {...newTodoLists, responseTime: moment()},
        });
      }
    } catch (error : any) {
      console.log(error);
      if (error.response?.data.status == 'error') {
        await dispatch({
          type: types.ADD_TODO_FAIL,
          payload: {...error.response?.data?.errors, responseTime: moment()},
        });
      }
    }
  };
}

export function patchTodoList(
  id: number,
  dataPatch: any
): (dispatch: any, getState: any) => Promise<void> {
  return async (dispatch: any, getState: any): Promise<void> => {
    try {
      await TodoListService.patchTodoList(id, dataPatch);
      const { todoLists } = getState();
      const { todos } = todoLists;
      const curTodo = todos.find((x: any) => x.id == id);
      Object.keys(dataPatch).map((key) => {
        curTodo[key] = dataPatch[key];
      });
      await dispatch({
        type: types.GET_TODO_SUCCESS,
        payload: todos,
      });
    } catch (error) {
      console.log(error);
    }
  };
}

export function clearTodoErrors() {
  return {
    type: types.CLEAR_TODO_ERRORS,
  }
}