import localForage from "localforage";

class DB {
    private static instance: DB | null = null;
    store: any = null;
    public static getInstance() {
        if (!this.instance) {
            localForage.config({
                driver      : localForage.INDEXEDDB,
                name        : 'WebStart',
                version     : 3.0
            });
            this.instance = new DB();
        }
        return this.instance; 
    }
    setStore(storeName: string, dbName: string) {
        this.store = localForage.createInstance({
            name: dbName,
            storeName: storeName
        });
        return this;
    }
    setItem(key: string, value: any) {
        this.store.setItem(key, value);
        return {key: value};
    }
    getItem(key: string) {
        return this.store.getItem(key);
    }
    getKeys() {
        return this.store.keys();
    }
    removeItem(key: string) {
        return this.store.removeItem(key);
    }
}

export default DB.getInstance();