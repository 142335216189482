export type UserPreferenceType =
  | 'landscape'
  | 'quote'
  | 'positions'
  | 'weather'
  | 'clock'
  | 'channel';

export enum UserPreferenceTypes {
  'landscape' = 'landscape',
  'quote' = 'quote',
  'positions' = 'positions',
  'weather' = 'weather',
  'clock' = 'clock',
  'channel' = 'channel',
}
