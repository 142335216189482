import { AxiosResponse } from 'axios';
import api from './api';

class PromotionService {
  public async getPromotions(): Promise<AxiosResponse> {
    return await api({
      method: 'get',
      url: '/api/all-promotions',
    });
  }

  public async createPromotion(promotion: any): Promise<AxiosResponse> {
    return await api({
      method: 'post',
      url: '/api/promotion',
      data: promotion,
    });
  }

  public async updatePromotion(promotion: any): Promise<AxiosResponse> {
    return await api({
      method: 'put',
      url: `/api/promotion/${promotion.id}`,
      data: promotion,
    });
  }

  public async deletePromotion(id: string): Promise<AxiosResponse> {
    return await api({
      method: 'delete',
      url: `/api/promotion/${id}`,
    });
  }

  public async reorderFavorites(
    data: { rowIndex: number; id: string | null }[]
  ): Promise<AxiosResponse> {
    return await api({
      method: 'post',
      url: `/api/promotion/reorder-favorites`,
      data,
    });
  }

  public async syncFavorites(favorites: any, userId: any): Promise<AxiosResponse> {
    return await api({
      method: 'post',
      url: '/api/sync-favorites',
      data: {data: favorites, userId: userId},
    });
  }
}

export default new PromotionService();
