import Immutable from 'seamless-immutable';
import * as types from './actionTypes';
import { ILandscapeStoreState } from '../../types/LandscapeStoreState';
import * as _ from 'lodash';
import bgColorPalette from 'src/constants/backgroundColorPalette';

const emptyState: ILandscapeStoreState = {
  isBgImageOn: true,
  isRandom: true,
  bgImage: '',
  testBgImage: '',
  bgColor: '#FFFFFF',
  gallery: [],
  my_gallery: [],
  isSpeedTestOn: true,
  isSpeedTestDark: false,
  voiceSearchLang: '',
  isCalendarOn: true,
};

const initialState = Immutable(_.cloneDeep(emptyState));

export default function reduce(state = initialState, action: any = {}): any {
  switch (action.type) {
    case types.GET_DAILY_IMAGE:
      return {
        ...state,
        bgImage: action.image,
        isRandom: true,
        isBgImageOn: true,
      };

    case types.SET_BACKGROUND_IMAGE:
      return {
        ...state,
        bgImage: action.payload,
        isRandom: false,
        isBgImageOn: true,
      };

    case types.SET_BACKGROUND_COLOR:
      return { ...state, bgColor: action.payload };

    case types.SET_TEST_BACKGROUND_IMAGE:
      return { ...state, testBgImage: action.payload };

    case types.CLEAR_TEST_BACKGROUND_IMAGE:
      return { ...state, testBgImage: '' };

    case types.GET_IMAGES_GALLERY:
      return { ...state, gallery: action.gallery };

    case types.GET_MY_IMAGES_GALLERY:
      return { ...state, my_gallery: action.my_gallery };

    case types.UPDATE_LANDSCAPE_OPTIONS:
      let data = action.payload;
      if (typeof(data) == 'string') {
        data = JSON.parse(data);
      }
      return { ...state, ...data };

    default:
      return state;
  }
}
