import Immutable from 'seamless-immutable';
import * as _ from 'lodash';
import * as types from './actionTypes';
import { IWeatherStoreState } from '../../types/WeatherStoreState';

const emptyState: IWeatherStoreState = {
  isOn: true,
  cities: [],
  weather: null,
  selectedLocation: null,
  weatherDergeeUnit: 'F',
};

const initialState = Immutable(_.cloneDeep(emptyState));

export default function reduce(state = initialState, action: any = {}): any {
  switch (action.type) {
    case types.GET_WEATHER:
      return { ...state, weather: action.weather };

    case types.GET_CITIES:
      return { ...state, cities: action.payload };

    case types.UPDATE_WEATHER_OPTIONS:
      return { ...state, ...action.payload };

    case types.UPDATE_SELECTED_LOCATION:
      return { ...state, selectedLocation: action.payload };

    default:
      return state;
  }
}
