import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import { ICellRendererParams, RowDragEvent } from 'ag-grid-community';
import {
  Box,
  Link,
  Divider,
  IconButton,
  makeStyles,
  Typography,
  Button,
} from '@material-ui/core';
import classNames from 'classnames';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { AllState } from 'src/types/AllState';
import PromotionForm from './PromotionForm';
import {
  deletePromotion,
  getPromotions,
  reorderFavorites,
} from 'src/store/promotions/actions';
import { Promotion } from 'src/models';
import { toast } from 'react-toastify';

const useStyles = makeStyles(() => ({
  tableWrap: {
    marginTop: '10px',
    height: '345px',
  },
  actionsDivider: {
    height: '20px',
    margin: '0 5px',
  },
  actionsWrap: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    marginTop: '5px',
  },
}));

const FavoritesTable: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { favorites, loggedInUser } = useSelector(
    ({ promotions, user }: AllState) => ({
      ...promotions,
      ...user,
    })
  );

  const [rowData, setRowData] = useState<any>(favorites);
  const [isFormOpen, setFormOpen] = useState<boolean>(false);
  const [currentPromotion, setCurrentPromotion] = useState<Promotion | null>(
    null
  );
  const [sortingIdx, setSortingIdx] = useState<null | number>(null);

  useEffect(() => {
    if (favorites) {
      setRowData(favorites);
    }
  }, [favorites]);

  const handleAddNew = useCallback(() => {
    if (favorites?.length === 150) {
      toast.error(`Can't add more than 150 favorites.`);
    } else {
      setCurrentPromotion(null);
      setFormOpen(true);
    }
  }, [setCurrentPromotion, setFormOpen]);

  const handleEdit = useCallback(
    (params: ICellRendererParams) => {
      setCurrentPromotion(
        params.context?.rowData?.find(
          (x: Promotion) => x.id === params.value
        ) ?? null
      );
      setFormOpen(true);
    },
    [setCurrentPromotion, setFormOpen]
  );

  const handleDelete = useCallback(
    async (id: string) => {
      if (await dispatch(deletePromotion(id))) {
        await dispatch(getPromotions());
        toast.success('Favorite deleted successfully!');
      }
    },
    [dispatch]
  );

  const handleRowDragEnd = useCallback(
    ({ overIndex, api }: RowDragEvent) => {
      if (overIndex !== sortingIdx && typeof sortingIdx === 'number') {
        const startIdx = Math.min(sortingIdx, overIndex);
        const endIdx = Math.max(sortingIdx, overIndex);

        const changedOrder: { rowIndex: number; id: string | null }[] = [];

        api.getRenderedNodes().forEach((x) => {
          if (
            typeof x.rowIndex === 'number' &&
            x.rowIndex >= startIdx &&
            x.rowIndex <= endIdx
          ) {
            changedOrder.push({
              rowIndex: x.rowIndex,
              id: x.data?.id ? String(x.data.id) : null,
            });
          }
        });

        dispatch(reorderFavorites(changedOrder));
      }
    },
    [sortingIdx]
  );

  const renderName = useCallback((params: ICellRendererParams) => {
    const promotion = params.context?.rowData?.find(
      (x: Promotion) => x.id === params.value
    );

    return (
      <Link
        component='a'
        target='_blank'
        href={
          promotion.url.includes('http')
            ? promotion.url
            : `http://${promotion.url}`
        }
      >
        {promotion.name}
      </Link>
    );
  }, []);

  const renderIcon = useCallback(
    (params: ICellRendererParams) =>
      params.value?.trim()?.length > 0 ? (
        <Link component='a' href={params.value.trim()} target='_blank'>
          Link
        </Link>
      ) : (
        params.value
      ),
    []
  );

  const renderActions = useCallback(
    (params: ICellRendererParams) => {
      return (
        <Box className={classes.actionsWrap}>
          <IconButton size='small' onClick={() => handleEdit(params)}>
            <EditIcon fontSize='small' />
          </IconButton>
          <Divider orientation='vertical' className={classes.actionsDivider} />
          <IconButton size='small' onClick={() => handleDelete(params.value)}>
            <DeleteIcon fontSize='small' />
          </IconButton>
        </Box>
      );
    },
    [classes, handleEdit, handleDelete]
  );

  return (
    <>
      <Box height='400px' marginX={2} marginBottom={4} textAlign='right'>
        {/* <Typography variant='h6'>Favorite websites</Typography> */}
        {!favorites || favorites.length < 151 ? (
          <Box my={1}>
            <Button variant='contained' color='primary' onClick={handleAddNew}>
              Add new
            </Button>
          </Box>
        ) : (
          <div>Maximum of 150 favorites have been added.</div>
        )}

        <div className={classNames(classes.tableWrap, 'ag-theme-alpine')}>
          <AgGridReact
            rowDragManaged={true}
            animateRows={true}
            rowData={rowData}
            context={{ rowData }}
            frameworkComponents={{
              renderIcon: renderIcon,
              renderName: renderName,
              renderActions: renderActions,
            }}
            onRowDragEnter={(e) => setSortingIdx(e.overIndex)}
            onRowDragEnd={handleRowDragEnd}
          >
            <AgGridColumn
              field='id'
              width={200}
              rowDrag={true}
              headerName='Name'
              cellRenderer='renderName'
            />
            <AgGridColumn field='rank' width={80} />

            <AgGridColumn
              field='id'
              width={100}
              headerName=''
              cellRenderer='renderActions'
            />
          </AgGridReact>
        </div>
      </Box>
      <PromotionForm
        open={isFormOpen}
        handleClose={() => setFormOpen(false)}
        user={loggedInUser}
        getPromotions={() => dispatch(getPromotions())}
        promotion={currentPromotion}
      />
    </>
  );
};

export default FavoritesTable;
