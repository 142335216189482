import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';

import {
  makeStyles,
  TextField,
  Button
} from '@material-ui/core';
import {
  createCalendarEvents,
  cleanAddCalendarEvent,
  deleteCalendarEvents,
  updateCalendarEvents
} from 'src/store/user/actions';
import { toast } from 'react-toastify';

const useStyles = makeStyles((theme) => ({
  formContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formGroup: {
    marginTop: '20px',
    width: '100%',
  },
  formControl: {
    width: '100%',
  },
  buttonGroup: {
    marginTop: '20px',
    width: '100%',
    "& button": {
      float: 'right',
      marginLeft: '5px'
    }
  }
}));

const selectedDateStart = moment().format("YYYY-MM-DDT01:00:00");
const selectedDateEnd = moment().format("YYYY-MM-DDT23:59:00");

interface Props {
  handleChangeTab: any;
  dataEvent: any;
}

interface IEvent {
  start_date?: string | undefined,
  due_date?: string | undefined,
  title?: string | undefined,
  description?: string | undefined
}

const AddEventCard: React.FC<Props> = ({ handleChangeTab, dataEvent }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [data, setData]: [any, Function] = useState(dataEvent);

  let { 
    responseAddCalendarEventSuccess: success, 
    responseUpdateCalendarEventSuccess: updateSuccess,
    responseAddCalendarEventInvalid: errors
  } = useSelector(({ user }: any) => user);

  useEffect(() => {
    if (success?.status == 'success') {
      toast.success('Event added successfully!');
      setData({...data, title: '', description: ''});
    }
  }, [success]);

  useEffect(() => {
    if (updateSuccess?.status == 'success') {
      handleChangeTab(null, '1');
      toast.success('Event updated successfully!');
    }
  }, [updateSuccess]);

  useEffect(() => {
    return function cleanup () {
      dispatch(cleanAddCalendarEvent());
      handleChangeTab(null, '1');
    }
  }, []);

  const handleChange = (event: any) => {
    const name = event.target.name;
    const value = event.target.value;
    setData({...data, [name]: value});
  }

  const handleAddEvent = () => {
    let {start_date, due_date, title, description}: IEvent = data;
    start_date = start_date ? moment(start_date).format('YYYY-MM-DD HH:mm:00') :
      moment(selectedDateStart).format('YYYY-MM-DD HH:mm:00');
    due_date = due_date ? moment(due_date).format('YYYY-MM-DD HH:mm:00') :
      moment(selectedDateEnd).format('YYYY-MM-DD HH:mm:00');
    if (data && data.id) {
      dispatch(updateCalendarEvents(data.id, start_date, due_date, title || '', description || ''));
    } else {
      dispatch(createCalendarEvents(start_date, due_date, title || '', description || ''));
    }
  }

  const handleDeleteEvent = () => {
    dispatch(deleteCalendarEvents(data.id));
    toast.success('Event deleted successfully!');
    handleChangeTab(null, '1', {action: 'delete'});
  }

  return (
    <>
      <label><b>{ (data && data.id) ? 'Edit' : 'Add' } your event</b></label>
      <form className={classes.formContainer}>
        <div className={classes.formGroup}>
          <TextField
            id="start_date"
            name="start_date"
            label="Start"
            type="datetime-local"
            className={classes.formControl}
            InputLabelProps={{
              shrink: true,
            }}
            value={data.start_date || selectedDateStart}
            onChange={handleChange}
            error={errors?.start_date ? true: false}
            helperText={errors?.start_date}
          />
        </div>
        <div className={classes.formGroup}>
          <TextField
            id="due_date"
            name="due_date"
            label="End"
            type="datetime-local"
            className={classes.formControl}
            InputLabelProps={{
              shrink: true,
            }}
            value={data.due_date || selectedDateEnd}
            onChange={handleChange}
            error={errors?.due_date ? true: false}
            helperText={errors?.due_date}
          />
        </div>
        <div className={classes.formGroup}>
          <TextField
            id="title"
            name="title"
            label="Title"
            type="text"
            className={classes.formControl}
            InputLabelProps={{
              shrink: true,
            }}
            value={data.title || ''}
            onChange={handleChange}
            onKeyPress={(ev) => {
                if (ev.key === 'Enter') {
                  ev.preventDefault();
                  handleAddEvent();
                }
              }}
            error={errors?.title ? true: false}
            helperText={errors?.title}
          />
        </div>
        <div className={classes.formGroup}>
          <TextField
            id="description"
            name="description"
            label="Description"
            multiline
            rows={2}
            rowsMax={6}
            className={classes.formControl}
            InputLabelProps={{
              shrink: true,
            }}
            value={data.description || ''}
            onChange={handleChange}
            error={errors?.description ? true: false}
            helperText={errors?.description}
          />
        </div>
        <div className={classes.buttonGroup}>
          {data && data.id &&
            <Button variant="contained" color="secondary" onClick={handleDeleteEvent}>
              Delete
            </Button>
          }
          <Button variant="contained" color="primary" onClick={handleAddEvent}>
            { (data && data.id) ? 'Edit' : 'Add' }
          </Button>
          <Button variant="contained" onClick={(e) => handleChangeTab(e, '1')}>
            Cancel
          </Button>
        </div>
      </form>
    </>
  );
};

export default AddEventCard;
