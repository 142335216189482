import { AxiosResponse } from 'axios';
import api from './api';
import queryString from 'querystring';

class ChannelService {
  
  public async getChannels(): Promise<AxiosResponse> {
    return await api({
      method: 'get',
      url: '/api/user-channels',
    });
  }

  public async addChannel(channel_id: string): Promise<AxiosResponse> {
    return await api({
      method: 'post',
      url: '/api/user-channels',
      data: { channel_id }
    });
  }

  public async deleteChannel(channel_id: string): Promise<AxiosResponse> {
    return await api({
      method: 'delete',
      url: `/api/user-channels/${channel_id}`,
    });
  }

  public async getYoutubePlaylistInfo(channel_id: number): Promise<AxiosResponse> {
    return await api({
      method: 'get',
      url: `/api/youtube-playlists/${channel_id}`,
    });
  }

  public async getYoutubeVideoInfo(playlist_id: number): Promise<AxiosResponse> {
    return await api({
      method: 'get',
      url: `/api/youtube-videos/${playlist_id}`,
    });
  }

  public async getYoutubeChannelInfo(channel_id: number): Promise<AxiosResponse> {
    return await api({
      method: 'get',
      url: `/api/youtube-channels/${channel_id}`,
    });
  }

  public async searchYoutubeVideoInfo(search: any): Promise<AxiosResponse> {
    const query = queryString.stringify(search);
    return await api({
      method: 'get',
      url: `/api/youtube-videos/search?${query}`,
    });
  }

  public async addToPlaylist(video_id: string): Promise<AxiosResponse> {
    return await api({
      method: 'post',
      url: '/api/user-playlists',
      data: { video_id }
    });
  }

  public async getUserPlaylists(): Promise<AxiosResponse> {
    return await api({
      method: 'get',
      url: '/api/user-playlists',
    });
  }

  public async deletePlaylist(video_id: string): Promise<AxiosResponse> {
    return await api({
      method: 'delete',
      url: `/api/user-playlists/${video_id}`,
    });
  }
}

export default new ChannelService();
