import React, { useCallback, useEffect } from 'react';
import { Box, Button, Grid, TextField } from '@material-ui/core';
import { useFormik, FormikHelpers, FormikErrors } from 'formik';
import { toast } from 'react-toastify';
import { object, string, ref } from 'yup';

interface FormProps {
  name: string;
  email: string;
  password: string;
  repeatPassword: string;
}

const initialValues: FormProps = {
  name: '',
  email: '',
  password: '',
  repeatPassword: '',
};

const validationSchema = object().shape({
  name: string().required('Name is required.'),
  email: string()
    .required('Email address is required.')
    .email('Email address not formed correctly.'),
  password: string()
    .required('Password is required.')
    .min(8, 'Password must be 8 or more characters.')
    .max(20, 'Password must be not more than 20 characters.'),
  repeatPassword: string()
    .required('Confirm password is required.')
    .oneOf([ref('password'), null], 'Passwords must match.'),
});

interface Props {
  register: (data: any) => Promise<string | null | FormikErrors<FormProps>>;
  shouldReset: boolean;
}

const RegisterForm: React.FC<Props> = ({ register, shouldReset }) => {
  const onSubmit = useCallback(
    async (
      { name, email, password, repeatPassword }: FormProps,
      form: FormikHelpers<FormProps>
    ) => {
      const error: string | null | FormikErrors<FormProps> = await register({
        name,
        email,
        password,
        repeatPassword,
      });

      if (error) {
        typeof error === 'string' ? toast.error(error) : form.setErrors(error);
      }else{
        
        toast.success('Successfully register and login');
        
      }
    },
    [register]
  );

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  useEffect(() => {
    if (shouldReset) {
      formik.resetForm();
    }
  }, [shouldReset]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className='modalRegisterForm'>
      <Box px={3} pt={0} pb={6}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id='name'
              name='name'
              label='Name'
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
              InputLabelProps={{ className: 'Mui-black' }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id='email'
              name='email'
              label='Email'
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              InputLabelProps={{ className: 'Mui-black' }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id='password'
              name='password'
              label='Password'
              type='password'
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
              InputLabelProps={{ className: 'Mui-black' }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id='repeatPassword'
              name='repeatPassword'
              label='Confirm password'
              type='password'
              value={formik.values.repeatPassword}
              onChange={formik.handleChange}
              error={
                formik.touched.repeatPassword &&
                Boolean(formik.errors.repeatPassword)
              }
              helperText={
                formik.touched.repeatPassword && formik.errors.repeatPassword
              }
              InputLabelProps={{ className: 'Mui-black' }}
            />
          </Grid>
        </Grid>
      </Box>
      </div>
      <Button
        fullWidth
        variant='contained'
        type='submit'
        color='primary'
        className='Mui-submitFormBtn'
      >
        Sign up
      </Button>
    </form>
  );
};

export default RegisterForm;
