import React from 'react';
import { useSelector } from 'react-redux';
import { PositionField } from 'src/types/PositionsStoreState';
import { AllState } from 'src/types/AllState';
import classNames from 'classnames';
import './withMoving.styles.scss';

const withoutMoving = (
  Component: React.FC<any>,
  storeFieldName: PositionField
) => (props: any) => {
  const { isAuthenticated, areWidgetsRenderable, ...positions } = useSelector(
    (state: AllState) => ({
      isAuthenticated: state.user.isAuthenticated,
      areWidgetsRenderable: state.user.areWidgetsRenderable,
    })
  );

  return (
    <div
      className={classNames(
        { ['hidden']: !areWidgetsRenderable },
        storeFieldName
      )}
    >
      <Component {...props} />
    </div>
  );
};

export default withoutMoving;
