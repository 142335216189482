import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import chromeStore from 'src/services/chromeStore';
import { getLandscapePreferencesFromChromeStorage } from 'src/store/landscape/actions';
import { getUserData, setPreferencesLoaded } from 'src/store/user/actions';

const useUserData = () => {
  const dispatch = useDispatch();

  const getUser = useCallback(async () => {
    if (!!(await chromeStore.get('token'))) {
      dispatch(getUserData());
    } else {
      await dispatch(getLandscapePreferencesFromChromeStorage());
      dispatch(setPreferencesLoaded());
    }
  }, [dispatch]);

  useEffect(() => {
    getUser();
  }, []);
};

export default useUserData;
