import React from 'react';
import { Button } from '@material-ui/core';
import './buttons.styles.scss';
import useGoogleLogin from 'src/hooks/useGoogleLogin';
import googleLoginIcon from 'src/assets/google_icon.svg';

const GoogleButton: React.FC = () => {
  const googleLogin = useGoogleLogin();

  return (
    <Button
      color='primary'
      variant='contained'
      className='Mui-socialBtn'
      onClick={() => googleLogin()}
    >

      <div className='iconWrapper'>
        <img
          className='googleIcon'
          src={googleLoginIcon}
        />
      </div>
      <p className='btnText'>
        <b>Google</b>
      </p>
    </Button>
  );
};

export default GoogleButton;
