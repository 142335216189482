import React, { useState } from 'react';
import {
    makeStyles,
    List,
    ListItem,
    ListItemText,
    ListItemAvatar,
    Avatar,
    ListSubheader,
    useTheme,
    IconButton,
    ListItemSecondaryAction
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import DeleteIcon from '@material-ui/icons/Delete';
import './stickyVideo.scss';
import IframeVideo from './IframeVideo';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
    rootDivTab: {
        display: 'flex',
        height: '100%',        
    },
    rootListVideo: {
        paddingTop: 0,
        overflowX: 'hidden',
        overflowY: 'auto',
        height: '430px',
        width: '100%',
        maxWidth: '320px',
        marginLeft: '20px',
        border: '1px solid #cccccce8',
        backgroundColor: 'rgba(0, 0, 0, .2)',
        '& .MuiListItem-button:hover':{
            backgroundColor: 'rgba(245, 0, 87, 0.5)',
        },
    '& .MuiListItemText-primary': {
        color: 'white',
        fontSize:'0.75rem',
    },

    '& .MuiListItemText-secondary': {
        color: '#167ac6',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        width: '150px',
        height: '1.2em',
        whiteSpace: 'nowrap',
    },

      '&::-webkit-scrollbar-track': {
        //borderRadius: '10px',
        border: '1px solid #111111e8',
        padding: '2px 0',
        backgroundColor: 'rgba(0,0,0,0.1)',
      },
      '&::-webkit-scrollbar': {
        width: '10px',
      },
      '&::-webkit-scrollbar-thumb': {
        borderRadius: '5px',
        boxShadow: 'inset 0 0 6px rgba(0,0,0,.6)',
        background: 'linear-gradient(left, #fff, #e4e4e4)',
        border: '3px solid transparent',
        backgroundClip:'padding-box',
      },
      '& .removeVideo':{
          position:'absolute',
          bottom:0,
          right:'2px',
          zIndex:'2'
      },
      '& .itemVideo':{
        minHeight:93,
      }
      
    },
    headerListVideo: {
        background: 'rgba(32, 32, 32, .9)',
        fontSize:'14pt',
        fontWeight:'bold',
        color: 'white',
    },
    inlineVideo: {
        display: 'inline',
    },
    thumbVideo: {
        width: '120px',
        height: '70px',
        cursor: 'pointer'
    }
}));

interface IProps {
    listVideo: any,
    playVideoId: string,
    isSticky: any,
    typePlaylist: string,
    closeSticky: () => void,
    handleBack?: () => void,
    handleRemoveVideo?: (videoId: string) => void,
}

type Props = IProps;

const PlaylistVideo: React.FC<Props> = ({listVideo, playVideoId, isSticky, typePlaylist, closeSticky, handleBack, handleRemoveVideo}) => {
  const classes = useStyles();
  const theme = useTheme();
  const [playVideo, setPlayVideo] = useState(playVideoId);

  React.useEffect(() => {
    setPlayVideo(playVideoId);
  }, [playVideoId]);

  const handleOpenVideo = (videoId: string) => {
    setPlayVideo(videoId);
  }

  const paddingString = (str: string , length:number , ending:string ) => {
    if (length == null) {
        length = 60;
      }
      if (ending == null) {
        ending = '...';
      }

      if (str.length > length) {
        return str.substring(0, length - ending.length) + ending;
      } 
      return str;

  }
  return (
    <div className={classes.rootDivTab}>
        <div style={{width: "100%", position: "relative"}}>
            <div className="iframe-video">
                {playVideo &&
                    <IframeVideo playVideo={playVideo} isSticky={isSticky} closeSticky={closeSticky} autoPlay={typePlaylist === 'search'}/>
                }
                {!playVideo &&
                    <iframe
                        src={`https://www.youtube.com/embed/`}
                        width="100%" height="100%"
                        allow='autoplay' style={{border:'1px solid #ebebeb88'}}
                    />
                }
            </div>
        </div>
        <List className={classes.rootListVideo}>
            <ListSubheader component="div" className={classes.headerListVideo}>
            {typePlaylist === 'my_playlist' && <div>Video</div> }
            {typePlaylist === 'search' && handleBack &&
                <IconButton
                    onClick={handleBack}
                    style={{color: 'white', float: 'left'}}
                >                    
                    <SearchIcon  />
                </IconButton>                
            }
            {typePlaylist === 'search' && <div>BACK TO SEARCH</div> }
            </ListSubheader>
            {listVideo.length > 0 && listVideo.map((v: any) => {
                const videoId = typePlaylist === 'search' ? v.id.videoId : v.id;

                return (
                    <ListItem className="itemVideo" title={v.snippet?.title} key={videoId} onClick={() => handleOpenVideo(videoId)} dense button>
                    <ListItemAvatar>
                        <Avatar
                            className={classes.thumbVideo}
                            src={v.snippet?.thumbnails?.medium.url || v.snippet?.thumbnails?.default.url}
                            variant="square"
                            
                        />
                    </ListItemAvatar>
                    <ListItemText                        
                        primary={
                            <React.Fragment>                                
                                {paddingString(v.snippet?.title, 60, '...')}
                                {typePlaylist === 'my_playlist' && handleRemoveVideo &&
                                <IconButton className="removeVideo" title="Remove video from favorite" edge="end" aria-label="delete" onClick={() => handleRemoveVideo(videoId || '')}>
                                    <DeleteIcon color="secondary" />
                                </IconButton>   
                                }            
                            </React.Fragment>
                          }
                        secondary={moment(v.snippet?.publishedAt).format("MMMM Do YYYY")} 
                        style={{marginLeft: '10px'}}
                    />                    
                    </ListItem>
                )
            })}
            {listVideo.length === 0 && 
                <p style={{ padding: '10px' }}>No videos available in your playlist</p>
            }
        </List>
    </div>
  );
};

export default PlaylistVideo;
