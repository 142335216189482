import axios from 'axios';
import { logout } from 'src/store/user/actions';
import store from 'src/store';
import chromeStore from './chromeStore';

const client = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

client.interceptors.response.use(undefined, (error) => {
  if (error?.response?.status === 401) {
    store.dispatch(logout() as any);
  }
  return Promise.reject(error);
});

client.interceptors.request.use(async (config) => {
  const token = await chromeStore.get('token');

  if (token?.token) {
    config.headers.Authorization = token.token;
  }

  return config;
});

export default client;
