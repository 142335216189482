export interface IChromeStorage {
  get: (key: string) => Promise<any>;
  set: (key: string, value: any) => void;
  remove: (key: string) => Promise<any>;
}

const chromeStore: IChromeStorage = {
  async get(key: string): Promise<any> {
    if (chrome?.storage) {
      return new Promise((resolve) => {
        chrome.storage.local.get(key, (result) => {
          resolve(result[key]);
        });
      });
    } else {
      const value = localStorage.getItem(key);

      return value ? JSON.parse(value) : null;
    }
  },
  set(key: string, value: any): void {
    if (chrome?.storage) {
      chrome.storage.local.set({ [key]: value });
    } else {
      localStorage.setItem(key, JSON.stringify(value));
    }
  },

  async remove(key: string): Promise<any> {
    if (chrome?.storage) {
      chrome.storage.local.set({ [key]: null });
    } else {
      localStorage.removeItem(key);
    }
  },
};

export default chromeStore;
