import Immutable from 'seamless-immutable';
import * as _ from 'lodash';
import * as types from './actionTypes';
import { QuoteSize } from 'src/types/Quote';
import { IQuoteStoreState } from '../../types/QuoteStoreState';

const emptyState: IQuoteStoreState = {
  quote: null,
  position: 'bottom',
  isOn: true,
  size: QuoteSize.Medium,
  color: '#fff',
};

const initialState = Immutable(_.cloneDeep(emptyState));

export default function reduce(state = initialState, action: any = {}): any {
  switch (action.type) {
    case types.GET_DAILY_QUOTE:
      return { ...state, quote: action.quote };

    case types.UPDATE_QUOTE_OPTIONS:
      return { ...state, ...action.payload };

    default:
      return state;
  }
}
