import Immutable from 'seamless-immutable';
import * as types from './actionTypes';
import { IPositionsStoreState } from 'src/types/PositionsStoreState';
import * as _ from 'lodash';

const emptyState: IPositionsStoreState = {
  homeLogo: undefined,
  speedTest: undefined,
  clock: undefined,
  weather: undefined,
  counter: undefined,
  searchHistory: undefined,
  bestOfChrome: undefined,
  favorites: undefined,
  calendar: undefined,
  todoList: undefined,
  edit: undefined,
};

const initialState = Immutable(_.cloneDeep(emptyState));

export default function reduce(state = initialState, action: any = {}): any {
  switch (action.type) {
    case types.UPDATE_POSITIONS:
      return { ...state, ...action.payload };

    default:
      return state;
  }
}
