import { ControlPosition } from 'react-draggable';
import UserService from '../../services/user';
import { AllState } from 'src/types/AllState';
import * as types from './actionTypes';
import {
  IPositionsStoreState,
  PositionField,
} from 'src/types/PositionsStoreState';
import { UserPreferenceTypes } from 'src/types/UserPreferenceType';

export function updatePositions(data: Partial<IPositionsStoreState>) {
  return {
    type: types.UPDATE_POSITIONS,
    payload: data,
  };
}

export function setPosition(
  position: ControlPosition,
  field: PositionField
): (dispatch: any, getState: any) => Promise<void> {
  return async (dispatch: any, getState: () => AllState): Promise<void> => {
    try {
      await dispatch({
        type: types.UPDATE_POSITIONS,
        payload: { [field]: position },
      });

      await UserService.postUserPreference(
        {
          ...getState().positions,
          [field]: position,
        },
        UserPreferenceTypes.positions
      );
    } catch (error) {
      console.error(error);
    }
  };
}
