export const OPEN_AUTH_POPUP = 'users.OPEN_AUTH_POPUP';
export const LOGIN_SUCCESS = 'users.LOGIN_SUCCESS';
export const LOGIN_ERROR = 'users.LOGIN_ERROR';
export const LOGOUT_USER = 'users.LOGOUT_USER';
export const PREFERENCES_LOADED = 'users.PREFERENCES_LOADED';
export const SET_CALENDAR_EVENTS = 'users.SET_CALENDAR_EVENTS';
export const ADD_CALENDAR_EVENT_SUCCESS = 'users.ADD_CALENDAR_EVENT_SUCCESS';
export const UPDATE_CALENDAR_EVENT_SUCCESS = 'users.UPDATE_CALENDAR_EVENT_SUCCESS';
export const ADD_CALENDAR_EVENT_INVALID = 'users.ADD_CALENDAR_EVENT_INVALID';
export const ADD_CALENDAR_EVENT_FAIL = 'users.ADD_CALENDAR_EVENT_FAIL';
export const CLEAN_ADD_CALENDAR_EVENT = 'users.CLEAN_ADD_CALENDAR_EVENT';
export const PATCH_CALENDAR_EVENT = 'users.PATCH_CALENDAR_EVENT';
export const GET_CALENDAR_EVENT_DATES = 'users.GET_CALENDAR_EVENT_DATES';
