import WeatherService from 'src/services/weather';
import UserService from 'src/services/user';
import { City } from 'src/types/WeatherStoreState';
import * as types from './actionTypes';
import { IClockStoreState } from 'src/types/ClockStoreState';
import { AllState } from 'src/types/AllState';

export function getCities(
  p?: string
): (dispatch: any, getState: any) => Promise<void> {
  return async (dispatch: any, getState: any): Promise<void> => {
    try {
      const { data } = await WeatherService.getCities(p);

      if (data.data) {
        dispatch({
          type: types.GET_CITIES,
          payload: data.data as City[],
        });
      }
    } catch (error) {
      console.error(error);
    }
  };
}

export function updateLocation(
  location: City
): (dispatch: any, getState: any) => Promise<void> {
  return async (dispatch: any, getState: any): Promise<void> => {
    try {
      if (location) {
        dispatch({
          type: types.UPDATE_TIME_ZONE,
          payload: location,
        });

        const {
          clock,
          user: { isAuthenticated },
        }: AllState = getState();

        if (isAuthenticated) {
          const { cities, ...preferences } = clock;
          UserService.postUserPreference(preferences, 'clock');
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
}

export function switchIsOn(
  isOn: boolean
): (dispatch: any, getState: any) => Promise<void> {
  return async (dispatch: any, getState: any): Promise<void> => {
    try {
      dispatch({
        type: types.UPDATE_OPTIONS,
        payload: { isOn },
      });

      const {
        clock,
        user: { isAuthenticated },
      }: AllState = getState();

      if (isAuthenticated) {
        const { cities, ...preferences } = { ...clock, isOn };
        UserService.postUserPreference(preferences, 'clock');
      }
    } catch (error) {
      console.error(error);
    }
  };
}

export function updateClockOptions(
  data: Partial<IClockStoreState>
): (dispatch: any, getState: any) => Promise<void> {
  return async (dispatch: any, getState: any): Promise<void> => {
    try {
      if (data) {
        dispatch({
          type: types.UPDATE_OPTIONS,
          payload: data,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };
}
