import Immutable from 'seamless-immutable';
import * as _ from 'lodash';
import * as types from './actionTypes';
import { IClockStoreState } from '../../types/ClockStoreState';
import { City } from 'src/types/WeatherStoreState';

export const defaultLocation: City = {
  name: 'Local',
  country: '',
  lat: 0,
  lng: 0,
  timeZone: 'local',
};

const emptyState: IClockStoreState = {
  cities: [],
  location: defaultLocation,
  isOn: true,
};

const initialState = Immutable(_.cloneDeep(emptyState));

export default function reduce(state = initialState, action: any = {}): any {
  switch (action.type) {
    case types.GET_CITIES:
      return { ...state, cities: action.payload };

    case types.UPDATE_TIME_ZONE:
      return { ...state, location: action.payload };

    case types.UPDATE_OPTIONS:
      return { ...state, ...action.payload };

    default:
      return state;
  }
}
