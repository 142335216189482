import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import * as _ from 'lodash';
import {
  Box,
  Grid,
  Paper,
  TextField,
  Typography,
  makeStyles,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
  Tooltip,
} from '@material-ui/core';
//import withMoving from 'src/components/hocs/withMoving/withMoving';
import * as weatherActions from 'src/store/weather/actions';
//import LocationOnIcon from '@material-ui/icons/LocationOn';
import Modal from 'src/components/containers/Modal/Modal';
import { City } from 'src/types/WeatherStoreState';
import { Autocomplete } from '@material-ui/lab';
import { AllState } from 'src/types/AllState';
import { Weather } from 'src/types/Weather';

const useStyles = makeStyles(() => ({
  iconWrap: {
    textAlign: 'center',
    minWidth: 'fit-content',
  },
  icon: {
    marginTop: '-15px',
    marginRight: '-5px',
    verticalAlign: '-webkit-baseline-middle',
  },
  numbersWrap: {
    color: 'white',
    fontWeight: 'bold',
    fontSize: '18px',
  },
  input: {
    marginBottom: '15px',
  },
  muiWeather: {
    cursor: 'pointer',
    position: 'absolute',
    top: '30px',
    right: '195px',
  },
}));

interface IDispatchProps {
  getWeather: (city: City) => Promise<void>;
  getCities: (p?: string) => Promise<void>;
  changeDegreeUnit: (unit: string) => Promise<void>;
  updateSelectedLocation: (location: City) => Promise<void>;
}

interface IStateProps {
  arePreferencesLoaded: boolean;
  selectedLocation: City | null;
  weather: Weather | null;
  dergeeUnit: string;
  cities: City[];
  isOn: boolean;
}

type Props = IDispatchProps & IStateProps;

const defaultLocation: City = {
  country: '',
  name: 'Local',
  lat: 0,
  lng: 0,
  timeZone: 'local',
};

const WeatherWidget: React.FC<Props> = ({
  arePreferencesLoaded,
  selectedLocation,
  dergeeUnit,
  weather,
  cities,
  isOn,
  getCities,
  getWeather,
  changeDegreeUnit,
  updateSelectedLocation,
}) => {
  const classes = useStyles();

  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [cityList, setCityList] = useState<City[]>([]);

  useEffect(() => {
    getCities();
  }, []);

  useEffect(() => {
    setCityList([defaultLocation, ...cities]);
  }, [cities]);

  const onPreferencesLoaded = useCallback(async () => {
    if (arePreferencesLoaded && !weather) {
      await getWeather(selectedLocation ?? defaultLocation);
    }
  }, [
    arePreferencesLoaded,
    defaultLocation,
    weather,
    selectedLocation,
    getWeather,
  ]);

  useEffect(() => {
    onPreferencesLoaded();
  }, [arePreferencesLoaded]);

  const handleLocationChange = useCallback(
    (e, val: City | null) => {
      if (val) {
        updateSelectedLocation(val);
        getWeather(val);
        setModalOpen(false);
      }
    },
    [updateSelectedLocation, getWeather, setModalOpen]
  );

  const modifyDegrees = useCallback(
    (val: string) => {
      const diff: number = dergeeUnit === 'C' ? 273.5 : 459.67;
      const mult: number = dergeeUnit === 'C' ? 1 : 1.8;

      return Math.ceil(parseInt(val, 10) * mult - diff);
    },
    [dergeeUnit]
  );

  const debouncedGetCities = useMemo(
    () =>
      _.debounce((val) => {
        getCities(val);
      }, 200),
    [getCities]
  );

  return (
    <div className={classes.muiWeather}>
      {weather && isOn ? (
        <Tooltip title='Double click to change city' placement='bottom'>
          <Grid
            container
            spacing={3}
            wrap='nowrap'
            justifyContent='center'
            direction='column'
            alignItems='center'
            className='can-hide'
            onDoubleClick={() => setModalOpen(true)}
          >
            <Grid item xs={12} className={classes.iconWrap}>
              <img
                className={classes.icon}
                src={`http://openweathermap.org/img/wn/${weather.weather[0].icon}.png`}
              />
              <span className={classes.numbersWrap}>
                {' '}
                {modifyDegrees(weather.main.temp)}°{dergeeUnit}
              </span>
            </Grid>
          </Grid>
        </Tooltip>
      ) : (
        ''
      )}
      <Modal
        useBackdrop
        open={isModalOpen}
        handleClose={() => setModalOpen(false)}
        header='Weather'
      >
        <Box px={3} pb={2}>
          <FormControl component='fieldset' className={classes.input}>
            <FormLabel component='legend'>Degree</FormLabel>
            <RadioGroup
              value={dergeeUnit}
              onChange={(e, val) => changeDegreeUnit(val)}
            >
              <FormControlLabel
                value='F'
                control={<Radio color='primary' />}
                label='Fahrenheit'
              />
              <FormControlLabel
                value='C'
                control={<Radio color='primary' />}
                label='Celsius'
              />
            </RadioGroup>
          </FormControl>
          <Autocomplete
            fullWidth
            value={selectedLocation}
            options={cityList}
            onInputChange={(e, val) => {
              if (val.trim().length > 0) {
                debouncedGetCities(val);
              }
            }}
            onChange={handleLocationChange}
            getOptionLabel={(option) => option.name}
            getOptionSelected={(op, val) => op.name === val.name}
            renderInput={(params) => (
              <TextField {...params} label='Location' variant='outlined' />
            )}
          />
          <div>
            <span>
              {/* <a href='https://weather.com' target='_blank'>
                Multiple locations
              </a> */}
            </span>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

function mapStateToProps({ weather, user }: AllState, props: any) {
  return {
    isOn: weather.isOn,
    weather: weather.weather,
    cities: weather.cities,
    selectedLocation: weather.selectedLocation,
    arePreferencesLoaded: user.arePreferencesLoaded,
    dergeeUnit: weather.weatherDergeeUnit,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return (bindActionCreators(
    {
      getWeather: weatherActions.getWeather,
      getCities: weatherActions.getCities,
      changeDegreeUnit: weatherActions.changeDegreeUnit,
      updateSelectedLocation: weatherActions.updateSelectedLocation,
    },
    dispatch
  ) as unknown) as IDispatchProps;
}

export default connect(mapStateToProps, mapDispatchToProps)(WeatherWidget);

// export default connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(withMoving(WeatherWidget, 'weather'));
